import projectData from './project-data';

/* eslint-disable import/no-unresolved */
import popWav from '!arraybuffer-loader!./83a9787d4cb6f3b7632b4ddfebf74367.wav';
import meowWav from '!arraybuffer-loader!./4e080acae1c3dc65eef30f07c2b0d4a5.wav';
import backdrop from '!raw-loader!./cd21514d0531fdffb22204e0ec5ed84a.svg';
import STEMbot1 from '!raw-loader!./9303e3558053ca27429a589fb9d599c7.svg';
import STEMbot2 from '!raw-loader!./213c88520cd5ab07708db7e2023d177e.svg';
import STEMbot3 from '!raw-loader!./b8679a9280a05cd598b3bb392545ad32.svg';
import STEMbot4 from '!raw-loader!./0edb7355f3f82c17acb663a2797ed318.svg';
import STEMbot5 from '!raw-loader!./4c62b94c6504640b9ce1927b7348e8a3.svg';
/* eslint-enable import/no-unresolved */

const defaultProject = translator => {
    let _TextEncoder;
    if (typeof TextEncoder === 'undefined') {
        _TextEncoder = require('text-encoding').TextEncoder;
    } else {
        /* global TextEncoder */
        _TextEncoder = TextEncoder;
    }
    const encoder = new _TextEncoder();

    const projectJson = projectData(translator);
    return [{
        id: 0,
        assetType: 'Project',
        dataFormat: 'JSON',
        data: JSON.stringify(projectJson)
    }, {
        id: '83a9787d4cb6f3b7632b4ddfebf74367',
        assetType: 'Sound',
        dataFormat: 'WAV',
        data: new Uint8Array(popWav)
    }, {
        id: '4e080acae1c3dc65eef30f07c2b0d4a5',
        assetType: 'Sound',
        dataFormat: 'WAV',
        data: new Uint8Array(meowWav)
    }, {
        id: 'cd21514d0531fdffb22204e0ec5ed84a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(backdrop)
    }, {
        id: '9303e3558053ca27429a589fb9d599c7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(STEMbot1)
    }, {
        id: '213c88520cd5ab07708db7e2023d177e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(STEMbot2)
    },{
        id: 'b8679a9280a05cd598b3bb392545ad32',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(STEMbot3)
    }, {
        id: '0edb7355f3f82c17acb663a2797ed318',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(STEMbot4)
    }, {
        id: '4c62b94c6504640b9ce1927b7348e8a3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(STEMbot5)
    }, ];
};

export default defaultProject;
