import { UIPanel } from './libs/ui.js';
import { APP } from './libs/app.js';

function Player( editor ) {

	console.log("Player function initialized with editor:", editor);
	const signals = editor.signals;
	console.log("Signals object:", signals);

	const container = new UIPanel();
	container.setId( 'player' );
	container.setPosition( 'absolute' );
	container.setDisplay( 'none' );

	//

	const player = new APP.Player();
	container.dom.appendChild( player.dom );
	console.log("Player DOM element initialized.");

	window.addEventListener( 'resize', function () {
		console.log("Window resized. Updating player size.");
		player.setSize( container.dom.clientWidth, container.dom.clientHeight );

	} );

	signals.windowResize.add( function () {
		console.log("WindowResize signal triggered. Updating player size.");
		player.setSize( container.dom.clientWidth, container.dom.clientHeight );

	} );

	signals.startPlayer.add( function () {
		console.log("StartPlayer signal triggered.");
		container.setDisplay( '' );
		console.log("Container display set to visible.");
		player.load( editor.toJSON() );
		console.log("Player loaded with editor data:", editor.toJSON());
		player.setSize( container.dom.clientWidth, container.dom.clientHeight );
		
		console.log("Player size set.");
		player.play();
		console.log("Player started.");

	} );

	signals.stopPlayer.add( function () {
		console.log("StopPlayer signal triggered.");
		container.setDisplay( 'none' );
		console.log("Container display set to none.");

		player.stop();
		console.log("Player stopped.");

		player.dispose();
		console.log("Player disposed.");

	} );
	
	console.log("Player container initialized and returned.");
	return container;

}

export { Player };
