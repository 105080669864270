// import { UIElement } from './libs/ui.js';

// function Resizer( editor ) {
// 	 console.log("Resizer function called.");
// 	const signals = editor.signals;

// 	const dom = document.createElement( 'div' );
// 	dom.id = 'resizer';

// 	function onPointerDown( event ) {

// 		if ( event.isPrimary === false ) return;

// 		dom.ownerDocument.addEventListener( 'pointermove', onPointerMove );
// 		dom.ownerDocument.addEventListener( 'pointerup', onPointerUp );

// 	}

// 	function onPointerUp( event ) {

// 		if ( event.isPrimary === false ) return;

// 		dom.ownerDocument.removeEventListener( 'pointermove', onPointerMove );
// 		dom.ownerDocument.removeEventListener( 'pointerup', onPointerUp );

// 	}

// 	function onPointerMove( event ) {

// 		// PointerEvent's movementX/movementY are 0 in WebKit

// 		if ( event.isPrimary === false ) return;

// 		const offsetWidth = document.body.offsetWidth;
// 		const clientX = event.clientX;

// 		const cX = clientX < 0 ? 0 : clientX > offsetWidth ? offsetWidth : clientX;

// 		const x = Math.max( 335, offsetWidth - cX ); // .TabbedPanel min-width: 335px

// 		dom.style.right = x + 'px';

// 		document.getElementById( 'sidebar' ).style.width = x + 'px';
// 		document.getElementById( 'player' ).style.right = x + 'px';
// 		document.getElementById( 'script' ).style.right = x + 'px';
// 		document.getElementById( 'viewport' ).style.right = x + 'px';

// 		signals.windowResize.dispatch();

// 	}

// 	dom.addEventListener( 'pointerdown', onPointerDown );

// 	return new UIElement( dom );

// }

// export { Resizer };


import { UIElement } from './libs/ui.js';

function Resizer(editor) {
    console.log("Resizer function called."); // Entry log

    const signals = editor.signals;

    const dom = document.createElement('div');
    dom.id = 'resizer';
    console.log("Resizer DOM element created with ID:", dom.id);

    function onPointerDown(event) {
        console.log("Pointer down event triggered.", event);

        if (event.isPrimary === false) {
            console.log("Non-primary pointer event ignored.");
            return;
        }

        dom.ownerDocument.addEventListener('pointermove', onPointerMove);
        dom.ownerDocument.addEventListener('pointerup', onPointerUp);

        console.log("Pointer down: Event listeners for move and up added.");
    }

    function onPointerUp(event) {
        console.log("Pointer up event triggered.", event);

        if (event.isPrimary === false) {
            console.log("Non-primary pointer event ignored.");
            return;
        }

        dom.ownerDocument.removeEventListener('pointermove', onPointerMove);
        dom.ownerDocument.removeEventListener('pointerup', onPointerUp);

        console.log("Pointer up: Event listeners for move and up removed.");
    }

    function onPointerMove(event) {
        console.log("Pointer move event triggered.", event);

        if (event.isPrimary === false) {
            console.log("Non-primary pointer event ignored.");
            return;
        }

        // PointerEvent's movementX/movementY are 0 in WebKit
        const offsetWidth = document.body.offsetWidth;
        const clientX = event.clientX;

        const cX = clientX < 0 ? 0 : clientX > offsetWidth ? offsetWidth : clientX;

        const x = Math.max(335, offsetWidth - cX); // .TabbedPanel min-width: 335px
        console.log("Calculated right value for resizer:", x);

        dom.style.right = x + 'px';

        const sidebar = document.getElementById('sidebar');
        const player = document.getElementById('player');
        const script = document.getElementById('script');
        const viewport = document.getElementById('viewport');

        if (sidebar && player && script && viewport) {
            sidebar.style.width = x + 'px';
            player.style.right = x + 'px';
            script.style.right = x + 'px';
            viewport.style.right = x + 'px';
            console.log("Updated styles for sidebar, player, script, and viewport.");
        } else {
            console.error("One or more elements (sidebar, player, script, viewport) are missing.");
        }

        signals.windowResize.dispatch();
        console.log("Window resize signal dispatched.");
    }

    dom.addEventListener('pointerdown', onPointerDown);
    console.log("Pointer down event listener added to resizer.");

    return new UIElement(dom);
}

export { Resizer };
