// import PropTypes from 'prop-types';
// import React from 'react';
// import bindAll from 'lodash.bindall';
// import ConnectionModalComponent, {PHASES} from '../components/connection-modal/connection-modal.jsx';
// import VM from 'blockzie-vm';
// import analytics from '../lib/analytics';
// import extensionData from '../lib/libraries/extensions/index.jsx';
// import {connect} from 'react-redux';
// import {closeConnectionModal} from '../reducers/modals';
// import {setConnectionModalPeripheralName, setListAll} from '../reducers/connection-modal';

// let Pdevice = null;
// class ConnectionModal extends React.Component {
    
//     constructor (props) {
//         super(props);
        
//         bindAll(this, [
//             'handleScanning',
//             'handleCancel',
//             'handleConnected',
//             'handleConnecting',
//             'handleDisconnect',
//             'handleError',
//             'handleHelp',
//             'connectBluetoothDevice'
//         ]);
//         this.state = {
            
//             extension: extensionData.find(ext => ext.extensionId === props.deviceId) ||
//                 this.props.deviceData.find(ext => ext.deviceId === props.deviceId),
//             phase: props.vm.getPeripheralIsConnected(props.deviceId) ? PHASES.connected : PHASES.scanning,
//             peripheralName: null
//         };
        
//     }

//     componentDidMount() {
//         // this.props.vm.on('PERIPHERAL_CONNECTED', (peripheralId) => {
//         //     console.log('PERIPHERAL_CONNECTED event received:', peripheralId);
//         //     this.handleConnected();
//         // });
    
//         this.props.vm.on('PERIPHERAL_ERROR', (error) => {
//             console.error('Bluetooth Error:', error);
//             this.setState({ phase: PHASES.error });
//         });
//     }
    
//     componentWillUnmount() {
//         this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
//         this.props.vm.removeListener('PERIPHERAL_ERROR', this.handleError);
//     }
    

//     async handleScanning () {
//         this.setState({phase: PHASES.scanning});

//         try {
//             await this.connectBluetoothDevice();
//         } catch (error) {
//             this.handleError(error);
//         }
//     }

//     async connectBluetoothDevice () {
//         try {
//             const device = await navigator.bluetooth.requestDevice({
//                 acceptAllDevices: true,
//                 optionalServices: ['c8659212-af91-4ad3-a995-a58d6fd26145','d804b643-6ce7-4e81-9f8a-ce0f699085eb']
//             });

//             console.log('Bluetooth Device Selected:', device);
//             this.handleConnecting(device.id, device.name);
//         } catch (error) {
//             console.error('Bluetooth Connection Error:', error);
//             this.handleError();
//         }
//     }

//     // async handleConnecting(peripheralId, peripheralName) {
//     //     try {
//     //         this.setState({
//     //             phase: PHASES.connecting,
//     //             peripheralName: peripheralName
//     //         });
    
//     //         // Perform the connection
//     //         await this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
    
//     //         // Update phase to connected
//     //         this.setState({ phase: PHASES.connected });
//     //     } catch (error) {
//     //         console.error('Connection error:', error);
//     //         this.handleError(error);
//     //     }
       
//     //     analytics.event({
//     //         category: 'devices',
//     //         action: 'connecting',
//     //         label: this.props.deviceId
//     //     });
//     //     this.handleConnected();
//     //     this.props.onConnected(this.state.peripheralName);
//     // }

//     async handleConnecting(peripheralId, peripheralName, device) {
//         try {
//             console.log('Attempting to Connect to Peripheral:', peripheralId, peripheralName, device);
    
//             if (device) {
//                 localStorage.setItem("connectedBluetooth", JSON.stringify(device.gatt.connected));
//                 Pdevice = device;
//                 console.log("Pdevice", device.gatt);
//             }
    
//             // Set initial state to connecting
//             this.setState({
//                 phase: PHASES.connecting,
//                 peripheralName: peripheralName
//             });
    
//             if (this.props.isRealtimeMode) {
//                 console.log("Connecting in realtime mode");
//                 await this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
//             } else {
//                 console.log("Connecting in non-realtime mode");
//                 await this.props.vm.connectPeripheral(this.props.deviceId, peripheralId, parseInt(this.props.baudrate, 10));
//             }
    
//             // Update phase to connected
//             this.setState({ phase: PHASES.connected });
//         } catch (error) {
//             console.error('Connection error:', error);
//             this.handleError(error);
//         }
    
//         console.log("before analytics Connecting");
//         analytics.event({
//             category: 'devices',
//             action: 'connecting',
//             label: this.props.deviceId
//         });
//         console.log("after analytics Connecting");
    
//         this.handleConnected();
//         console.log("before onConnected");
//         this.props.onConnected(this.state.peripheralName);
//         console.log("after onConnected");
    
//         if (device) {
//             console.log("Connection Attempt:", device.isConnected, device.gatt.connected, device.connect);
//         }
//     }
    
//     // handleConnecting (peripheralId, peripheralName, device) {
//     //     console.log('Attempting to Connect to Peripheral:', peripheralId, peripheralName, device); //device.gatt.disconnect()
//     //     if (device){
//     //         localStorage.setItem("connectedBluetooth", JSON.stringify(device.gatt.connected));
//     //         Pdevice = device;
//     //         console.log("Pdevice",  device.gatt);
//     //     }
//     //     if (this.props.isRealtimeMode) {
//     //         console.log("in handle connection method  if of connection-modal", this.props.isRealtimeMode);
//     //         this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
//     //     } else {
//     //         // console.log("in handle connection method else of connection-modal");
//     //         this.props.vm.connectPeripheral(this.props.deviceId, peripheralId, parseInt(this.props.baudrate, 10));
//     //     }
//     //     this.setState({
//     //         phase: PHASES.connecting,
//     //         peripheralName: peripheralName
//     //     });
//     //     // console.log("before analytics Connecting++++")
//     //     analytics.event({
//     //         category: 'devices',
//     //         action: 'connecting',
//     //         label: this.props.deviceId
//     //     });
//     //     // console.log("after analytics Connecting++++");

//     //     this.handleConnected();
//     //     // console.log("before onConnected++++")
//     //     this.props.onConnected(this.state.peripheralName);
//     //     // console.log("after onConnected++++")

//     //     // console.log("Attempt", device.isConnected, device.gatt.connected, device.connect);
//     //     //localStorage.setItem("isBluetoothConnected", JSON.stringify(device.gatt.connected));
//     // }

//     handleDisconnect () {
//         try {
//              console.log("onnect method of connection-modal contai", Pdevice );
//             const connectedBluetooth =  JSON.parse(localStorage.getItem("connectedBluetooth"))
//             if (connectedBluetooth) {
//                 console.log("into bluetooth disconnect");
//                 localStorage.removeItem("connectedBluetooth");
//                 Pdevice.gatt.disconnect();
//                 //localStorage.removeItem("connectedBluetooth");
//               }
//             this.props.vm.disconnectPeripheral(this.props.deviceId);
//         } finally {
//             this.props.onCancel();
//         }
//     }
    
//     handleCancel = async () => {
//         try {
//             console.log("Device ID:", this.props.deviceId);
//             const isConnected = this.props.vm.getPeripheralIsConnected(this.props.deviceId);
//             // console.log("Is connected:", isConnected);
    
//             if (!isConnected) {
//                 //console.log("Attempting to connect peripheral...");
//                 //await this.props.vm.connectPeripheral(this.props.deviceId);
//                 //console.log('Connected successfully to peripheral.');
//             } else {
//                 console.log('Device is already connected.');
//             }
//         } catch (error) {
//             console.error("Error in handleCancel:", error);
//         } finally {
//             this.props.onCancel(); 
//         }
//     }    
    
//     handleError (error) {
//         console.error('Connection Error:', error);
//         if (this.state.phase === PHASES.scanning || this.state.phase === PHASES.unavailable) {
//             this.setState({
//                 phase: PHASES.unavailable
//             });
//         } else {
//             this.setState({
//                 phase: PHASES.error
//             });
//             analytics.event({
//                 category: 'devices',
//                 action: 'connecting error',
//                 label: this.props.deviceId
//             });
//         }
//     }
    
//     handleConnected() {
//         console.log('Device successfully connected:', this.state.peripheralName);
//         this.setState({ phase: PHASES.connected });
    
//         analytics.event({
//             category: 'devices',
//             action: 'connected',
//             label: this.props.deviceId
//         });
    
//         this.props.onConnected(this.state.peripheralName);
//     }
    
    
    

//     handleHelp () {
//         window.open(this.state.extension.helpLink, '_blank');
//         analytics.event({
//             category: 'devices',
//             action: 'device help',
//             label: this.props.deviceId
//         });
//     }

//     render () {
//         return (
//             <ConnectionModalComponent
//             connectingMessage={this.state.extension && this.state.extension.connectingMessage}
//             connectionIconURL={this.state.extension && this.state.extension.connectionIconURL}
//             connectionSmallIconURL={this.state.extension && this.state.extension.connectionSmallIconURL}
//             isSerialport={this.state.extension && this.state.extension.serialportRequired}
//             isListAll={this.props.isListAll}
//             connectionTipIconURL={this.state.extension && this.state.extension.connectionTipIconURL}
//             extensionId={this.props.deviceId}
//             name={this.state.extension && this.state.extension.name}
//             phase={this.state.phase} // Determines UI state
//             title={this.props.deviceId}
//             useAutoScan={this.state.extension && this.state.extension.useAutoScan}
//             vm={this.props.vm}
//             onCancel={this.handleCancel}
//             onConnected={this.handleConnected}
//             onConnecting={this.handleConnecting}
//             onClickListAll={this.props.onClickListAll}
//             onDisconnect={this.handleDisconnect}
//             onHelp={this.handleHelp}
//             onScanning={this.handleScanning}
//             peripheralName={this.state.peripheralName}
//             showDisconnectButton={this.state.phase === PHASES.connected} // Add this
//         />
        
//         );
//     }
// }

// ConnectionModal.propTypes = {
//     baudrate: PropTypes.string.isRequired,
//     deviceId: PropTypes.string.isRequired,
//     deviceData: PropTypes.instanceOf(Array).isRequired,
//     isRealtimeMode: PropTypes.bool,
//     isListAll: PropTypes.bool,
//     onCancel: PropTypes.func.isRequired,
//     onConnected: PropTypes.func.isRequired,
//     onClickListAll: PropTypes.func.isRequired,
//     vm: PropTypes.instanceOf(VM).isRequired
// };

// const mapStateToProps = state => ({
//     baudrate: state.scratchGui.hardwareConsole.baudrate,
//     deviceData: state.scratchGui.deviceData.deviceData,
//     deviceId: state.scratchGui.device.deviceId,
//     isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
//     isListAll: state.scratchGui.connectionModal.isListAll,
//     peripheralName: state.scratchGui.connectionModal.peripheralName
// });

// const mapDispatchToProps = dispatch => ({
//     onCancel: () => {
//         dispatch(closeConnectionModal());
//     },
//     onConnected: peripheralName => {
//         dispatch(setConnectionModalPeripheralName(peripheralName));
//     },
//     onClickListAll: state => {
//         dispatch(setListAll(state));
//     }
// });

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(ConnectionModal);


// import PropTypes from 'prop-types';
// import React from 'react';
// import bindAll from 'lodash.bindall';
// import ConnectionModalComponent, {PHASES} from '../components/connection-modal/connection-modal.jsx';
// import VM from 'blockzie-vm';
// import analytics from '../lib/analytics';
// import extensionData from '../lib/libraries/extensions/index.jsx';
// import {connect} from 'react-redux';
// import {closeConnectionModal} from '../reducers/modals';
// import {setConnectionModalPeripheralName, setListAll} from '../reducers/connection-modal';

// class ConnectionModal extends React.Component {
//     constructor (props) {
//         super(props);
//         bindAll(this, [
//             'handleScanning',
//             'handleCancel',
//             'handleConnected',
//             'handleConnecting',
//             'handleDisconnect',
//             'handleError',
//             'handleHelp',
//             'connectBluetoothDevice'
//         ]);
//         this.state = {
//             extension: extensionData.find(ext => ext.extensionId === props.deviceId) ||
//                 this.props.deviceData.find(ext => ext.deviceId === props.deviceId),
//             phase: props.vm.getPeripheralIsConnected(props.deviceId) ? PHASES.connected : PHASES.scanning,
//             peripheralName: null
//         };
//     }

//     componentDidMount() {
//         this.props.vm.on('PERIPHERAL_CONNECTED', (peripheralId) => {
//             console.log('PERIPHERAL_CONNECTED event received:', peripheralId);
//             this.handleConnected();
//         });
    
//         this.props.vm.on('PERIPHERAL_ERROR', (error) => {
//             console.error('Bluetooth Error:', error);
//             this.setState({ phase: PHASES.error });
//         });
//     }
    
//     componentWillUnmount() {
//         this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
//         this.props.vm.removeListener('PERIPHERAL_ERROR', this.handleError);
//     }
    

//     async handleScanning () {
//         this.setState({phase: PHASES.scanning});

//         try {
//             await this.connectBluetoothDevice();
//         } catch (error) {
//             this.handleError(error);
//         }
//     }

//     async connectBluetoothDevice () {
//         try {
//             const device = await navigator.bluetooth.requestDevice({
//                 acceptAllDevices: true,
//                 optionalServices: ['battery_service', 'generic_access']
//             });

//             console.log('Bluetooth Device Selected:', device);
//             this.handleConnecting(device.id, device.name);
//         } catch (error) {
//             console.error('Bluetooth Connection Error:', error);
//             this.handleError();
//         }
//     }

//     async handleConnecting(peripheralId, peripheralName) {
//         try {
//             this.setState({
//                 phase: PHASES.connecting,
//                 peripheralName: peripheralName
//             });
    
//             // Perform the connection
//             await this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
    
//             // Update phase to connected
//             this.setState({ phase: PHASES.connected });
//         } catch (error) {
//             console.error('Connection error:', error);
//             this.handleError(error);
//         }
       
//         analytics.event({
//             category: 'devices',
//             action: 'connecting',
//             label: this.props.deviceId
//         });
//         this.handleConnected();
//         this.props.onConnected(this.state.peripheralName);
//     }
    


    // async handleConnecting(peripheralId, peripheralName) {
    //     console.log('Attempting to Connect to Peripheral:', peripheralId, peripheralName);
    
    //     const connectionTimeout = setTimeout(() => {
    //         if (this.state.phase === PHASES.connecting) {
    //             console.error('Connection timed out. Please retry.');
    //             this.setState({ phase: PHASES.error });
    //         }
    //     }, 10000); // 10 seconds
    
    //     try {
    //         this.setState({
    //             phase: PHASES.connecting,  
    //             peripheralName: peripheralName
    //         });
    
    //         let connectPromise;
    
    //         if (this.props.isRealtimeMode) {
    //             connectPromise = this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
    //         } else {
    //             connectPromise = this.props.vm.connectPeripheral(
    //                 this.props.deviceId,
    //                 peripheralId,
    //                 parseInt(this.props.baudrate, 10)
    //             );
    //         }
    
    //         await connectPromise;
    
    //         clearTimeout(connectionTimeout);
    
           
    //     } catch (error) {
    //         console.error('Connection Error:', error.message || error);
    
    //         // Retry logic
    //         if (error.message.includes('NetworkError') || error.message.includes('timeout')) {
    //             console.log('Retrying connection...');
    //             await this.handleConnecting(peripheralId, peripheralName);
    //         } else {
    //             this.setState({ phase: PHASES.error });
    //             this.handleError(error);
    //         }
    
    //         clearTimeout(connectionTimeout);
    //     }
        // this.handleConnected();
        // analytics.event({
        //     category: 'devices',
        //     action: 'connecting',
        //     label: this.props.deviceId
        // });
        // this.props.onConnected(this.state.peripheralName);
    // }
    
    // handleDisconnect() {
    //     try {
    //         this.props.vm.disconnectPeripheral(this.props.deviceId);
    //         this.setState({ phase: PHASES.scanning, peripheralName: null }); // Reset state
    //     } catch (error) {
    //         console.error('Error disconnecting:', error);
    //     } finally {
    //         this.props.onCancel(); // Close the modal
    //     }
    // }
    

    // handleCancel () {
    //     try {
    //         if (!this.props.vm.getPeripheralIsConnected(this.props.deviceId)) {
    //             this.props.vm.disconnectPeripheral(this.props.deviceId);
    //         }
    //     } finally {
    //         this.props.onCancel();
    //     }
    // }
    
    // handleCancel = async () => {
    //     try {
    //         console.log("Device ID:", this.props.deviceId);
    //         const isConnected = this.props.vm.getPeripheralIsConnected(this.props.deviceId);
    //         // console.log("Is connected:", isConnected);
    
    //         if (!isConnected) {
    //             console.log("Attempting to connect peripheral...");
    //             await this.props.vm.connectPeripheral(this.props.deviceId);
    //             console.log('Connected successfully to peripheral.');
    //         } else {
    //             console.log('Device is already connected.');
    //         }
    //     } catch (error) {
    //         console.error("Error in handleCancel:", error);
    //     } finally {
    //         this.props.onCancel(); 
    //     }
    // }    
    
    // handleCancel = async () => {
    //     try {
    //         console.log("Device ID:", this.props.deviceId);
    //         const isConnected = this.props.vm.getPeripheralIsConnected(this.props.deviceId);
    
    //         if (!isConnected) {
    //             console.log("Attempting to connect peripheral...");
    
    //             // Assuming your `vm` object has a method to connect to the peripheral
    //             await this.props.vm.connectPeripheral(this.props.deviceId);
    
    //             console.log('Connected successfully to peripheral.');
    
    //             const valueToSend = "LED on"; // Replace with actual value
    //             await this.props.vm.writeToPeripheral(this.props.deviceId, valueToSend);
    //             console.log('Value sent to the device:', valueToSend);
    
    //             // Access the GATT server directly from the device object
    //             const device = await navigator.bluetooth.requestDevice({
    //                 filters: [{ services: ['d804b643-6ce7-4e81-9f8a-ce0f699085eb'] }]
    //             });
    
    //             const gattServer = await device.gatt.connect();
    //             console.log("GATT Server connected:", gattServer);
    
    //             const service = await gattServer.getPrimaryService('d804b643-6ce7-4e81-9f8a-ce0f699085eb'); // Replace with actual service UUID
    //             const characteristic = await service.getCharacteristic('c8659212-af91-4ad3-a995-a58d6fd26145'); // Replace with actual characteristic UUID
    
    //             // Read the value of the characteristic (instead of starting notifications)
    //             const value = await characteristic.readValue();
    //             const receivedValue = new TextDecoder().decode(value);
    //             console.log('Data received from the device:', receivedValue);
                
    //         } else {
    //             console.log('Device is already connected.');
    //         }
    //     } catch (error) {
    //         console.error("Error in handleCancel:", error);
    //     } finally {
    //         this.props.onCancel(); // Trigger navigation or modal close
    //     }
    // }    
     
    
    
    
//     handleError (error) {
//         console.error('Connection Error:', error);
//         if (this.state.phase === PHASES.scanning || this.state.phase === PHASES.unavailable) {
//             this.setState({
//                 phase: PHASES.unavailable
//             });
//         } else {
//             this.setState({
//                 phase: PHASES.error
//             });
//             analytics.event({
//                 category: 'devices',
//                 action: 'connecting error',
//                 label: this.props.deviceId
//             });
//         }
//     }
    
//     handleConnected() {
//         console.log('Device successfully connected:', this.state.peripheralName);
//         this.setState({ phase: PHASES.connected });
    
//         analytics.event({
//             category: 'devices',
//             action: 'connected',
//             label: this.props.deviceId
//         });
    
//         this.props.onConnected(this.state.peripheralName);
//     }
    
    
    

//     handleHelp () {
//         window.open(this.state.extension.helpLink, '_blank');
//         analytics.event({
//             category: 'devices',
//             action: 'device help',
//             label: this.props.deviceId
//         });
//     }

//     render () {
//         return (
//             <ConnectionModalComponent
//             connectingMessage={this.state.extension && this.state.extension.connectingMessage}
//             connectionIconURL={this.state.extension && this.state.extension.connectionIconURL}
//             connectionSmallIconURL={this.state.extension && this.state.extension.connectionSmallIconURL}
//             isSerialport={this.state.extension && this.state.extension.serialportRequired}
//             isListAll={this.props.isListAll}
//             connectionTipIconURL={this.state.extension && this.state.extension.connectionTipIconURL}
//             extensionId={this.props.deviceId}
//             name={this.state.extension && this.state.extension.name}
//             phase={this.state.phase} // Determines UI state
//             title={this.props.deviceId}
//             useAutoScan={this.state.extension && this.state.extension.useAutoScan}
//             vm={this.props.vm}
//             onCancel={this.handleCancel}
//             onConnected={this.handleConnected}
//             onConnecting={this.handleConnecting}
//             onClickListAll={this.props.onClickListAll}
//             onDisconnect={this.handleDisconnect}
//             onHelp={this.handleHelp}
//             onScanning={this.handleScanning}
//             peripheralName={this.state.peripheralName}
//             showDisconnectButton={this.state.phase === PHASES.connected} // Add this
//         />
        
//         );
//     }
// }

// ConnectionModal.propTypes = {
//     baudrate: PropTypes.string.isRequired,
//     deviceId: PropTypes.string.isRequired,
//     deviceData: PropTypes.instanceOf(Array).isRequired,
//     isRealtimeMode: PropTypes.bool,
//     isListAll: PropTypes.bool,
//     onCancel: PropTypes.func.isRequired,
//     onConnected: PropTypes.func.isRequired,
//     onClickListAll: PropTypes.func.isRequired,
//     vm: PropTypes.instanceOf(VM).isRequired
// };

// const mapStateToProps = state => ({
//     baudrate: state.scratchGui.hardwareConsole.baudrate,
//     deviceData: state.scratchGui.deviceData.deviceData,
//     deviceId: state.scratchGui.device.deviceId,
//     isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
//     isListAll: state.scratchGui.connectionModal.isListAll,
//     peripheralName: state.scratchGui.connectionModal.peripheralName
// });

// const mapDispatchToProps = dispatch => ({
//     onCancel: () => {
//         dispatch(closeConnectionModal());
//     },
//     onConnected: peripheralName => {
//         dispatch(setConnectionModalPeripheralName(peripheralName));
//     },
//     onClickListAll: state => {
//         dispatch(setListAll(state));
//     }
// });

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(ConnectionModal);


    // handleConnecting (peripheralId, peripheralName) {
    //     console.log('Attempting to Connect to Peripheral:', peripheralId, peripheralName);
    //     if (this.props.isRealtimeMode) {
    //         this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
    //     } else {
    //         this.props.vm.connectPeripheral(this.props.deviceId, peripheralId, parseInt(this.props.baudrate, 10));
    //     }
    //     this.setState({
    //         phase: PHASES.connecting,
    //         peripheralName: peripheralName
    //     });
        
    //     analytics.event({
    //         category: 'devices',
    //         action: 'connecting',
    //         label: this.props.deviceId
    //     });

    //     this.props.onConnected(this.state.peripheralName);
    // }
    
    // async handleConnecting(peripheralId, peripheralName) {
    //     console.log('Attempting to Connect to Peripheral:', peripheralId, peripheralName);
    
    //     // Set a timeout for 10 seconds
    //     const connectionTimeout = setTimeout(() => {
    //         if (this.state.phase === PHASES.connecting) {
    //             console.error('Connection timed out. Please retry.');
    //             this.setState({ phase: PHASES.error });
    //         }
    //     }, 10000); // 10 seconds
    
    //     try {
    //         // Set phase to 'connecting' before attempting connection
    //         this.setState({
    //             phase: PHASES.connecting,
    //             peripheralName: peripheralName // Store the name for display
    //         });
    
    //         let connectPromise;
    
    //         // Check if the connection method supports async/await
    //         if (this.props.isRealtimeMode) {
    //             connectPromise = this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
    //         } else {
    //             connectPromise = this.props.vm.connectPeripheral(
    //                 this.props.deviceId,
    //                 peripheralId,
    //                 parseInt(this.props.baudrate, 10)
    //             );
    //         }
    
    //         // Await the connection attempt
    //         await connectPromise;
    
    //         // Clear the timeout on successful connection
    //         clearTimeout(connectionTimeout);
    
           
    //         console.log('Connected successfully.');
    //     } catch (error) {
    //         console.error('Connection Error:', error);
    //         clearTimeout(connectionTimeout);
    //         this.setState({ phase: PHASES.error });
    //         this.handleError(error);
    //     }

    //     // Log the analytics event
    //     analytics.event({
    //         category: 'devices',
    //         action: 'connecting',
    //         label: this.props.deviceId
    //     });
    //      // Update phase to 'connected'
    //      this.handleConnected();
    // }




import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ConnectionModalComponent, { PHASES } from '../components/connection-modal/connection-modal.jsx';
import VM from 'blockzie-vm';
import analytics from '../lib/analytics';
import extensionData from '../lib/libraries/extensions/index.jsx';
import { connect } from 'react-redux';
import { closeConnectionModal } from '../reducers/modals';
import { setConnectionModalPeripheralName, setListAll } from '../reducers/connection-modal';

class ConnectionModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleScanning',
            'handleCancel',
            'handleConnected',
            'handleConnecting',
            'handleDisconnect',
            'handleError',
            'handleHelp',
            'connectBluetoothDevice'
        ]);
        this.state = {
            extension: extensionData.find(ext => ext.extensionId === props.deviceId) ||
                this.props.deviceData.find(ext => ext.deviceId === props.deviceId),
            phase: props.vm.getPeripheralIsConnected(props.deviceId) ? PHASES.connected : PHASES.scanning,
            peripheralName: null
        };
    }

    componentDidMount() {
        this.props.vm.on('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.on('PERIPHERAL_REQUEST_ERROR', this.handleError);
    }

    componentWillUnmount() {
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.removeListener('PERIPHERAL_REQUEST_ERROR', this.handleError);
    }

    async handleScanning() {
        this.setState({ phase: PHASES.scanning });

        try {
            await this.connectBluetoothDevice();
        } catch (error) {
            this.handleError(error);
        }
    }

    async connectBluetoothDevice() {
        try {
            const device = await navigator.bluetooth.requestDevice({
                acceptAllDevices: true,
                optionalServices: ['battery_service', 'generic_access']
            });

            console.log('Bluetooth Device Selected:', device);
            this.handleConnecting(device.id, device.name);
        } catch (error) {
            console.error('Bluetooth Connection Error:', error);
            this.handleError();
        }
    }

    handleConnecting (peripheralId, peripheralName) {
        if (this.props.isRealtimeMode) {
            this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
        } else {
            this.props.vm.connectPeripheral(this.props.deviceId, peripheralId, parseInt(this.props.baudrate, 10));
        }
        this.setState({
            phase: PHASES.connecting,
            peripheralName: peripheralName
        });
        
        analytics.event({
            category: 'devices',
            action: 'connecting',
            label: this.props.deviceId
        });

    }
    

    handleDisconnect() {
        try {
            this.props.vm.disconnectPeripheral(this.props.deviceId);
        } finally {
            this.props.onCancel();
        }
    }

    handleCancel() {
        try {
            if (!this.props.vm.getPeripheralIsConnected(this.props.deviceId)) {
                this.props.vm.disconnectPeripheral(this.props.deviceId);
            }
        } finally {
            this.props.onCancel();
        }
    }

    handleError(error) {
        console.error('Connection Error:', error);
        if (this.state.phase === PHASES.scanning || this.state.phase === PHASES.unavailable) {
            this.setState({
                phase: PHASES.unavailable
            });
        } else {
            this.setState({
                phase: PHASES.error
            });
            analytics.event({
                category: 'devices',
                action: 'connecting error',
                label: this.props.deviceId
            });
        }
    }
    

    handleConnected() {
        this.setState({ phase: PHASES.connected });

        analytics.event({
            category: 'devices',
            action: 'connected',
            label: this.props.deviceId
        });

        this.props.onConnected(this.state.peripheralName);
    }

    handleHelp() {
        window.open(this.state.extension.helpLink, '_blank');
        analytics.event({
            category: 'devices',
            action: 'device help',
            label: this.props.deviceId
        });
    }

    render() {
        return (
            <ConnectionModalComponent
                connectingMessage={this.state.extension && this.state.extension.connectingMessage}
                connectionIconURL={this.state.extension && this.state.extension.connectionIconURL}
                connectionSmallIconURL={this.state.extension && this.state.extension.connectionSmallIconURL}
                isSerialport={this.state.extension && this.state.extension.serialportRequired}
                isListAll={this.props.isListAll}
                connectionTipIconURL={this.state.extension && this.state.extension.connectionTipIconURL}
                extensionId={this.props.deviceId}
                name={this.state.extension && this.state.extension.name}
                phase={this.state.phase}
                title={this.props.deviceId}
                useAutoScan={this.state.extension && this.state.extension.useAutoScan}
                vm={this.props.vm}
                onCancel={this.handleCancel}
                onConnected={this.handleConnected}
                onConnecting={this.handleConnecting}
                onClickListAll={this.props.onClickListAll}
                onDisconnect={this.handleDisconnect}
                onHelp={this.handleHelp}
                onScanning={this.handleScanning}
            />
        );
    }
}

ConnectionModal.propTypes = {
    baudrate: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired,
    deviceData: PropTypes.instanceOf(Array).isRequired,
    isRealtimeMode: PropTypes.bool,
    isListAll: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConnected: PropTypes.func.isRequired,
    onClickListAll: PropTypes.func.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    baudrate: state.scratchGui.hardwareConsole.baudrate,
    deviceData: state.scratchGui.deviceData.deviceData,
    deviceId: state.scratchGui.device.deviceId,
    isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
    isListAll: state.scratchGui.connectionModal.isListAll
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeConnectionModal());
    },
    onConnected: peripheralName => {
        dispatch(setConnectionModalPeripheralName(peripheralName));
    },
    onClickListAll: state => {
        dispatch(setListAll(state));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionModal);
