
// import { UIPanel, UIRow } from './libs/ui.js';

// function MenubarEnvironment(editor) {
//     const strings = editor.strings;

//     const container = new UIPanel();
//     container.setClass('menu');

//     // Menu Title
//     const title = new UIPanel();
//     title.setClass('title');
//     title.setTextContent(strings.getKey('menubar/environment')); // Menu title: "Environment"
//     container.add(title);

//     const options = new UIPanel();
//     options.setClass('options');
//     container.add(options);

//     // Add Effects Options
//     const effects = [
//         { label: 'rain', handler: addRainEffect },
//         { label: 'snow', handler: addSnowEffect },
//     ];

//     effects.forEach(effect => {
//         const option = new UIRow();
//         option.setClass('option');
//         option.setTextContent(strings.getKey(`menubar/environment/${effect.label}`));
//         option.onClick(() => effect.handler(editor)); // Trigger corresponding effect handler
//         options.add(option);
//     });

//     return container;

//     // Helper functions to handle effects

//     function addRainEffect(editor) {
//         // Add Rain Effect
//         const rainEffect = document.createElement('canvas');
//         rainEffect.width = window.innerWidth;
//         rainEffect.height = window.innerHeight;
//         rainEffect.style.position = 'absolute';
//         rainEffect.style.top = '0';
//         rainEffect.style.left = '0';
//         rainEffect.style.pointerEvents = 'none'; // Allow clicks through the canvas
//         rainEffect.style.zIndex = '9999'; // Overlay effect

//         document.body.appendChild(rainEffect);

//         const ctx = rainEffect.getContext('2d');
//         const raindrops = [];

//         function createRaindrops() {
//             for (let i = 0; i < 100; i++) {
//                 raindrops.push({
//                     x: Math.random() * rainEffect.width,
//                     y: Math.random() * rainEffect.height,
//                     speed: Math.random() * 5 + 2,
//                     length: Math.random() * 20 + 10,
//                 });
//             }
//         }

//         function drawRain() {
//             ctx.clearRect(0, 0, rainEffect.width, rainEffect.height);
//             ctx.strokeStyle = 'rgba(174,194,224,0.5)';
//             ctx.lineWidth = 2;

//             raindrops.forEach((drop) => {
//                 ctx.beginPath();
//                 ctx.moveTo(drop.x, drop.y);
//                 ctx.lineTo(drop.x, drop.y + drop.length);
//                 ctx.stroke();
//             });
//         }

//         function updateRain() {
//             raindrops.forEach((drop) => {
//                 drop.y += drop.speed;
//                 if (drop.y > rainEffect.height) {
//                     drop.y = -drop.length;
//                     drop.x = Math.random() * rainEffect.width;
//                 }
//             });
//         }

//         function animateRain() {
//             drawRain();
//             updateRain();
//             requestAnimationFrame(animateRain);
//         }

//         createRaindrops();
//         animateRain();
//     }

//     function addSnowEffect(editor) {
//         // Add Snow Effect
//         const snowEffect = document.createElement('canvas');
//         snowEffect.width = window.innerWidth;
//         snowEffect.height = window.innerHeight;
//         snowEffect.style.position = 'absolute';
//         snowEffect.style.top = '0';
//         snowEffect.style.left = '0';
//         snowEffect.style.pointerEvents = 'none'; // Allow clicks through the canvas
//         snowEffect.style.zIndex = '9999'; // Overlay effect

//         document.body.appendChild(snowEffect);

//         const ctx = snowEffect.getContext('2d');
//         const snowflakes = [];

//         function createSnowflakes() {
//             for (let i = 0; i < 100; i++) {
//                 snowflakes.push({
//                     x: Math.random() * snowEffect.width,
//                     y: Math.random() * snowEffect.height,
//                     speed: Math.random() * 1 + 0.5,
//                     size: Math.random() * 5 + 2,
//                 });
//             }
//         }

//         function drawSnow() {
//             ctx.clearRect(0, 0, snowEffect.width, snowEffect.height);
//             ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
//             snowflakes.forEach((flake) => {
//                 ctx.beginPath();
//                 ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
//                 ctx.fill();
//             });
//         }

//         function updateSnow() {
//             snowflakes.forEach((flake) => {
//                 flake.y += flake.speed;
//                 if (flake.y > snowEffect.height) {
//                     flake.y = -flake.size;
//                     flake.x = Math.random() * snowEffect.width;
//                 }
//             });
//         }

//         function animateSnow() {
//             drawSnow();
//             updateSnow();
//             requestAnimationFrame(animateSnow);
//         }

//         createSnowflakes();
//         animateSnow();
//     }
// }

// export { MenubarEnvironment };



// import { UIPanel, UIRow } from './libs/ui.js';
// import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

// function MenubarEnvironment(editor) {
//     const strings = editor.strings;

//     const container = new UIPanel();
//     container.setClass('menu');

//     const title = new UIPanel();
//     title.setClass('title');
//     title.setTextContent(strings.getKey('menubar/environment')); // Menu title: "Environment"
//     container.add(title);

//     const options = new UIPanel();
//     options.setClass('options');
//     container.add(options);

//     // Add Snow Effect Button
//     const snowButton = new UIRow();
//     snowButton.setClass('option');
//     snowButton.setTextContent('Toggle Snow Effect');
//     snowButton.onClick(() => {
//         toggleSnowEffect(editor.scene, editor);
//     });
//     options.add(snowButton);

//     // Add Rain Effect Button
//     const rainButton = new UIRow();
//     rainButton.setClass('option');
//     rainButton.setTextContent('Toggle Rain Effect');
//     rainButton.onClick(() => {
//         toggleRainEffect(editor.scene, editor);
//     });
//     options.add(rainButton);

//     // Add Intensity Slider
//     const intensityRow = new UIRow();
//     intensityRow.setClass('option');
//     intensityRow.setTextContent('Particle Intensity');
//     const intensitySlider = document.createElement('input');
//     intensitySlider.type = 'range';
//     intensitySlider.min = 1000;
//     intensitySlider.max = 10000;
//     intensitySlider.value = 5000;
//     intensitySlider.step = 1000;
//     intensitySlider.oninput = (event) => {
//         particleIntensity = parseInt(event.target.value, 10);
//         updateParticleIntensity(editor.scene);
//     };
//     intensityRow.dom.appendChild(intensitySlider);
//     options.add(intensityRow);

//     return container;
// }

// // Global particle references
// let snowParticles = null;
// let rainParticles = null;
// let particleIntensity = 5000;

// // Function to toggle snow effect
// function toggleSnowEffect(scene, editor) {
//     if (snowParticles) {
//         scene.remove(snowParticles);
//         snowParticles = null;
//     } else {
//         snowParticles = createParticles('snow');
//         scene.add(snowParticles);
//         animateParticles(editor, 'snow');
//     }
// }

// // Function to toggle rain effect
// function toggleRainEffect(scene, editor) {
//     if (rainParticles) {
//         scene.remove(rainParticles);
//         rainParticles = null;
//     } else {
//         rainParticles = createParticles('rain');
//         scene.add(rainParticles);
//         animateParticles(editor, 'rain');
//     }
// }

// // Function to create particles (snow or rain)
// function createParticles(type) {
//     const geometry = new THREE.BufferGeometry();
//     const positions = new Float32Array(particleIntensity * 3);

//     for (let i = 0; i < particleIntensity; i++) {
//         positions[i * 3] = Math.random() * 200 - 100;
//         positions[i * 3 + 1] = Math.random() * 200;
//         positions[i * 3 + 2] = Math.random() * 200 - 100;
//     }

//     geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

//     const material = new THREE.PointsMaterial({
//         color: type === 'snow' ? 0xffffff : 0xaaaaaa,
//         size: type === 'snow' ? 0.5 : 0.1,
//         transparent: true,
//     });

//     return new THREE.Points(geometry, material);
// }

// // Update particle intensity
// function updateParticleIntensity(scene) {
//     if (snowParticles) {
//         scene.remove(snowParticles);
//         snowParticles = createParticles('snow');
//         scene.add(snowParticles);
//     }
//     if (rainParticles) {
//         scene.remove(rainParticles);
//         rainParticles = createParticles('rain');
//         scene.add(rainParticles);
//     }
// }

// // Animate particles
// function animateParticles(editor, type) {
//     function animate() {
//         if (type === 'snow' && snowParticles) {
//             const positions = snowParticles.geometry.attributes.position.array;
//             for (let i = 0; i < positions.length; i += 3) {
//                 positions[i + 1] -= 0.5; // Move downwards
//                 positions[i] += Math.sin(Date.now() * 0.001) * 0.1; // Wind effect
//                 if (positions[i + 1] < -100) {
//                     positions[i + 1] = 200;
//                 }
//             }
//             snowParticles.geometry.attributes.position.needsUpdate = true;
//         }

//         if (type === 'rain' && rainParticles) {
//             const positions = rainParticles.geometry.attributes.position.array;
//             for (let i = 0; i < positions.length; i += 3) {
//                 positions[i + 1] -= 1; // Move downwards faster
//                 if (positions[i + 1] < -100) {
//                     positions[i + 1] = 200;
//                 }
//             }
//             rainParticles.geometry.attributes.position.needsUpdate = true;
//         }

//         editor.renderer.render(editor.scene, editor.camera);
//         requestAnimationFrame(animate);
//     }

//     animate();
// }

// // Set up editor scene with controls
// function initializeEditorScene(editor) {
//     editor.scene = new THREE.Scene();
//     editor.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
//     editor.camera.position.set(0, 50, 150);

//     editor.renderer = new THREE.WebGLRenderer({ antialias: true });
//     editor.renderer.setSize(window.innerWidth, window.innerHeight);
//     document.body.appendChild(editor.renderer.domElement);

//     const controls = new OrbitControls(editor.camera, editor.renderer.domElement);
//     controls.update();

//     const light = new THREE.PointLight(0xffffff, 1);
//     light.position.set(50, 50, 50);
//     editor.scene.add(light);

//     const ambientLight = new THREE.AmbientLight(0x404040); // Soft ambient light
//     editor.scene.add(ambientLight);

//     editor.renderer.render(editor.scene, editor.camera);
// }

// export { MenubarEnvironment, initializeEditorScene };

import { UIPanel, UIRow } from './libs/ui.js';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

// Define the MenubarEnvironment function
function MenubarEnvironment(editor) {
    const strings = editor.strings;

    const container = new UIPanel();
    container.setClass('menu');

    const title = new UIPanel();
    title.setClass('title');
    title.setTextContent(strings.getKey('menubar/environment')); // Menu title: "Environment"
    container.add(title);

    const options = new UIPanel();
    options.setClass('options');
    container.add(options);

    // Add Snow Effect Button
    const snowButton = new UIRow();
    snowButton.setClass('option');
    snowButton.setTextContent('Toggle Snow Effect');
    snowButton.onClick(() => {
        toggleSnowEffect(editor.scene, editor);
    });
    options.add(snowButton);

    // Add Rain Effect Button
    const rainButton = new UIRow();
    rainButton.setClass('option');
    rainButton.setTextContent('Toggle Rain Effect');
    rainButton.onClick(() => {
        toggleRainEffect(editor.scene, editor);
    });
    options.add(rainButton);

    // Add Intensity Slider
    const intensityRow = new UIRow();
    intensityRow.setClass('option');
    intensityRow.setTextContent('Particle Intensity');
    const intensitySlider = document.createElement('input');
    intensitySlider.type = 'range';
    intensitySlider.min = 1000;
    intensitySlider.max = 10000;
    intensitySlider.value = 5000;
    intensitySlider.step = 1000;
    intensitySlider.oninput = (event) => {
        particleIntensity = parseInt(event.target.value, 10);
        updateParticleIntensity(editor.scene);
    };
    intensityRow.dom.appendChild(intensitySlider);
    options.add(intensityRow);

    // Add Movement Speed Slider
    const movementSpeedRow = new UIRow();
    movementSpeedRow.setClass('option');
    movementSpeedRow.setTextContent('Movement Speed');
    const movementSpeedSlider = document.createElement('input');
    movementSpeedSlider.type = 'range';
    movementSpeedSlider.min = 0.1;
    movementSpeedSlider.max = 5;
    movementSpeedSlider.value = movementSpeed;
    movementSpeedSlider.step = 0.1;
    movementSpeedSlider.oninput = (event) => {
        movementSpeed = parseFloat(event.target.value); // Update movement speed
    };
    movementSpeedRow.dom.appendChild(movementSpeedSlider);
    options.add(movementSpeedRow);

    return container;
}

// Global particle references
let snowParticles = null;
let rainParticles = null;
let particleIntensity = 5000;
let movementSpeed = 10; // Initial movement speed for particles

// Function to toggle snow effect
function toggleSnowEffect(scene, editor) {
    if (snowParticles) {
        scene.remove(snowParticles);
        snowParticles = null;
    } else {
        snowParticles = createParticles('snow');
        scene.add(snowParticles);
        animateParticles(editor, 'snow');
    }
}

// Function to toggle rain effect
function toggleRainEffect(scene, editor) {
    if (rainParticles) {
        scene.remove(rainParticles);
        rainParticles = null;
    } else {
        rainParticles = createParticles('rain');
        scene.add(rainParticles);
        animateParticles(editor, 'rain');
    }
}

// Function to create particles (snow or rain)
function createParticles(type) {
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleIntensity * 3);

    for (let i = 0; i < particleIntensity; i++) {
        positions[i * 3] = Math.random() * 200 - 100; // X
        positions[i * 3 + 1] = Math.random() * 200; // Y
        positions[i * 3 + 2] = Math.random() * 200 - 100; // Z
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

    const material = new THREE.PointsMaterial({
        color: type === 'snow' ? 0xffffff : 0xaaaaaa,
        size: type === 'snow' ? 0.5 : 0.1,
        transparent: true,
    });

    return new THREE.Points(geometry, material);
}

// Update particle intensity
function updateParticleIntensity(scene) {
    if (snowParticles) {
        scene.remove(snowParticles);
        snowParticles = createParticles('snow');
        scene.add(snowParticles);
    }
    if (rainParticles) {
        scene.remove(rainParticles);
        rainParticles = createParticles('rain');
        scene.add(rainParticles);
    }
}

// Animate particles with time control
let clock = new THREE.Clock();

function animateParticles(editor, type) {
    function animate() {
        const deltaTime = clock.getDelta();

        if (type === 'snow' && snowParticles) {
            const positions = snowParticles.geometry.attributes.position.array;
            for (let i = 0; i < positions.length; i += 3) {
                positions[i + 1] -= movementSpeed * deltaTime * 20; // Move downwards
                positions[i] += Math.sin(Date.now() * 0.001) * 5 * deltaTime; // Wind effect

                if (positions[i + 1] < -100) {
                    positions[i + 1] = 200;
                }
            }
            snowParticles.geometry.attributes.position.needsUpdate = true;
        }

        if (type === 'rain' && rainParticles) {
            const positions = rainParticles.geometry.attributes.position.array;
            for (let i = 0; i < positions.length; i += 3) {
                positions[i + 1] -= movementSpeed * deltaTime * 100; // Move downwards faster
                if (positions[i + 1] < -100) {
                    positions[i + 1] = 200;
                }
            }
            rainParticles.geometry.attributes.position.needsUpdate = true;
        }

        editor.renderer.render(editor.scene, editor.camera);
        requestAnimationFrame(animate);
    }

    animate();
}

// Set up editor scene with controls
function initializeEditorScene(editor) {
    editor.scene = new THREE.Scene();
    editor.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    editor.camera.position.set(0, 50, 150);

    editor.renderer = new THREE.WebGLRenderer({ antialias: true });
    editor.renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(editor.renderer.domElement);

    const controls = new OrbitControls(editor.camera, editor.renderer.domElement);
    controls.update();

    const light = new THREE.PointLight(0xffffff, 1);
    light.position.set(50, 50, 50);
    editor.scene.add(light);

    const ambientLight = new THREE.AmbientLight(0x404040); // Soft ambient light
    editor.scene.add(ambientLight);

    editor.renderer.render(editor.scene, editor.camera);

    window.addEventListener('resize', () => {
        editor.camera.aspect = window.innerWidth / window.innerHeight;
        editor.camera.updateProjectionMatrix();
        editor.renderer.setSize(window.innerWidth, window.innerHeight);
    });
}

export { MenubarEnvironment, initializeEditorScene };
