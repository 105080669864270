// import React, { useState, useRef, useEffect } from 'react';
// import sb3fix from './sb3fix';

// export const WarningModal = ({ open, handleClose }) => {
//   if (!open) return null;

//   const inputRef = useRef(null);
//   const [files, setFiles] = useState([]);
//   const [isDragging, setIsDragging] = useState(false);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [progress, setProgress] = useState({});

//   // Handle drag over event to highlight drop area
//   const handleDragOver = (e) => {
//     if (e.dataTransfer.types.includes('Files')) {
//       e.preventDefault();
//       setIsDragging(true);
//     }
//   };

//   // Handle drag leave event to reset drop area
//   const handleDragLeave = () => {
//     setIsDragging(false);
//   };

//   // Handle drop event to add files
//   const handleDrop = (e) => {
//     if (e.dataTransfer.types.includes('Files')) {
//       e.preventDefault();
//       setIsDragging(false);
//       setFiles((prev) => [...prev, ...e.dataTransfer.files]);
//     }
//   };

//   // Remove a selected file from the list
//   const handleFileRemove = (index) => {
//     setFiles((prev) => prev.filter((_, i) => i !== index));
//   };

//   // Process each file with the sb3fix function
//   const processFile = async (file, index) => {
//     const container = document.createElement('div');
//     const output = document.querySelector('.log-container');
//     output.appendChild(container);

//     container.appendChild(
//       Object.assign(document.createElement('h3'), {
//         textContent: file.name,
//       })
//     );

//     const processingMessage = Object.assign(document.createElement('p'), {
//       textContent: 'Processing...',
//     });
//     container.appendChild(processingMessage);

//     const logContainer = Object.assign(document.createElement('details'), {
//       className: 'log-container',
//     });
//     const logSummary = Object.assign(document.createElement('summary'), {
//       className: 'log-summary',
//       textContent: 'View log',
//     });
//     const logMessages = Object.assign(document.createElement('textarea'), {
//       className: 'log-messages',
//       readOnly: true,
//       placeholder: '(empty)',
//     });
//     logContainer.appendChild(logSummary);
//     logContainer.appendChild(logMessages);
//     container.appendChild(logContainer);

//     try {
//       const startTime = performance.now();
//       console.log('Starting to fix the file:', file.name);

//       const fixed = await sb3fix.fixZip(file, {
//         logCallback: (message) => {
//           const millis = ((performance.now() - startTime) / 1000).toFixed(3);
//           logMessages.textContent += `${millis} ${message}\n`;
//         },
//         progressCallback: (progress) => {
//           setProgress((prevProgress) => ({
//             ...prevProgress,
//             [index]: progress,
//           }));
//         },
//       });

//       if (!fixed) {
//         throw new Error('Failed to fix the .sb3 file.');
//       }

//       const blob = new Blob([fixed], {
//         type: 'application/x.scratch.sb3',
//       });

//       if (!blob) {
//         throw new Error('Failed to create a Blob from the fixed file.');
//       }

//       const link = document.createElement('a');
//       link.href = URL.createObjectURL(blob);
//       link.download = `fixed_${file.name}`;
//       link.textContent = `Download fixed ${file.name}`;

//       processingMessage.insertAdjacentElement('afterend', link);
//       link.click(); // Automatically start the download

//       container.removeChild(processingMessage);
//     } catch (error) {
//       const errorElement = Object.assign(document.createElement('p'), {
//         className: 'error',
//         textContent: `ERROR: ${error.message}`,
//       });
//       processingMessage.insertAdjacentElement('afterend', errorElement);

//       container.removeChild(processingMessage);
//     }
//   };

//   // Process all selected files
//   const processFiles = async () => {
//     const output = document.querySelector('.log-container');
//     while (output.firstChild) {
//       output.removeChild(output.firstChild);
//     }
//     setIsProcessing(true);
//     for (let i = 0; i < files.length; i++) {
//       await processFile(files[i], i);
//     }
//     setIsProcessing(false);
//   };

//   // Handle file selection through input
//   useEffect(() => {
//     const input = inputRef.current;

//     if (input) {
//       if (input.files.length > 0) {
//         setFiles((prev) => [...prev, ...input.files]);
//       }
//     }

//     return () => {
//       // Cleanup if necessary
//     };
//   }, []);

//   // Modal and content styles
//   const modalStyle = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     // width: '100%',
//     // height: '100%',
//     backgroundColor: '#4d97ffe6',
//     // display: 'flex',
//     // justifyContent: 'center',
//     // alignItems: 'center',
//     zIndex: 510,
//   };

//   const contentStyle = {
//     // backgroundColor: '#fff',
//     // width: '60%',
//     // maxWidth: '800px',
//     // borderRadius: '10px',
//     // boxShadow: '4px solid hsla(0, 100%, 100%, 0.25)',
//     // textAlign: 'center',
//     margin: '15vh 45vh',
//     outline: 'none',
//     border: '4px solid hsla(0, 100%, 100%, 0.25)',
//     padding: '0',
//     borderRadius: '0.5rem',
//     color: '#hsla(225, 15%, 40%, 1)',
//     overflow: 'hidden'
//   };

//   const dropAreaStyle = {
//     border: '2px dashed #2b256d',
//     backgroundColor: isDragging ? '#e0e4ff' : '#f9f9f9',
//     borderRadius: '8px',
//     padding: '20px',
//     margin: '20px 0',
//     transition: 'background-color 0.3s ease, border-color 0.3s ease',
//     cursor: 'pointer',
//   };

//   return (
//     <div
//       style={modalStyle}
//       onDragOver={handleDragOver}
//       onDragLeave={handleDragLeave}
//       onDrop={handleDrop}
//     >
//       <div style={contentStyle}>
//         <div style={{display : 'flex'}}>
//         <div
//           style={{
//             color: '#fff',
//             padding: '15px',
//             backgroundColor: 'rgb(0 188 143)',
//             textAlign: 'center',
//             fontSize: '1rem',
//             fontWeight: '600'
//           }}
//           >
//           sb3fix - Fix Corrupted SB3 Files
//         </div>
//         {/* <div style={{
//           backgroundColor: 'rgb(255 255 255)',
//           textAlign: 'center',
//           paddingTop: '1rem',
//           paddingBottom: '0.75rem',
//           paddingLeft: '0.75rem',
//           paddingRight: '0.75rem',
//           fontSize: '0.85rem',
//           fontWeight: '600'
//           }}> */}
//           <div
//             style={{
//               padding: '10px 20px',
//               backgroundColor: '#2b256d',
//               color: '#fff',
//               border: 'none',
//               borderRadius: '5px',
//               cursor: 'pointer',
//             }}
//             onClick={handleClose}
//             >
//             Close
//           </div>
//             </div>
//           {/* </div> */}

//         {/* <div style={{ padding: '10px', color: '#333', backgroundColor: 'hsl(211.11deg 93.1% 94.31%)' }}> */}
//         <div style={{ padding: '10px', color: '#333', backgroundColor: 'hsl(211.11deg 93.1% 94.31%)' }}>
//           <input
//             ref={inputRef}
//             style={{
//               display: 'block',
//               margin: '10px auto',
//               padding: '10px',
//               border: '1px solid #ccc',
//               borderRadius: '4px',
//               fontSize: '1rem',
//               width: '50%',
//             }}
//             type="file"
//             accept=".sb3, .sprite3"
//             multiple
//             onChange={(e) => setFiles((prev) => [...prev, ...e.target.files])}
//             />
//             </div>

//           {/* <div style={dropAreaStyle} onClick={() => inputRef.current.click()}>
//             Drag and drop files here, or click to select files.
//           </div> */}

//           {/* <div>
//             <h4>Selected Files:</h4>
//             {files.map((file, index) => (
//               <div key={index} style={{ marginBottom: '10px' }}>
//                 {file.name}
//                 <button
//                   style={{
//                     marginLeft: '10px',
//                     padding: '5px 10px',
//                     backgroundColor: 'red',
//                     color: 'white',
//                     border: 'none',
//                     borderRadius: '5px',
//                     cursor: 'pointer',
//                   }}
//                   onClick={() => handleFileRemove(index)}
//                 >
//                   Remove
//                 </button>
//                 <div style={{ marginTop: '5px' }}>
//                   {progress[index] ? `Progress: ${progress[index]}%` : 'No progress'}
//                 </div>
//               </div>
//             ))}
//           </div> */}
// <div style={{
//           textAlign: 'center',
//           paddingTop: '1rem',
//           paddingBottom: '0.75rem',
//           paddingLeft: '0.75rem',
//           paddingRight: '0.75rem',
//           fontSize: '0.85rem',
//           fontWeight: '600'
//         }}>
//           <button
//             style={{
//               padding: '10px 20px',
//               backgroundColor: '#2b256d',
//               color: '#fff',
//               border: 'none',
//               borderRadius: '5px',
//               cursor: 'pointer',
//             }}
//             onClick={processFiles}
//             disabled={isProcessing || files.length === 0}
//             >
//             {isProcessing ? 'Processing...' : 'Fix Files'}
//           </button>

//           <div className="log-container"></div>
//         {/* </div> */}
//         </div>
//         {/* <div style={{
//           backgroundColor: 'rgb(255 255 255)',
//           textAlign: 'center',
//           paddingTop: '1rem',
//           paddingBottom: '0.75rem',
//           paddingLeft: '0.75rem',
//           paddingRight: '0.75rem',
//           fontSize: '0.85rem',
//           fontWeight: '600'
//         }}>
//           <button
//             style={{
//               padding: '10px 20px',
//               backgroundColor: '#2b256d',
//               color: '#fff',
//               border: 'none',
//               borderRadius: '5px',
//               cursor: 'pointer',
//             }}
//             onClick={handleClose}
//           >
//             Close
//           </button>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default WarningModal;






import React, { useState, useRef, useEffect } from 'react';
import sb3fix from './sb3fix';
// import CloseButton from '../modal/modal.jsx';
import closebutton from './cross.png'

export const WarningModal = ({ open, handleClose }) => {
  if (!open) return null;

  const inputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState({});

  // Handle drag over event to highlight drop area
  const handleDragOver = (e) => {
    if (e.dataTransfer.types.includes('Files')) {
      e.preventDefault();
      setIsDragging(true);
    }
  };

  // Handle drag leave event to reset drop area
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // Handle drop event to add files
  const handleDrop = (e) => {
    if (e.dataTransfer.types.includes('Files')) {
      e.preventDefault();
      setIsDragging(false);
      setFiles((prev) => [...prev, ...e.dataTransfer.files]);
    }
  };

  // Remove a selected file from the list
  const handleFileRemove = (index) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  // Process each file with the sb3fix function
  const processFile = async (file, index) => {
    const container = document.createElement('div');
    const output = document.querySelector('.log-container');
    output.appendChild(container);

    container.appendChild(
      Object.assign(document.createElement('h3'), {
        textContent: file.name,
      })
    );

    const processingMessage = Object.assign(document.createElement('p'), {
      textContent: 'Processing...',
    });
    container.appendChild(processingMessage);

    const logContainer = Object.assign(document.createElement('details'), {
      className: 'log-container',
    });
    const logSummary = Object.assign(document.createElement('summary'), {
      className: 'log-summary',
      textContent: 'View log',
    });
    const logMessages = Object.assign(document.createElement('textarea'), {
      className: 'log-messages',
      readOnly: true,
      placeholder: '(empty)',
    });
    logContainer.appendChild(logSummary);
    logContainer.appendChild(logMessages);
    container.appendChild(logContainer);

    try {
      const startTime = performance.now();
      console.log('Starting to fix the file:', file.name);

      const fixed = await sb3fix.fixZip(file, {
        logCallback: (message) => {
          const millis = ((performance.now() - startTime) / 1000).toFixed(3);
          logMessages.textContent += `${millis} ${message}\n`;
        },
        progressCallback: (progress) => {
          setProgress((prevProgress) => ({
            ...prevProgress,
            [index]: progress,
          }));
        },
      });

      if (!fixed) {
        throw new Error('Failed to fix the .sb3 file.');
      }

      const blob = new Blob([fixed], {
        type: 'application/x.scratch.sb3',
      });

      if (!blob) {
        throw new Error('Failed to create a Blob from the fixed file.');
      }

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `fixed_${file.name}`;
      link.textContent = `Download fixed ${file.name}`;

      processingMessage.insertAdjacentElement('afterend', link);
      link.click(); // Automatically start the download

      container.removeChild(processingMessage);
    } catch (error) {
      const errorElement = Object.assign(document.createElement('p'), {
        className: 'error',
        textContent: `ERROR: ${error.message}`,
      });
      processingMessage.insertAdjacentElement('afterend', errorElement);

      container.removeChild(processingMessage);
    }
  };

  // Process all selected files
  const processFiles = async () => {
    const output = document.querySelector('.log-container');
    while (output.firstChild) {
      output.removeChild(output.firstChild);
    }
    setIsProcessing(true);
    for (let i = 0; i < files.length; i++) {
      await processFile(files[i], i);
    }
    setIsProcessing(false);
  };

  // Handle file selection through input
  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      if (input.files.length > 0) {
        setFiles((prev) => [...prev, ...input.files]);
      }
    }

    return () => {
      // Cleanup if necessary
    };
  }, []);

  // Modal and content styles
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#4d97ffe6',
    zIndex: 510,
  };

  const contentStyle = {
    margin: '15vh 45vh',
    outline: 'none',
    border: '4px solid hsla(0, 100%, 100%, 0.25)',
    padding: '0',
    borderRadius: '0.5rem',
    color: '#hsla(225, 15%, 40%, 1)',
    overflow: 'hidden'
  };

  const dropAreaStyle = {
    border: '2px dashed #2b256d',
    backgroundColor: isDragging ? '#e0e4ff' : '#f9f9f9',
    borderRadius: '8px',
    padding: '20px',
    margin: '20px 0',
    transition: 'background-color 0.3s ease, border-color 0.3s ease',
    cursor: 'pointer',
  };
 
  return (
    <div
      style={modalStyle}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div style={contentStyle}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              color: '#fff',
              padding: '1.5vh',
              paddingRight: '10.5vh',
              paddingLeft: '10.5vh',
              backgroundColor: 'rgb(0 188 143)',
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: '600'
            }}
          >
            sb3fix - Fix Corrupted SB3 Files
          </div>
          <div
            style={{
              padding: '1.5vh',
              backgroundColor: 'rgb(0 188 143)',
              color: '#fff',
              border: 'none',
              paddingLeft: '2vh',
              paddingRight: '2vh',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <img src={closebutton} style={{ width: '1.5vh', height: '1.5vh', cursor: 'pointer', margin: '2px', }}/>
          </div>
          {/* <CloseButton
            onClick={handleClose}
          /> */}
        </div>

        <div style={{ padding: '10px', color: '#333', backgroundColor: 'hsl(211.11deg 93.1% 94.31%)' }}>
          <input
            ref={inputRef}
            style={{
              display: 'block',
              margin: '10px auto',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '1rem',
              width: '50%',
            }}
            type="file"
            accept=".sb3, .sprite3"
            multiple
            onChange={(e) => setFiles((prev) => [...prev, ...e.target.files])}
          />
        </div>

        <div style={{
          textAlign: 'center',
          paddingTop: '1rem',
          paddingBottom: '0.75rem',
          paddingLeft: '0.75rem',
          paddingRight: '0.75rem',
          fontSize: '0.85rem',
          fontWeight: '600',
          backgroundColor: '#ffffff',
        }}>
          <button
            style={{
              padding: '10px 20px',
              backgroundColor: '#2b256d',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={processFiles}
            disabled={isProcessing || files.length === 0}
          >
            {isProcessing ? 'Processing...' : 'Fix Files'}
          </button>

          <div className="log-container" style={{ color: 'black' }}></div>
        </div>

      </div>
    </div>
  );
};

export default WarningModal;

