import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../box/box.jsx';
import Dots from './dots.jsx';
import bluetoothIcon from './icons/bluetooth-white.svg';
import usbIcon from './icons/usb-white.svg';
import styles from './connection-modal.css';
import classNames from 'classnames';

const ConnectedStep = props => (
    <Box className={styles.body}>
        <Box className={styles.activityArea}>
            <Box className={styles.centeredRow}>
                <div className={styles.peripheralActivity}>
                    <img
                        className={styles.peripheralActivityIcon}
                        src={props.connectionIconURL}
                    />
                    {props.isSerialport ? (
                        <img
                            className={styles.bluetoothConnectingIcon}
                            src={usbIcon}
                        />) :
                        (
                            <img
                                className={styles.bluetoothConnectingIcon}
                                src={bluetoothIcon}
                            />)}
                </div>
            </Box>
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                <FormattedMessage
                    defaultMessage="Connected"
                    description="Message indicating that a device was connected"
                    id="gui.connection.connected"
                />
            </Box>
            <Dots
                success
                className={styles.bottomAreaItem}
                total={3}
            />
            <div className={classNames(styles.bottomAreaItem, styles.cornerButtons)}>
                <button
                    className={classNames(styles.redButton, styles.connectionButton)}
                    onClick={props.onDisconnect}
                >
                    <FormattedMessage
                        defaultMessage="Disconnect"
                        description="Button to disconnect the device"
                        id="gui.connection.disconnect"
                    />
                </button>

                {/* Conditionally render the "Go to Editor" button if Bluetooth is connected */}
                {props.isSerialport && (
                    <button
                        className={styles.connectionButton}
                        onClick={props.isSerialport ? props.onCancel: props.onGoToEditor}
                    >
                        <FormattedMessage
                            defaultMessage="Go to Editor"
                            description="Button to return to the editor"
                            id="gui.connection.go-to-editor"
                        />
                    </button>
                )}
            </div>
        </Box>
    </Box>
);

ConnectedStep.propTypes = {
    connectionIconURL: PropTypes.string.isRequired,
    isSerialport: PropTypes.bool,
    onCancel: PropTypes.func,
    onDisconnect: PropTypes.func
};

export default ConnectedStep;
