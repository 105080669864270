function Strings( config ) {
	
	console.log("editor call in: Strings.js");
	const language = config.getKey( 'language' );

	const values = {

		en: {

			'prompt/file/open': 'Any unsaved data will be lost. Are you sure?',
			'prompt/file/failedToOpenProject': 'Failed to open project!',
			'prompt/file/export/noMeshSelected': 'No Mesh selected!',
			'prompt/file/export/noObjectSelected': 'No Object selected!',
			'prompt/script/remove': 'Are you sure?',
			'prompt/history/clear': 'The Undo/Redo History will be cleared. Are you sure?',
			'prompt/history/preserve': 'The history will be preserved across sessions.\nThis can have an impact on performance when working with textures.',
			'prompt/history/forbid': 'Undo/Redo disabled while scene is playing.',
			'prompt/rendering/realistic/unsupportedMaterial': 'REALISTIC Shading: Only MeshStandardMaterial and MeshPhysicalMaterial are supported',

			'command/AddObject': 'Add Object',
			'command/AddScript': 'Add Script',
			'command/MoveObject': 'Move Object',
			'command/MultiCmds': 'Multiple Changes',
			'command/RemoveObject': 'Remove Object',
			'command/RemoveScript': 'Remove Script',
			'command/SetColor': 'Set Color',
			'command/SetGeometry': 'Set Geometry',
			'command/SetGeometryValue': 'Set Geometry Value',
			'command/SetMaterialColor': 'Set Material Color',
			'command/SetMaterial': 'Set Material',
			'command/SetMaterialMap': 'Set Material Map',
			'command/SetMaterialRange': 'Set Material Range',
			'command/SetMaterialValue': 'Set Material Value',
			'command/SetMaterialVector': 'Set Material Vector',
			'command/SetPosition': 'Set Position',
			'command/SetRotation': 'Set Rotation',
			'command/SetScale': 'Set Scale',
			'command/SetScene': 'Set Scene',
			'command/SetScriptValue': 'Set Script Value',
			'command/SetShadowValue': 'Set Shadow Value',
			'command/SetUuid': 'Set UUID',
			'command/SetValue': 'Set Value',

			'menubar/file': 'File',
			'menubar/file/new': 'New',
			'menubar/file/new/empty': 'Empty',
			'menubar/file/new/Arkanoid': 'Arkanoid',
			'menubar/file/new/Camera': 'Camera',
			'menubar/file/new/Particles': 'Particles',
			'menubar/file/new/Pong': 'Pong',
			'menubar/file/new/Shaders': 'Shaders',
			'menubar/file/open': 'Open',
			'menubar/file/save': 'Save',
			'menubar/file/import': 'Import',
			'menubar/file/export': 'Export',

			'menubar/edit': 'Edit',
			'menubar/edit/undo': 'Undo',
			'menubar/edit/redo': 'Redo',
			'menubar/edit/center': 'Center',
			'menubar/edit/clone': 'Clone',
			'menubar/edit/delete': 'Delete',

			'menubar/add': 'Add',
			'menubar/add/group': 'Group',

			'menubar/add/mesh': 'Mesh',
			'menubar/add/mesh/plane': 'Plane',
			'menubar/add/mesh/box': 'Box',
			'menubar/add/mesh/capsule': 'Capsule',
			'menubar/add/mesh/circle': 'Circle',
			'menubar/add/mesh/cylinder': 'Cylinder',
			'menubar/add/mesh/ring': 'Ring',
			'menubar/add/mesh/sphere': 'Sphere',
			'menubar/add/mesh/dodecahedron': 'Dodecahedron',
			'menubar/add/mesh/icosahedron': 'Icosahedron',
			'menubar/add/mesh/octahedron': 'Octahedron',
			'menubar/add/mesh/tetrahedron': 'Tetrahedron',
			'menubar/add/mesh/torus': 'Torus',
			'menubar/add/mesh/tube': 'Tube',
			'menubar/add/mesh/torusknot': 'TorusKnot',
			'menubar/add/mesh/lathe': 'Lathe',
			'menubar/add/mesh/sprite': 'Sprite',

			'menubar/add/light': 'Light',
			'menubar/add/light/ambient': 'Ambient',
			'menubar/add/light/directional': 'Directional',
			'menubar/add/light/hemisphere': 'Hemisphere',
			'menubar/add/light/point': 'Point',
			'menubar/add/light/spot': 'Spot',

			'menubar/add/camera': 'Camera',
			'menubar/add/camera/perspective': 'Perspective',
			'menubar/add/camera/orthographic': 'Orthographic',

			'menubar/status/autosave': 'autosave',

			'menubar/view': 'View',
			'menubar/view/fullscreen': 'Fullscreen',
			'menubar/view/gridHelper': 'Grid Helper',
			'menubar/view/cameraHelpers': 'Camera Helpers',
			'menubar/view/lightHelpers': 'Light Helpers',
			'menubar/view/skeletonHelpers': 'Skeleton Helpers',

			'menubar/help': 'Help',
			'menubar/help/source_code': 'Source Code',
			'menubar/help/icons': 'Icon Pack',
			'menubar/help/about': 'About',
			'menubar/help/manual': 'Manual',
			
			"menubar/environment": "Environment",
            "menubar/environment/rain": "Rain",
            "menubar/environment/sky": "Sky",
            "menubar/environment/snow": "Snow",
			"menubar/environment/sun": "Sun",
			"menubar/environment/cloud": "Cloud",
			"menubar/environment/water": "Water",

			'sidebar/animations': 'Animations',
			'sidebar/animations/play': 'Play',
			'sidebar/animations/stop': 'Stop',
			'sidebar/animations/timescale': 'Time Scale',

			'sidebar/scene': 'Scene',
			'sidebar/scene/background': 'Background',
			'sidebar/scene/environment': 'Environment',
			'sidebar/scene/fog': 'Fog',

			'sidebar/properties/object': 'Object',
			'sidebar/properties/geometry': 'Geometry',
			'sidebar/properties/material': 'Material',
			'sidebar/properties/script': 'Script',

			'sidebar/object/type': 'Type',
			'sidebar/object/new': 'New',
			'sidebar/object/uuid': 'UUID',
			'sidebar/object/name': 'Name',
			'sidebar/object/position': 'Position',
			'sidebar/object/rotation': 'Rotation',
			'sidebar/object/scale': 'Scale',
			'sidebar/object/fov': 'Fov',
			'sidebar/object/left': 'Left',
			'sidebar/object/right': 'Right',
			'sidebar/object/top': 'Top',
			'sidebar/object/bottom': 'Bottom',
			'sidebar/object/near': 'Near',
			'sidebar/object/far': 'Far',
			'sidebar/object/intensity': 'Intensity',
			'sidebar/object/color': 'Color',
			'sidebar/object/groundcolor': 'Ground Color',
			'sidebar/object/distance': 'Distance',
			'sidebar/object/angle': 'Angle',
			'sidebar/object/penumbra': 'Penumbra',
			'sidebar/object/decay': 'Decay',
			'sidebar/object/shadow': 'Shadow',
			'sidebar/object/shadowIntensity': 'Shadow Intensity',
			'sidebar/object/shadowBias': 'Shadow Bias',
			'sidebar/object/shadowNormalBias': 'Shadow Normal Bias',
			'sidebar/object/shadowRadius': 'Shadow Radius',
			'sidebar/object/cast': 'cast',
			'sidebar/object/receive': 'receive',
			'sidebar/object/visible': 'Visible',
			'sidebar/object/frustumcull': 'Frustum Cull',
			'sidebar/object/renderorder': 'Render Order',
			'sidebar/object/userdata': 'User data',
			'sidebar/object/export': 'Export JSON',

			'sidebar/geometry/type': 'Type',
			'sidebar/geometry/new': 'New',
			'sidebar/geometry/uuid': 'UUID',
			'sidebar/geometry/name': 'Name',
			'sidebar/geometry/bounds': 'Bounds',
			'sidebar/geometry/userdata': 'User Data',
			'sidebar/geometry/show_vertex_normals': 'Show Vertex Normals',
			'sidebar/geometry/compute_vertex_normals': 'Compute Vertex Normals',
			'sidebar/geometry/compute_vertex_tangents': 'Compute Tangents',
			'sidebar/geometry/center': 'Center',
			'sidebar/geometry/export': 'Export JSON',

			'sidebar/geometry/box_geometry/width': 'Width',
			'sidebar/geometry/box_geometry/height': 'Height',
			'sidebar/geometry/box_geometry/depth': 'Depth',
			'sidebar/geometry/box_geometry/widthseg': 'Width Seg',
			'sidebar/geometry/box_geometry/heightseg': 'Height Seg',
			'sidebar/geometry/box_geometry/depthseg': 'Depth Seg',

			'sidebar/geometry/buffer_geometry/attributes': 'Attributes',
			'sidebar/geometry/buffer_geometry/index': 'index',
			'sidebar/geometry/buffer_geometry/morphAttributes': 'Morph Attributes',
			'sidebar/geometry/buffer_geometry/morphRelative': 'Morph Relative',

			'sidebar/geometry/capsule_geometry/radius': 'Radius',
			'sidebar/geometry/capsule_geometry/length': 'Length',
			'sidebar/geometry/capsule_geometry/capseg': 'Cap Seg',
			'sidebar/geometry/capsule_geometry/radialseg': 'Radial Seg',

			'sidebar/geometry/circle_geometry/radius': 'Radius',
			'sidebar/geometry/circle_geometry/segments': 'Segments',
			'sidebar/geometry/circle_geometry/thetastart': 'Theta start',
			'sidebar/geometry/circle_geometry/thetalength': 'Theta length',

			'sidebar/geometry/cylinder_geometry/radiustop': 'Radius top',
			'sidebar/geometry/cylinder_geometry/radiusbottom': 'Radius bottom',
			'sidebar/geometry/cylinder_geometry/height': 'Height',
			'sidebar/geometry/cylinder_geometry/radialsegments': 'Radial segments',
			'sidebar/geometry/cylinder_geometry/heightsegments': 'Height segments',
			'sidebar/geometry/cylinder_geometry/openended': 'Open ended',

			'sidebar/geometry/extrude_geometry/curveSegments': 'Curve Segments',
			'sidebar/geometry/extrude_geometry/steps': 'Steps',
			'sidebar/geometry/extrude_geometry/depth': 'Depth',
			'sidebar/geometry/extrude_geometry/bevelEnabled': 'Bevel',
			'sidebar/geometry/extrude_geometry/bevelThickness': 'Thickness',
			'sidebar/geometry/extrude_geometry/bevelSize': 'Size',
			'sidebar/geometry/extrude_geometry/bevelOffset': 'Offset',
			'sidebar/geometry/extrude_geometry/bevelSegments': 'Segments',
			'sidebar/geometry/extrude_geometry/shape': 'Convert to Shape',

			'sidebar/geometry/dodecahedron_geometry/radius': 'Radius',
			'sidebar/geometry/dodecahedron_geometry/detail': 'Detail',

			'sidebar/geometry/icosahedron_geometry/radius': 'Radius',
			'sidebar/geometry/icosahedron_geometry/detail': 'Detail',

			'sidebar/geometry/octahedron_geometry/radius': 'Radius',
			'sidebar/geometry/octahedron_geometry/detail': 'Detail',

			'sidebar/geometry/tetrahedron_geometry/radius': 'Radius',
			'sidebar/geometry/tetrahedron_geometry/detail': 'Detail',

			'sidebar/geometry/lathe_geometry/segments': 'Segments',
			'sidebar/geometry/lathe_geometry/phistart': 'Phi start (°)',
			'sidebar/geometry/lathe_geometry/philength': 'Phi length (°)',
			'sidebar/geometry/lathe_geometry/points': 'Points',

			'sidebar/geometry/plane_geometry/width': 'Width',
			'sidebar/geometry/plane_geometry/height': 'Height',
			'sidebar/geometry/plane_geometry/widthsegments': 'Width segments',
			'sidebar/geometry/plane_geometry/heightsegments': 'Height segments',

			'sidebar/geometry/ring_geometry/innerRadius': 'Inner radius',
			'sidebar/geometry/ring_geometry/outerRadius': 'Outer radius',
			'sidebar/geometry/ring_geometry/thetaSegments': 'Theta segments',
			'sidebar/geometry/ring_geometry/phiSegments': 'Phi segments',
			'sidebar/geometry/ring_geometry/thetastart': 'Theta start',
			'sidebar/geometry/ring_geometry/thetalength': 'Theta length',

			'sidebar/geometry/shape_geometry/curveSegments': 'Curve Segments',
			'sidebar/geometry/shape_geometry/extrude': 'Extrude',

			'sidebar/geometry/sphere_geometry/radius': 'Radius',
			'sidebar/geometry/sphere_geometry/widthsegments': 'Width segments',
			'sidebar/geometry/sphere_geometry/heightsegments': 'Height segments',
			'sidebar/geometry/sphere_geometry/phistart': 'Phi start',
			'sidebar/geometry/sphere_geometry/philength': 'Phi length',
			'sidebar/geometry/sphere_geometry/thetastart': 'Theta start',
			'sidebar/geometry/sphere_geometry/thetalength': 'Theta length',

			'sidebar/geometry/torus_geometry/radius': 'Radius',
			'sidebar/geometry/torus_geometry/tube': 'Tube',
			'sidebar/geometry/torus_geometry/radialsegments': 'Radial segments',
			'sidebar/geometry/torus_geometry/tubularsegments': 'Tubular segments',
			'sidebar/geometry/torus_geometry/arc': 'Arc',

			'sidebar/geometry/torusKnot_geometry/radius': 'Radius',
			'sidebar/geometry/torusKnot_geometry/tube': 'Tube',
			'sidebar/geometry/torusKnot_geometry/tubularsegments': 'Tubular segments',
			'sidebar/geometry/torusKnot_geometry/radialsegments': 'Radial segments',
			'sidebar/geometry/torusKnot_geometry/p': 'P',
			'sidebar/geometry/torusKnot_geometry/q': 'Q',

			'sidebar/geometry/tube_geometry/path': 'Path',
			'sidebar/geometry/tube_geometry/radius': 'Radius',
			'sidebar/geometry/tube_geometry/tube': 'Tube',
			'sidebar/geometry/tube_geometry/tubularsegments': 'Tubular segments',
			'sidebar/geometry/tube_geometry/radialsegments': 'Radial segments',
			'sidebar/geometry/tube_geometry/closed': 'Closed',
			'sidebar/geometry/tube_geometry/curvetype': 'Curve Type',
			'sidebar/geometry/tube_geometry/tension': 'Tension',

			'sidebar/material/new': 'New',
			'sidebar/material/copy': 'Copy',
			'sidebar/material/paste': 'Paste',
			'sidebar/material/slot': 'Slot',
			'sidebar/material/type': 'Type',
			'sidebar/material/uuid': 'UUID',
			'sidebar/material/name': 'Name',
			'sidebar/material/program': 'Program',
			'sidebar/material/info': 'Info',
			'sidebar/material/vertex': 'Vert',
			'sidebar/material/fragment': 'Frag',
			'sidebar/material/color': 'Color',
			'sidebar/material/depthPacking': 'Depth Packing',
			'sidebar/material/roughness': 'Roughness',
			'sidebar/material/metalness': 'Metalness',
			'sidebar/material/reflectivity': 'Reflectivity',
			'sidebar/material/emissive': 'Emissive',
			'sidebar/material/specular': 'Specular',
			'sidebar/material/shininess': 'Shininess',
			'sidebar/material/clearcoat': 'Clearcoat',
			'sidebar/material/clearcoatroughness': 'Clearcoat Roughness',
			'sidebar/material/dispersion': 'Dispersion',
			'sidebar/material/ior': 'IOR',
			'sidebar/material/iridescence': 'Iridescence',
			'sidebar/material/iridescenceIOR': 'Thin-Film IOR',
			'sidebar/material/iridescenceThicknessMax': 'Thin-Film Thickness',
			'sidebar/material/sheen': 'Sheen',
			'sidebar/material/sheenroughness': 'Sheen Roughness',
			'sidebar/material/sheencolor': 'Sheen Color',
			'sidebar/material/transmission': 'Transmission',
			'sidebar/material/attenuationDistance': 'Attenuation Distance',
			'sidebar/material/attenuationColor': 'Attenuation Color',
			'sidebar/material/thickness': 'Thickness',
			'sidebar/material/vertexcolors': 'Vertex Colors',
			'sidebar/material/matcap': 'Matcap',
			'sidebar/material/map': 'Map',
			'sidebar/material/alphamap': 'Alpha Map',
			'sidebar/material/bumpmap': 'Bump Map',
			'sidebar/material/normalmap': 'Normal Map',
			'sidebar/material/clearcoatmap': 'Clearcoat Map',
			'sidebar/material/clearcoatnormalmap': 'Clearcoat Normal Map',
			'sidebar/material/clearcoatroughnessmap': 'Clearcoat Roughness Map',
			'sidebar/material/displacementmap': 'Displace Map',
			'sidebar/material/roughnessmap': 'Rough. Map',
			'sidebar/material/metalnessmap': 'Metal. Map',
			'sidebar/material/specularmap': 'Specular Map',
			'sidebar/material/iridescencemap': 'Irid. Map',
			'sidebar/material/iridescencethicknessmap': 'Thin-Film Thickness Map',
			'sidebar/material/sheencolormap': 'Sheen Color Map',
			'sidebar/material/sheenroughnessmap': 'Sheen Rough. Map',
			'sidebar/material/envmap': 'Env Map',
			'sidebar/material/lightmap': 'Light Map',
			'sidebar/material/aomap': 'AO Map',
			'sidebar/material/emissivemap': 'Emissive Map',
			'sidebar/material/gradientmap': 'Gradient Map',
			'sidebar/material/transmissionmap': 'Transmission Map',
			'sidebar/material/thicknessmap': 'Thickness Map',
			'sidebar/material/side': 'Side',
			'sidebar/material/size': 'Size',
			'sidebar/material/sizeAttenuation': 'Size Attenuation',
			'sidebar/material/flatShading': 'Flat Shading',
			'sidebar/material/blending': 'Blending',
			'sidebar/material/opacity': 'Opacity',
			'sidebar/material/transparent': 'Transparent',
			'sidebar/material/forcesinglepass': 'Force Single Pass',
			'sidebar/material/alphatest': 'Alpha Test',
			'sidebar/material/depthtest': 'Depth Test',
			'sidebar/material/depthwrite': 'Depth Write',
			'sidebar/material/wireframe': 'Wireframe',
			'sidebar/material/userdata': 'User data',
			'sidebar/material/export': 'Export JSON',

			'sidebar/script/new': 'New',
			'sidebar/script/edit': 'Edit',
			'sidebar/script/remove': 'Remove',

			'sidebar/project': 'Project',
			'sidebar/project/antialias': 'Antialias',
			'sidebar/project/shadows': 'Shadows',
			'sidebar/project/toneMapping': 'Tonemapping',
			'sidebar/project/materials': 'Materials',
			'sidebar/project/Assign': 'Assign',

			'sidebar/project/app': 'App',
			'sidebar/project/app/play': 'Play',
			'sidebar/project/app/stop': 'Stop',
			'sidebar/project/app/title': 'Title',
			'sidebar/project/app/editable': 'Editable',
			'sidebar/project/app/publish': 'Publish',

			'sidebar/project/image': 'Image',
			'sidebar/project/image/samples': 'Samples',
			'sidebar/project/video': 'Video',

			'sidebar/project/shading': 'Shading',
			'sidebar/project/resolution': 'Resolution',
			'sidebar/project/duration': 'Duration',
			'sidebar/project/render': 'Render',

			'sidebar/settings': 'Settings',
			'sidebar/settings/language': 'Language',

			'sidebar/settings/shortcuts': 'Shortcuts',
			'sidebar/settings/shortcuts/translate': 'Translate',
			'sidebar/settings/shortcuts/rotate': 'Rotate',
			'sidebar/settings/shortcuts/scale': 'Scale',
			'sidebar/settings/shortcuts/undo': 'Undo',
			'sidebar/settings/shortcuts/focus': 'Focus',

			'sidebar/history': 'History',
			'sidebar/history/clear': 'Clear',
			'sidebar/history/persistent': 'Persistent',

			'toolbar/translate': 'Translate',
			'toolbar/rotate': 'Rotate',
			'toolbar/scale': 'Scale',
			'toolbar/local': 'Local',

			'viewport/controls/grid': 'Grid',
			'viewport/controls/helpers': 'Helpers',

			'viewport/info/object': 'Object',
			'viewport/info/objects': 'Objects',
			'viewport/info/vertex': 'Vertex',
			'viewport/info/vertices': 'Vertices',
			'viewport/info/triangle': 'Triangle',
			'viewport/info/triangles': 'Triangles',
			'viewport/info/sample': 'Sample',
			'viewport/info/samples': 'Samples',
			'viewport/info/rendertime': 'Render time',

			'script/title/vertexShader': 'Vertex Shader',
			'script/title/fragmentShader': 'Fragment Shader',
			'script/title/programInfo': 'Program Properties'

		},

		fr: {

			'prompt/file/open': 'Toutes les données non enregistrées seront perdues Êtes-vous sûr ?',
			'prompt/file/failedToOpenProject': 'Échec de l\'ouverture du projet !',
			'prompt/file/export/noMeshSelected': 'Aucun maillage sélectionné !',
			'prompt/file/export/noObjectSelected': 'Aucun objet sélectionné !',
			'prompt/script/remove': 'Es-tu sûr?',
			'prompt/history/clear': 'L\'historique d\'annulation/rétablissement sera effacé Êtes-vous sûr ?',
			'prompt/history/preserve': 'L\'histoire sera conservée entre les sessions.\nCela peut avoir un impact sur les performances lors de la manipulation des textures.',
			'prompt/history/forbid': 'Les fonctions Annuler/Rétablir sont désactivées pendant la lecture de la scène.',
			'prompt/rendering/realistic/unsupportedMaterial': 'Ombrage REALISTIC : seuls MeshStandardMaterial et MeshPhysicalMaterial sont pris en charge',

			'command/AddObject': 'Ajouter un objet',
			'command/AddScript': 'Ajouter un script',
			'command/MoveObject': 'Déplacer l’objet',
			'command/MultiCmds': 'Changements multiples',
			'command/RemoveObject': 'Supprimer l’objet',
			'command/RemoveScript': 'Supprimer le script',
			'command/SetColor': 'Définir la couleur',
			'command/SetGeometry': 'Définir la géométrie',
			'command/SetGeometryValue': 'Définir la valeur de la géométrie',
			'command/SetMaterialColor': 'Définir la couleur du matériau',
			'command/SetMaterial': 'Matériel de l’ensemble',
			'command/SetMaterialMap': 'Définir la carte des matériaux',
			'command/SetMaterialRange': 'Définir la gamme de matériaux',
			'command/SetMaterialValue': 'Définir la valeur du matériau',
			'command/SetMaterialVector': 'Définir le vecteur de matériau',
			'command/SetPosition': 'Définir la position',
			'command/SetRotation': 'Définir la rotation',
			'command/SetScale': 'Définir l’échelle',
			'command/SetScene': 'Planter le décor',
			'command/SetScriptValue': 'Définir la valeur du script',
			'command/SetShadowValue': 'Set Shadow Value',
			'command/SetUuid': 'Définir l’UUID',
			'command/SetValue': 'Définir la valeur',

			'menubar/file': 'Fichier',
			'menubar/file/new': 'Nouveau',
			'menubar/file/new/empty': 'Vide',
			'menubar/file/new/Arkanoid': 'Arkanoid',
			'menubar/file/new/Camera': 'Camera',
			'menubar/file/new/Particles': 'Particles',
			'menubar/file/new/Pong': 'Pong',
			'menubar/file/new/Shaders': 'Shaders',
			'menubar/file/open': 'Open',
			'menubar/file/save': 'Save',
			'menubar/file/import': 'Importer',
			'menubar/file/export': 'Exporter',

			'menubar/edit': 'Edition',
			'menubar/edit/undo': 'Annuler',
			'menubar/edit/redo': 'Refaire',
			'menubar/edit/center': 'Center',
			'menubar/edit/clone': 'Cloner',
			'menubar/edit/delete': 'Supprimer',

			'menubar/add': 'Ajouter',
			'menubar/add/group': 'Groupe',

			'menubar/add/mesh': 'Maille',
			'menubar/add/mesh/plane': 'Plan',
			'menubar/add/mesh/box': 'Cube',
			'menubar/add/mesh/capsule': 'Capsule',
			'menubar/add/mesh/circle': 'Cercle',
			'menubar/add/mesh/cylinder': 'Cylindre',
			'menubar/add/mesh/ring': 'Bague',
			'menubar/add/mesh/sphere': 'Sphère',
			'menubar/add/mesh/dodecahedron': 'Dodécaèdre',
			'menubar/add/mesh/icosahedron': 'Icosaèdre',
			'menubar/add/mesh/octahedron': 'Octaèdre',
			'menubar/add/mesh/tetrahedron': 'Tétraèdre',
			'menubar/add/mesh/torus': 'Torus',
			'menubar/add/mesh/tube': 'Tube',
			'menubar/add/mesh/torusknot': 'Noeud Torus',
			'menubar/add/mesh/lathe': 'Tour',
			'menubar/add/mesh/sprite': 'Sprite',

			'menubar/add/light': 'Lumière',
			'menubar/add/light/ambient': 'Ambiante',
			'menubar/add/light/directional': 'Directionnelle',
			'menubar/add/light/hemisphere': 'Hémisphérique',
			'menubar/add/light/point': 'Ponctuelle',
			'menubar/add/light/spot': 'Projecteur',

			'menubar/add/camera': 'Caméra',
			'menubar/add/camera/perspective': 'Perspective',
			'menubar/add/camera/orthographic': 'Orthographique',

			'menubar/status/autosave': 'enregistrement automatique',

			'menubar/view': 'View',
			'menubar/view/fullscreen': 'Fullscreen',
			'menubar/view/gridHelper': 'Assistant de grille',
			'menubar/view/cameraHelpers': 'Aides à la caméra',
			'menubar/view/lightHelpers': 'Aides Lumière',
			'menubar/view/skeletonHelpers': 'Aides squelettes',

			'menubar/help': 'Aide',
			'menubar/help/source_code': 'Code Source',
			'menubar/help/icons': 'Icon Pack',
			'menubar/help/about': 'A propos',
			'menubar/help/manual': 'Manual',

			'sidebar/animations': 'Animations',
			'sidebar/animations/play': 'Play',
			'sidebar/animations/stop': 'Stop',
			'sidebar/animations/timescale': 'Time Scale',

			'sidebar/scene': 'Scène',
			'sidebar/scene/background': 'Arrière Plan',
			'sidebar/scene/environment': 'Environment',
			'sidebar/scene/fog': 'Brouillard',

			'sidebar/properties/object': 'Objet',
			'sidebar/properties/geometry': 'Géométrie',
			'sidebar/properties/material': 'Matériaux',
			'sidebar/properties/script': 'Script',

			'sidebar/object/type': 'Type',
			'sidebar/object/new': 'Nouveau',
			'sidebar/object/uuid': 'UUID',
			'sidebar/object/name': 'Nom',
			'sidebar/object/position': 'Position',
			'sidebar/object/rotation': 'Rotation',
			'sidebar/object/scale': 'Échelle',
			'sidebar/object/fov': 'Champ de vision',
			'sidebar/object/left': 'Gauche',
			'sidebar/object/right': 'Droite',
			'sidebar/object/top': 'Haut',
			'sidebar/object/bottom': 'Bas',
			'sidebar/object/near': 'Près',
			'sidebar/object/far': 'Loin',
			'sidebar/object/intensity': 'Intensité',
			'sidebar/object/color': 'Couleur',
			'sidebar/object/groundcolor': 'Couleur de fond',
			'sidebar/object/distance': 'Distance',
			'sidebar/object/angle': 'Angle',
			'sidebar/object/penumbra': 'Pénombre',
			'sidebar/object/decay': 'Affaiblissement',
			'sidebar/object/shadow': 'Ombre',
			'sidebar/object/shadowIntensity': 'Shadow Intensity',
			'sidebar/object/shadowBias': 'Biais directionnel des ombres',
			'sidebar/object/shadowNormalBias': 'Shadow Normal Bias',
			'sidebar/object/shadowRadius': 'Rayon de l\'ombre',
			'sidebar/object/cast': 'Projète',
			'sidebar/object/receive': 'Reçoit',
			'sidebar/object/visible': 'Visible',
			'sidebar/object/frustumcull': 'Culling',
			'sidebar/object/renderorder': 'Ordre de rendus',
			'sidebar/object/userdata': 'Données utilisateur',
			'sidebar/object/export': 'Exporter JSON',

			'sidebar/geometry/type': 'Type',
			'sidebar/geometry/new': 'Nouveau',
			'sidebar/geometry/uuid': 'UUID',
			'sidebar/geometry/name': 'Nom',
			'sidebar/geometry/bounds': 'Limites',
			'sidebar/geometry/userdata': 'Données utilisateur',
			'sidebar/geometry/show_vertex_normals': 'Afficher normales',
			'sidebar/geometry/compute_vertex_normals': 'Compute Vertex Normals',
			'sidebar/geometry/compute_vertex_tangents': 'Compute Tangents',
			'sidebar/geometry/center': 'Center',
			'sidebar/geometry/export': 'Exporter JSON',

			'sidebar/geometry/box_geometry/width': 'Largeur',
			'sidebar/geometry/box_geometry/height': 'Hauteur',
			'sidebar/geometry/box_geometry/depth': 'Profondeur',
			'sidebar/geometry/box_geometry/widthseg': 'Segments en Largeur',
			'sidebar/geometry/box_geometry/heightseg': 'Segments en Hauteur',
			'sidebar/geometry/box_geometry/depthseg': 'Segments en Profondeur',

			'sidebar/geometry/buffer_geometry/attributes': 'Attributs',
			'sidebar/geometry/buffer_geometry/index': 'index',
			'sidebar/geometry/buffer_geometry/morphAttributes': 'Morph Attributes',
			'sidebar/geometry/buffer_geometry/morphRelative': 'Morph Relative',

			'sidebar/geometry/capsule_geometry/radius': 'Radius',
			'sidebar/geometry/capsule_geometry/length': 'Length',
			'sidebar/geometry/capsule_geometry/capseg': 'Cap Seg',
			'sidebar/geometry/capsule_geometry/radialseg': 'Radial Seg',

			'sidebar/geometry/circle_geometry/radius': 'Rayon',
			'sidebar/geometry/circle_geometry/segments': 'Segments',
			'sidebar/geometry/circle_geometry/thetastart': 'Début Thêta (°)',
			'sidebar/geometry/circle_geometry/thetalength': 'Longueur Thêta (°)',

			'sidebar/geometry/cylinder_geometry/radiustop': 'Rayon supérieur',
			'sidebar/geometry/cylinder_geometry/radiusbottom': 'Rayon inférieur',
			'sidebar/geometry/cylinder_geometry/height': 'Hauteur',
			'sidebar/geometry/cylinder_geometry/radialsegments': 'Segments radiaux',
			'sidebar/geometry/cylinder_geometry/heightsegments': 'Segments en hauteur',
			'sidebar/geometry/cylinder_geometry/openended': 'Extrémités ouvertes',

			'sidebar/geometry/extrude_geometry/curveSegments': 'Segments de courbe',
			'sidebar/geometry/extrude_geometry/steps': 'Pas',
			'sidebar/geometry/extrude_geometry/depth': 'Profondeur',
			'sidebar/geometry/extrude_geometry/bevelEnabled': 'Biseau',
			'sidebar/geometry/extrude_geometry/bevelThickness': 'Épaisseur',
			'sidebar/geometry/extrude_geometry/bevelSize': 'Taille',
			'sidebar/geometry/extrude_geometry/bevelOffset': 'Décalage',
			'sidebar/geometry/extrude_geometry/bevelSegments': 'Segments',
			'sidebar/geometry/extrude_geometry/shape': 'Convertir en forme',

			'sidebar/geometry/dodecahedron_geometry/radius': 'Rayon',
			'sidebar/geometry/dodecahedron_geometry/detail': 'Détail',

			'sidebar/geometry/icosahedron_geometry/radius': 'Rayon',
			'sidebar/geometry/icosahedron_geometry/detail': 'Détail',

			'sidebar/geometry/octahedron_geometry/radius': 'Rayon',
			'sidebar/geometry/octahedron_geometry/detail': 'Détail',

			'sidebar/geometry/tetrahedron_geometry/radius': 'Rayon',
			'sidebar/geometry/tetrahedron_geometry/detail': 'Détail',

			'sidebar/geometry/lathe_geometry/segments': 'Segments',
			'sidebar/geometry/lathe_geometry/phistart': 'Début Phi (°)',
			'sidebar/geometry/lathe_geometry/philength': 'Longueur Phi (°)',
			'sidebar/geometry/lathe_geometry/points': 'Points',

			'sidebar/geometry/plane_geometry/width': 'Largeur',
			'sidebar/geometry/plane_geometry/height': 'Hauteur',
			'sidebar/geometry/plane_geometry/widthsegments': 'Segments en Largeur',
			'sidebar/geometry/plane_geometry/heightsegments': 'Segments en Hauteur',

			'sidebar/geometry/ring_geometry/innerRadius': 'Rayon intérieur',
			'sidebar/geometry/ring_geometry/outerRadius': 'Rayon extérieur',
			'sidebar/geometry/ring_geometry/thetaSegments': 'Segments Thêta',
			'sidebar/geometry/ring_geometry/phiSegments': 'Phi segments',
			'sidebar/geometry/ring_geometry/thetastart': 'Début Thêta',
			'sidebar/geometry/ring_geometry/thetalength': 'Longueur Thêta',

			'sidebar/geometry/shape_geometry/curveSegments': 'Segments de courbe',
			'sidebar/geometry/shape_geometry/extrude': 'Extruder',

			'sidebar/geometry/sphere_geometry/radius': 'Rayon',
			'sidebar/geometry/sphere_geometry/widthsegments': 'Segments en Largeur',
			'sidebar/geometry/sphere_geometry/heightsegments': 'Segments en Hauteur',
			'sidebar/geometry/sphere_geometry/phistart': 'Début Phi (°)',
			'sidebar/geometry/sphere_geometry/philength': 'Longueur Phi (°)',
			'sidebar/geometry/sphere_geometry/thetastart': 'Début Thêta',
			'sidebar/geometry/sphere_geometry/thetalength': 'Longueur Thêta',

			'sidebar/geometry/torus_geometry/radius': 'Rayon',
			'sidebar/geometry/torus_geometry/tube': 'Tube',
			'sidebar/geometry/torus_geometry/radialsegments': 'Segments radiaux',
			'sidebar/geometry/torus_geometry/tubularsegments': 'Segments tubulaires',
			'sidebar/geometry/torus_geometry/arc': 'Arc',

			'sidebar/geometry/torusKnot_geometry/radius': 'Rayon',
			'sidebar/geometry/torusKnot_geometry/tube': 'Tube',
			'sidebar/geometry/torusKnot_geometry/tubularsegments': 'Segments tubulaires',
			'sidebar/geometry/torusKnot_geometry/radialsegments': 'Segments radiaux',
			'sidebar/geometry/torusKnot_geometry/p': 'P',
			'sidebar/geometry/torusKnot_geometry/q': 'Q',

			'sidebar/geometry/tube_geometry/path': 'Chemin',
			'sidebar/geometry/tube_geometry/radius': 'Rayon',
			'sidebar/geometry/tube_geometry/tube': 'Tube',
			'sidebar/geometry/tube_geometry/tubularsegments': 'Segments tubulaires',
			'sidebar/geometry/tube_geometry/radialsegments': 'Segments radiaux',
			'sidebar/geometry/tube_geometry/closed': 'Fermé',
			'sidebar/geometry/tube_geometry/curvetype': 'Type de courbe',
			'sidebar/geometry/tube_geometry/tension': 'Tension',

			'sidebar/material/new': 'Nouveau',
			'sidebar/material/copy': 'Copier',
			'sidebar/material/paste': 'Coller',
			'sidebar/material/slot': 'Slot',
			'sidebar/material/type': 'Type',
			'sidebar/material/uuid': 'UUID',
			'sidebar/material/name': 'Nom',
			'sidebar/material/program': 'Programme',
			'sidebar/material/info': 'Info',
			'sidebar/material/vertex': 'Sommet',
			'sidebar/material/fragment': 'Fragment',
			'sidebar/material/color': 'Couleur',
			'sidebar/material/depthPacking': 'Encodage profondeur de couleur',
			'sidebar/material/roughness': 'Rugosité',
			'sidebar/material/metalness': 'Métal',
			'sidebar/material/reflectivity': 'Reflectivity',
			'sidebar/material/emissive': 'Émissif',
			'sidebar/material/specular': 'Spéculaire',
			'sidebar/material/shininess': 'Brillance',
			'sidebar/material/clearcoat': 'Vernis',
			'sidebar/material/clearcoatroughness': 'Rugosité du vernis',
			'sidebar/material/dispersion': 'Dispersion',
			'sidebar/material/ior': 'IOR',
			'sidebar/material/iridescence': 'Iridescence',
			'sidebar/material/iridescenceIOR': 'Thin-Film IOR',
			'sidebar/material/iridescenceThicknessMax': 'Thin-Film Thickness',
			'sidebar/material/sheen': 'Sheen',
			'sidebar/material/sheenroughness': 'Sheen Roughness',
			'sidebar/material/sheencolor': 'Sheen Color',
			'sidebar/material/transmission': 'Transmission',
			'sidebar/material/attenuationDistance': 'Attenuation Distance',
			'sidebar/material/attenuationColor': 'Attenuation Color',
			'sidebar/material/thickness': 'Thickness',
			'sidebar/material/vertexcolors': 'Couleurs aux Sommets',
			'sidebar/material/matcap': 'Matcap',
			'sidebar/material/map': 'Texture',
			'sidebar/material/alphamap': 'Texture de transparence',
			'sidebar/material/bumpmap': 'Texture de relief',
			'sidebar/material/normalmap': 'Texture de normales',
			'sidebar/material/clearcoatmap': 'Clearcoat Map',
			'sidebar/material/clearcoatnormalmap': 'Texture des normales du vernis',
			'sidebar/material/clearcoatroughnessmap': 'Clearcoat Roughness Map',
			'sidebar/material/displacementmap': 'Texture de déplacement',
			'sidebar/material/roughnessmap': 'Texture de rugosité',
			'sidebar/material/metalnessmap': 'Texture métallique',
			'sidebar/material/specularmap': 'Texture spéculaire',
			'sidebar/material/iridescencemap': 'Irid. Map',
			'sidebar/material/iridescencethicknessmap': 'Thin-Film Thickness Map',
			'sidebar/material/sheencolormap': 'Sheen Color Map',
			'sidebar/material/sheenroughnessmap': 'Sheen Rough. Map',
			'sidebar/material/envmap': 'Texture d\'environnement',
			'sidebar/material/lightmap': 'Texture d\'éclairage',
			'sidebar/material/aomap': 'Texture d\'occlusion ambiante',
			'sidebar/material/emissivemap': 'Texture d\'émission',
			'sidebar/material/gradientmap': 'Texture de gradient',
			'sidebar/material/transmissionmap': 'Transmission Map',
			'sidebar/material/thicknessmap': 'Thickness Map',
			'sidebar/material/side': 'Côté',
			'sidebar/material/size': 'Size',
			'sidebar/material/sizeAttenuation': 'Size Attenuation',
			'sidebar/material/flatShading': 'Flat Shading',
			'sidebar/material/blending': 'Mélange',
			'sidebar/material/opacity': 'Opacité',
			'sidebar/material/transparent': 'Transparence',
			'sidebar/material/forcesinglepass': 'Force Single Pass',
			'sidebar/material/alphatest': 'Test de transparence',
			'sidebar/material/depthtest': 'Depth Test',
			'sidebar/material/depthwrite': 'Depth Write',
			'sidebar/material/wireframe': 'Fil de fer',
			'sidebar/material/userdata': 'Données utilisateur',
			'sidebar/material/export': 'Exporter JSON',

			'sidebar/script/new': 'Nouveau',
			'sidebar/script/edit': 'Editer',
			'sidebar/script/remove': 'Supprimer',

			'sidebar/project': 'Projet',
			'sidebar/project/antialias': 'Anticrénelage',
			'sidebar/project/shadows': 'Ombres',
			'sidebar/project/toneMapping': 'Mappage des nuances',
			'sidebar/project/materials': 'Matériaux',
			'sidebar/project/Assign': 'Attribuer',

			'sidebar/project/app': 'App',
			'sidebar/project/app/play': 'Jouer',
			'sidebar/project/app/stop': 'Arrêter',
			'sidebar/project/app/title': 'Titre',
			'sidebar/project/app/editable': 'Modifiable',
			'sidebar/project/app/publish': 'Publier',

			'sidebar/project/image': 'Image',
			'sidebar/project/image/samples': 'd\'échantillons',
			'sidebar/project/video': 'Video',

			'sidebar/project/shading': 'Shading',
			'sidebar/project/resolution': 'Resolution',
			'sidebar/project/duration': 'Duration',
			'sidebar/project/render': 'Render',

			'sidebar/settings': 'Paramètres',
			'sidebar/settings/language': 'Langue',

			'sidebar/settings/shortcuts': 'Shortcuts',
			'sidebar/settings/shortcuts/translate': 'Position',
			'sidebar/settings/shortcuts/rotate': 'Rotation',
			'sidebar/settings/shortcuts/scale': 'Échelle',
			'sidebar/settings/shortcuts/undo': 'Annuler',
			'sidebar/settings/shortcuts/focus': 'Focus',

			'sidebar/history': 'Historique',
			'sidebar/history/clear': 'Supprimer',
			'sidebar/history/persistent': 'Permanent',

			'toolbar/translate': 'Position',
			'toolbar/rotate': 'Rotation',
			'toolbar/scale': 'Échelle',
			'toolbar/local': 'Local',

			'viewport/controls/grid': 'Grille',
			'viewport/controls/helpers': 'Helpers',

			'viewport/info/object': 'Objet',
			'viewport/info/objects': 'Objets',
			'viewport/info/vertex': 'Sommet',
			'viewport/info/vertices': 'Sommets',
			'viewport/info/triangle': 'Triangle',
			'viewport/info/triangles': 'Triangles',
			'viewport/info/sample': 'Échantillon',
			'viewport/info/samples': 'Échantillons',
			'viewport/info/rendertime': 'Temps de rendu',

			'script/title/vertexShader': 'Vertex Shader',
			'script/title/fragmentShader': 'Fragment Shader',
			'script/title/programInfo': 'Propriétés du programme'

		},

		zh: {

			'prompt/file/open': '您确定吗？未保存的数据将会丢失。',
			'prompt/file/failedToOpenProject': '无法打开项目！',
			'prompt/file/export/noMeshSelected': '未选择网格！',
			'prompt/file/export/noObjectSelected': '未选择对象！',
			'prompt/script/remove': '你确定吗？',
			'prompt/history/clear': '撤销/重做历史记录将被清除。您确定吗？',
			'prompt/history/preserve': '历史将在会话之间保留。\n这可能会影响在处理纹理时的性能。',
			'prompt/history/forbid': '在播放场景时，撤消/重做被禁用。',
			'prompt/rendering/realistic/unsupportedMaterial': 'REALISTIC着色：仅支持 MeshStandardMaterial 和 MeshPhysicalMaterial',

			'command/AddObject': '添加对象',
			'command/AddScript': '添加脚本',
			'command/MoveObject': '移动对象',
			'command/MultiCmds': '多次更改',
			'command/RemoveObject': '删除对象',
			'command/RemoveScript': '删除脚本',
			'command/SetColor': '设置颜色',
			'command/SetGeometry': '设置几何图形',
			'command/SetGeometryValue': '设置几何值',
			'command/SetMaterialColor': '设置材质颜色',
			'command/SetMaterial': '设置材质',
			'command/SetMaterialMap': '设置材质贴图',
			'command/SetMaterialRange': '设置材料范围',
			'command/SetMaterialValue': '设置材料值',
			'command/SetMaterialVector': '设置材质矢量',
			'command/SetPosition': '设置位置',
			'command/SetRotation': '设置旋转',
			'command/SetScale': '设置比例',
			'command/SetScene': '设置布景',
			'command/SetScriptValue': '设置脚本值',
			'command/SetShadowValue': 'Set Shadow Value',
			'command/SetUuid': '设置 UUID',
			'command/SetValue': '设定值',

			'menubar/file': '文件',
			'menubar/file/new': '新建项目',
			'menubar/file/new/empty': '空',
			'menubar/file/new/Arkanoid': '打砖块',
			'menubar/file/new/Camera': ' 摄像机',
			'menubar/file/new/Particles': '粒子',
			'menubar/file/new/Pong': '乒乓球',
			'menubar/file/new/Shaders': '着色器',
			'menubar/file/open': '打开',
			'menubar/file/save': '保存',
			'menubar/file/import': '导入',
			'menubar/file/export': '导出',

			'menubar/edit': '编辑',
			'menubar/edit/undo': '撤销',
			'menubar/edit/redo': '重做',
			'menubar/edit/center': '居中',
			'menubar/edit/clone': '拷贝',
			'menubar/edit/delete': '删除',

			'menubar/add': '添加',
			'menubar/add/group': '组',

			'menubar/add/mesh': '网格',
			'menubar/add/mesh/plane': '平面',
			'menubar/add/mesh/box': '正方体',
			'menubar/add/mesh/capsule': '胶囊',
			'menubar/add/mesh/circle': '圆',
			'menubar/add/mesh/cylinder': '圆柱体',
			'menubar/add/mesh/ring': '环',
			'menubar/add/mesh/sphere': '球体',
			'menubar/add/mesh/dodecahedron': '十二面体',
			'menubar/add/mesh/icosahedron': '二十面体',
			'menubar/add/mesh/octahedron': '八面体',
			'menubar/add/mesh/tetrahedron': '四面体',
			'menubar/add/mesh/torus': '圆环体',
			'menubar/add/mesh/torusknot': '环面纽结体',
			'menubar/add/mesh/tube': '管',
			'menubar/add/mesh/lathe': '酒杯',
			'menubar/add/mesh/sprite': '精灵',

			'menubar/add/light': '光源',
			'menubar/add/light/ambient': '环境光',
			'menubar/add/light/directional': '平行光',
			'menubar/add/light/hemisphere': '半球光',
			'menubar/add/light/point': '点光源',
			'menubar/add/light/spot': '聚光灯',

			'menubar/add/camera': '摄像机',
			'menubar/add/camera/perspective': '透视相机',
			'menubar/add/camera/orthographic': '正交相机',

			'menubar/status/autosave': '自动保存',

			'menubar/view': '视图',
			'menubar/view/fullscreen': '全屏',
			'menubar/view/gridHelper': '网格助手',
			'menubar/view/cameraHelpers': '相机助手',
			'menubar/view/lightHelpers': '光助手',
			'menubar/view/skeletonHelpers': '骷髅助手',

			'menubar/help': '帮助',
			'menubar/help/source_code': '源码',
			'menubar/help/icons': '图标组件包',
			'menubar/help/about': '关于',
			'menubar/help/manual': '手册',

			'sidebar/animations': '动画',
			'sidebar/animations/play': '播放',
			'sidebar/animations/stop': '暂停',
			'sidebar/animations/timescale': '时间缩放',

			'sidebar/scene': '场景',
			'sidebar/scene/background': '背景',
			'sidebar/scene/environment': '环境',
			'sidebar/scene/fog': '雾',

			'sidebar/properties/object': '属性',
			'sidebar/properties/geometry': '几何组件',
			'sidebar/properties/material': '材质组件',
			'sidebar/properties/script': '脚本',

			'sidebar/object/type': '类型',
			'sidebar/object/new': '更新',
			'sidebar/object/uuid': '识别码',
			'sidebar/object/name': '名称',
			'sidebar/object/position': '位置',
			'sidebar/object/rotation': '旋转',
			'sidebar/object/scale': '缩放',
			'sidebar/object/fov': '视角',
			'sidebar/object/left': '左',
			'sidebar/object/right': '右',
			'sidebar/object/top': '上',
			'sidebar/object/bottom': '下',
			'sidebar/object/near': '近点',
			'sidebar/object/far': '远点',
			'sidebar/object/intensity': '强度',
			'sidebar/object/color': '颜色',
			'sidebar/object/groundcolor': '基色',
			'sidebar/object/distance': '距离',
			'sidebar/object/angle': '角度',
			'sidebar/object/penumbra': '边缘',
			'sidebar/object/decay': '衰减',
			'sidebar/object/shadow': '阴影',
			'sidebar/object/shadowIntensity': 'Shadow Intensity',
			'sidebar/object/shadowBias': '阴影偏移',
			'sidebar/object/shadowNormalBias': '阴影法线偏移',
			'sidebar/object/shadowRadius': '阴影半径',
			'sidebar/object/cast': '产生',
			'sidebar/object/receive': '接受',
			'sidebar/object/visible': '可见性',
			'sidebar/object/frustumcull': '视锥体裁剪',
			'sidebar/object/renderorder': '渲染次序',
			'sidebar/object/userdata': '自定义数据',
			'sidebar/object/export': '导出JSON',

			'sidebar/geometry/type': '类型',
			'sidebar/geometry/new': '更新',
			'sidebar/geometry/uuid': '识别码',
			'sidebar/geometry/name': '名称',
			'sidebar/geometry/bounds': '界限',
			'sidebar/geometry/userdata': '自定义数据',
			'sidebar/geometry/show_vertex_normals': '显示顶点法线',
			'sidebar/geometry/compute_vertex_normals': '计算顶点法线',
			'sidebar/geometry/compute_vertex_tangents': 'Compute Tangents',
			'sidebar/geometry/center': '居中',
			'sidebar/geometry/export': '导出JSON',

			'sidebar/geometry/box_geometry/width': '宽度',
			'sidebar/geometry/box_geometry/height': '高度',
			'sidebar/geometry/box_geometry/depth': '深度',
			'sidebar/geometry/box_geometry/widthseg': '宽度分段',
			'sidebar/geometry/box_geometry/heightseg': '高度分段',
			'sidebar/geometry/box_geometry/depthseg': '深度分段',

			'sidebar/geometry/buffer_geometry/attributes': '属性',
			'sidebar/geometry/buffer_geometry/index': '索引',
			'sidebar/geometry/buffer_geometry/morphAttributes': 'Morph Attributes',
			'sidebar/geometry/buffer_geometry/morphRelative': 'Morph Relative',

			'sidebar/geometry/capsule_geometry/radius': '半径',
			'sidebar/geometry/capsule_geometry/length': '长度',
			'sidebar/geometry/capsule_geometry/capseg': '胶囊分段',
			'sidebar/geometry/capsule_geometry/radialseg': '半径分段',

			'sidebar/geometry/circle_geometry/radius': '半径',
			'sidebar/geometry/circle_geometry/segments': '分段',
			'sidebar/geometry/circle_geometry/thetastart': '弧度起点',
			'sidebar/geometry/circle_geometry/thetalength': '弧度长度',

			'sidebar/geometry/cylinder_geometry/radiustop': '顶部半径',
			'sidebar/geometry/cylinder_geometry/radiusbottom': '底部半径',
			'sidebar/geometry/cylinder_geometry/height': '高度',
			'sidebar/geometry/cylinder_geometry/radialsegments': '径向分段',
			'sidebar/geometry/cylinder_geometry/heightsegments': '高度分段',
			'sidebar/geometry/cylinder_geometry/openended': '开端',

			'sidebar/geometry/extrude_geometry/curveSegments': '曲线段',
			'sidebar/geometry/extrude_geometry/steps': '细分点数',
			'sidebar/geometry/extrude_geometry/depth': '深度',
			'sidebar/geometry/extrude_geometry/bevelEnabled': '启用斜角',
			'sidebar/geometry/extrude_geometry/bevelThickness': '斜角厚度',
			'sidebar/geometry/extrude_geometry/bevelSize': '斜角大小',
			'sidebar/geometry/extrude_geometry/bevelOffset': '斜角偏移量',
			'sidebar/geometry/extrude_geometry/bevelSegments': '斜角分段',
			'sidebar/geometry/extrude_geometry/shape': '转换图形',

			'sidebar/geometry/dodecahedron_geometry/radius': '半径',
			'sidebar/geometry/dodecahedron_geometry/detail': '面片分段',

			'sidebar/geometry/icosahedron_geometry/radius': '半径',
			'sidebar/geometry/icosahedron_geometry/detail': '面片分段',

			'sidebar/geometry/octahedron_geometry/radius': '半径',
			'sidebar/geometry/octahedron_geometry/detail': '面片分段',

			'sidebar/geometry/tetrahedron_geometry/radius': '半径',
			'sidebar/geometry/tetrahedron_geometry/detail': '面片分段',

			'sidebar/geometry/lathe_geometry/segments': '分段',
			'sidebar/geometry/lathe_geometry/phistart': '经度起点',
			'sidebar/geometry/lathe_geometry/philength': '经度长度',
			'sidebar/geometry/lathe_geometry/points': '点',

			'sidebar/geometry/plane_geometry/width': '宽度',
			'sidebar/geometry/plane_geometry/height': '长度',
			'sidebar/geometry/plane_geometry/widthsegments': '宽度分段',
			'sidebar/geometry/plane_geometry/heightsegments': '长度分段',

			'sidebar/geometry/ring_geometry/innerRadius': '内半径',
			'sidebar/geometry/ring_geometry/outerRadius': '外半径',
			'sidebar/geometry/ring_geometry/thetaSegments': '弧度分段',
			'sidebar/geometry/ring_geometry/phiSegments': '经度分段',
			'sidebar/geometry/ring_geometry/thetastart': '弧度起点',
			'sidebar/geometry/ring_geometry/thetalength': '弧度长度',

			'sidebar/geometry/shape_geometry/curveSegments': '曲线段',
			'sidebar/geometry/shape_geometry/extrude': '拉伸',

			'sidebar/geometry/sphere_geometry/radius': '半径',
			'sidebar/geometry/sphere_geometry/widthsegments': '宽度分段',
			'sidebar/geometry/sphere_geometry/heightsegments': '长度分段',
			'sidebar/geometry/sphere_geometry/phistart': '经度起点',
			'sidebar/geometry/sphere_geometry/philength': '经度长度',
			'sidebar/geometry/sphere_geometry/thetastart': '纬度起点',
			'sidebar/geometry/sphere_geometry/thetalength': '纬度长度',

			'sidebar/geometry/torus_geometry/radius': '半径',
			'sidebar/geometry/torus_geometry/tube': '管厚',
			'sidebar/geometry/torus_geometry/radialsegments': '半径分段',
			'sidebar/geometry/torus_geometry/tubularsegments': '管厚分段',
			'sidebar/geometry/torus_geometry/arc': '弧度',

			'sidebar/geometry/torusKnot_geometry/radius': '半径',
			'sidebar/geometry/torusKnot_geometry/tube': '管厚',
			'sidebar/geometry/torusKnot_geometry/tubularsegments': '管厚分段',
			'sidebar/geometry/torusKnot_geometry/radialsegments': '半径分段',
			'sidebar/geometry/torusKnot_geometry/p': '管长弧度',
			'sidebar/geometry/torusKnot_geometry/q': '扭曲弧度',

			'sidebar/geometry/tube_geometry/path': '路径',
			'sidebar/geometry/tube_geometry/radius': '半径',
			'sidebar/geometry/tube_geometry/tube': '管厚',
			'sidebar/geometry/tube_geometry/tubularsegments': '管厚分段',
			'sidebar/geometry/tube_geometry/radialsegments': '半径分段',
			'sidebar/geometry/tube_geometry/closed': '闭合',
			'sidebar/geometry/tube_geometry/curvetype': '曲线类型',
			'sidebar/geometry/tube_geometry/tension': '张力',

			'sidebar/material/new': '更新',
			'sidebar/material/copy': '复制',
			'sidebar/material/paste': '粘贴',
			'sidebar/material/slot': '插槽',
			'sidebar/material/type': '类型',
			'sidebar/material/uuid': '识别码',
			'sidebar/material/name': '名称',
			'sidebar/material/program': '程序',
			'sidebar/material/info': '信息',
			'sidebar/material/vertex': '顶点',
			'sidebar/material/fragment': '片元',
			'sidebar/material/color': '颜色',
			'sidebar/material/depthPacking': '深度包装',
			'sidebar/material/roughness': '粗糙度',
			'sidebar/material/metalness': '金属度',
			'sidebar/material/reflectivity': '反射率',
			'sidebar/material/emissive': '自发光',
			'sidebar/material/specular': '高光',
			'sidebar/material/shininess': '高光大小',
			'sidebar/material/clearcoat': '清漆',
			'sidebar/material/clearcoatroughness': '清漆粗糙度',
			'sidebar/material/dispersion': 'Dispersion',
			'sidebar/material/ior': 'IOR',
			'sidebar/material/iridescence': '彩虹色',
			'sidebar/material/iridescenceIOR': '彩虹色折射率',
			'sidebar/material/iridescenceThicknessMax': '彩虹色厚度',
			'sidebar/material/sheen': 'Sheen',
			'sidebar/material/sheenroughness': 'Sheen Roughness',
			'sidebar/material/sheencolor': 'Sheen Color',
			'sidebar/material/transmission': '透光',
			'sidebar/material/attenuationDistance': '衰减距离',
			'sidebar/material/attenuationColor': '衰减色',
			'sidebar/material/thickness': '厚度',
			'sidebar/material/vertexcolors': '顶点颜色',
			'sidebar/material/matcap': '材质捕获',
			'sidebar/material/map': '贴图',
			'sidebar/material/alphamap': '透明贴图',
			'sidebar/material/bumpmap': '凹凸贴图',
			'sidebar/material/normalmap': '法线贴图',
			'sidebar/material/clearcoatmap': 'Clearcoat Map',
			'sidebar/material/clearcoatnormalmap': '清漆法线贴图',
			'sidebar/material/clearcoatroughnessmap': 'Clearcoat Roughness Map',
			'sidebar/material/displacementmap': '置换贴图',
			'sidebar/material/roughnessmap': '粗糙贴图',
			'sidebar/material/metalnessmap': '金属贴图',
			'sidebar/material/specularmap': '高光贴图',
			'sidebar/material/iridescencemap': '彩虹色贴图',
			'sidebar/material/iridescencethicknessmap': '彩虹色厚度贴图',
			'sidebar/material/sheencolormap': 'Sheen Color Map',
			'sidebar/material/sheenroughnessmap': 'Sheen Rough. Map',
			'sidebar/material/envmap': '环境贴图',
			'sidebar/material/lightmap': '光照贴图',
			'sidebar/material/aomap': '环境光遮蔽贴图',
			'sidebar/material/emissivemap': '自发光贴图',
			'sidebar/material/gradientmap': '渐变贴图',
			'sidebar/material/transmissionmap': '透光贴图',
			'sidebar/material/thicknessmap': '厚度贴图',
			'sidebar/material/side': '面',
			'sidebar/material/size': '大小',
			'sidebar/material/sizeAttenuation': '大小衰减',
			'sidebar/material/flatShading': '平面着色',
			'sidebar/material/blending': '混合',
			'sidebar/material/opacity': '透明度',
			'sidebar/material/transparent': '透明性',
			'sidebar/material/forcesinglepass': 'Force Single Pass',
			'sidebar/material/alphatest': 'α测试',
			'sidebar/material/depthtest': '深度测试',
			'sidebar/material/depthwrite': '深度缓冲',
			'sidebar/material/wireframe': '线框',
			'sidebar/material/userdata': '自定义数据',
			'sidebar/material/export': '导出JSON',

			'sidebar/script/new': '新建',
			'sidebar/script/edit': '编辑',
			'sidebar/script/remove': '删除',

			'sidebar/project': '项目',
			'sidebar/project/antialias': '抗锯齿',
			'sidebar/project/shadows': '阴影',
			'sidebar/project/toneMapping': '色调映射',
			'sidebar/project/materials': '材质',
			'sidebar/project/Assign': '应用',

			'sidebar/project/app': 'App',
			'sidebar/project/app/play': '启动',
			'sidebar/project/app/stop': '暂停',
			'sidebar/project/app/title': '标题',
			'sidebar/project/app/editable': '编辑性',
			'sidebar/project/app/publish': '发布',

			'sidebar/project/image': 'Image',
			'sidebar/project/image/samples': '样本',
			'sidebar/project/video': '视频',

			'sidebar/project/shading': 'Shading',
			'sidebar/project/resolution': '分辨率',
			'sidebar/project/duration': '时长',
			'sidebar/project/render': '渲染',

			'sidebar/settings': '设置',
			'sidebar/settings/language': '语言',

			'sidebar/settings/shortcuts': '快捷键',
			'sidebar/settings/shortcuts/translate': '移动',
			'sidebar/settings/shortcuts/rotate': '旋转',
			'sidebar/settings/shortcuts/scale': '缩放',
			'sidebar/settings/shortcuts/undo': '撤销',
			'sidebar/settings/shortcuts/focus': '聚焦',

			'sidebar/history': '历史记录',
			'sidebar/history/clear': '清空',
			'sidebar/history/persistent': '本地存储',

			'toolbar/translate': '移动',
			'toolbar/rotate': '旋转',
			'toolbar/scale': '缩放',
			'toolbar/local': '本地',

			'viewport/controls/grid': '网格',
			'viewport/controls/helpers': '辅助',

			'viewport/info/object': '物体',
			'viewport/info/objects': '物体',
			'viewport/info/vertex': '顶点',
			'viewport/info/vertices': '顶点',
			'viewport/info/triangle': '三角形',
			'viewport/info/triangles': '三角形',
			'viewport/info/sample': '样本',
			'viewport/info/samples': '样本',
			'viewport/info/rendertime': '渲染时间',

			'script/title/vertexShader': '顶点着色器',
			'script/title/fragmentShader': '片段着色器',
			'script/title/programInfo': '程序属性'

		},

		ja: {

			'prompt/file/open': '保存されていないデータは失われます。 本気ですか？',
			'prompt/file/failedToOpenProject': 'プロジェクトを開くことができませんでした!',
			'prompt/file/export/noMeshSelected': 'メッシュが選択されていません!',
			'prompt/file/export/noObjectSelected': 'オブジェクトが選択されていません!',
			'prompt/script/remove': '本気ですか？',
			'prompt/history/clear': '元に戻す/やり直しの履歴が消去されます。 本気ですか？',
			'prompt/history/preserve': '履歴はセッションをまたいで保存されます。\nこれは、テクスチャを操作する際のパフォーマンスに影響を与える可能性があります。',
			'prompt/history/forbid': 'シーンの再生中は元に戻す/やり直しは無効になります。',
			'prompt/rendering/realistic/unsupportedMaterial': 'REALISTIC シェーディング: MeshStandardmaterial と MeshPhysicalmaterial のみがサポートされています',

			'command/AddObject': 'オブジェクトを追加',
			'command/AddScript': 'スクリプトを追加',
			'command/MoveObject': 'オブジェクトの移動',
			'command/MultiCmds': '複数の変更',
			'command/RemoveObject': 'オブジェクトを削除',
			'command/RemoveScript': 'スクリプトの削除',
			'command/SetColor': 'カラーを設定',
			'command/SetGeometry': 'ジオメトリの設定',
			'command/SetGeometryValue': 'ジオメトリ値の設定',
			'command/SetMaterialColor': 'マテリアル カラーの設定',
			'command/SetMaterial': 'マテリアルの設定',
			'command/SetMaterialMap': 'マテリアル マップの設定',
			'command/SetMaterialRange': 'マテリアル範囲の設定',
			'command/SetMaterialValue': 'マテリアル値の設定',
			'command/SetMaterialVector': '素材のベクトルを設定します',
			'command/SetPosition': '位置を設定',
			'command/SetRotation': '回転を設定',
			'command/SetScale': 'スケールを設定',
			'command/SetScene': 'セットシーン',
			'command/SetScriptValue': 'スクリプト値の設定',
			'command/SetShadowValue': 'Set Shadow Value',
			'command/SetUuid': 'UUIDの設定',
			'command/SetValue': '値の設定',

			'menubar/file': 'ファイル',
			'menubar/file/new': '新規プロジェクト',
			'menubar/file/new/empty': '空',
			'menubar/file/new/Arkanoid': 'ブロック崩し',
			'menubar/file/new/Camera': 'カメラ',
			'menubar/file/new/Particles': 'パーティクル',
			'menubar/file/new/Pong': 'ピンポン',
			'menubar/file/new/Shaders': 'シェーダー',
			'menubar/file/open': '開く',
			'menubar/file/save': '保存',
			'menubar/file/import': 'インポート',
			'menubar/file/export': 'エクスポート',

			'menubar/edit': '編集',
			'menubar/edit/undo': '元に戻す',
			'menubar/edit/redo': 'やり直す',
			'menubar/edit/center': '中央揃え',
			'menubar/edit/clone': '複製',
			'menubar/edit/delete': '削除',

			'menubar/add': '追加',
			'menubar/add/group': 'グループ',

			'menubar/add/mesh': 'メッシュ',
			'menubar/add/mesh/plane': '平面',
			'menubar/add/mesh/box': '直方体',
			'menubar/add/mesh/capsule': 'カプセル',
			'menubar/add/mesh/circle': '円',
			'menubar/add/mesh/cylinder': '円柱',
			'menubar/add/mesh/ring': 'リング',
			'menubar/add/mesh/sphere': '球',
			'menubar/add/mesh/dodecahedron': '十二面体',
			'menubar/add/mesh/icosahedron': '二十面体',
			'menubar/add/mesh/octahedron': '八面体',
			'menubar/add/mesh/tetrahedron': '四面体',
			'menubar/add/mesh/torus': 'トーラス',
			'menubar/add/mesh/tube': 'チューブ',
			'menubar/add/mesh/torusknot': 'ノットトーラス',
			'menubar/add/mesh/lathe': '旋盤形',
			'menubar/add/mesh/sprite': 'スプライト',

			'menubar/add/light': 'ライト',
			'menubar/add/light/ambient': 'アンビエント',
			'menubar/add/light/directional': 'ディレクショナル',
			'menubar/add/light/hemisphere': 'ヘミスフィア',
			'menubar/add/light/point': 'ポイント',
			'menubar/add/light/spot': 'スポット',

			'menubar/add/camera': 'カメラ',
			'menubar/add/camera/perspective': '透視投影',
			'menubar/add/camera/orthographic': '平行投影',

			'menubar/status/autosave': '自動保存',

			'menubar/view': '表示',
			'menubar/view/fullscreen': 'フルスクリーン',
			'menubar/view/gridHelper': 'グリッドヘルパー',
			'menubar/view/cameraHelpers': 'カメラヘルパー',
			'menubar/view/lightHelpers': 'ライトヘルパー',
			'menubar/view/skeletonHelpers': 'スケルトンヘルパー',

			'menubar/help': 'ヘルプ',
			'menubar/help/source_code': 'ソースコード',
			'menubar/help/icons': 'アイコンパック',
			'menubar/help/about': 'Three.js について',
			'menubar/help/manual': 'マニュアル',

			'sidebar/animations': 'アニメーション',
			'sidebar/animations/play': '再生',
			'sidebar/animations/stop': '停止',
			'sidebar/animations/timescale': 'タイムスケール',

			'sidebar/scene': 'シーン',
			'sidebar/scene/background': '背景',
			'sidebar/scene/environment': '環境',
			'sidebar/scene/fog': '霧',

			'sidebar/properties/object': 'オブジェクト',
			'sidebar/properties/geometry': 'ジオメトリ',
			'sidebar/properties/material': 'マテリアル',
			'sidebar/properties/script': 'スクリプト',

			'sidebar/object/type': 'タイプ',
			'sidebar/object/new': '新規',
			'sidebar/object/uuid': 'UUID',
			'sidebar/object/name': '名前',
			'sidebar/object/position': '位置',
			'sidebar/object/rotation': '回転',
			'sidebar/object/scale': 'スケール',
			'sidebar/object/fov': 'Fov',
			'sidebar/object/left': '左',
			'sidebar/object/right': '右',
			'sidebar/object/top': '上',
			'sidebar/object/bottom': '下',
			'sidebar/object/near': '範囲の開始',
			'sidebar/object/far': '範囲の終了',
			'sidebar/object/intensity': '強度',
			'sidebar/object/color': '色',
			'sidebar/object/groundcolor': '地面の色',
			'sidebar/object/distance': '距離',
			'sidebar/object/angle': '角度',
			'sidebar/object/penumbra': '半影',
			'sidebar/object/decay': '減衰',
			'sidebar/object/shadow': '影',
			'sidebar/object/shadowIntensity': 'Shadow Intensity',
			'sidebar/object/shadowBias': '影のバイアス',
			'sidebar/object/shadowNormalBias': '影のノーマルバイアス',
			'sidebar/object/shadowRadius': '影の半径',
			'sidebar/object/cast': 'キャスト',
			'sidebar/object/receive': 'レシーブ',
			'sidebar/object/visible': '表示',
			'sidebar/object/frustumcull': 'フラスタムカリング',
			'sidebar/object/renderorder': '描画順序',
			'sidebar/object/userdata': 'ユーザーデータ',
			'sidebar/object/export': 'JSONをエクスポート',

			'sidebar/geometry/type': 'タイプ',
			'sidebar/geometry/new': '新規',
			'sidebar/geometry/uuid': 'UUID',
			'sidebar/geometry/name': '名前',
			'sidebar/geometry/bounds': '境界',
			'sidebar/geometry/userdata': 'ユーザーデータ',
			'sidebar/geometry/show_vertex_normals': '頂点法線を表示',
			'sidebar/geometry/compute_vertex_normals': '頂点法線を計算',
			'sidebar/geometry/compute_vertex_tangents': '接線を計算',
			'sidebar/geometry/center': '中央',
			'sidebar/geometry/export': 'JSONをエクスポート',

			'sidebar/geometry/box_geometry/width': '幅',
			'sidebar/geometry/box_geometry/height': '高さ',
			'sidebar/geometry/box_geometry/depth': '奥行き',
			'sidebar/geometry/box_geometry/widthseg': '幅の分割数',
			'sidebar/geometry/box_geometry/heightseg': '高さの分割数',
			'sidebar/geometry/box_geometry/depthseg': '奥行きの分割数',

			'sidebar/geometry/buffer_geometry/attributes': '属性',
			'sidebar/geometry/buffer_geometry/index': 'インデックス',
			'sidebar/geometry/buffer_geometry/morphAttributes': 'モーフ属性',
			'sidebar/geometry/buffer_geometry/morphRelative': '相対モーフ',

			'sidebar/geometry/capsule_geometry/radius': '半径',
			'sidebar/geometry/capsule_geometry/length': '長さ',
			'sidebar/geometry/capsule_geometry/capseg': 'キャップの分割数',
			'sidebar/geometry/capsule_geometry/radialseg': '円の分割数',

			'sidebar/geometry/circle_geometry/radius': '半径',
			'sidebar/geometry/circle_geometry/segments': 'セグメント',
			'sidebar/geometry/circle_geometry/thetastart': '開始角度',
			'sidebar/geometry/circle_geometry/thetalength': '角度の大きさ',

			'sidebar/geometry/cylinder_geometry/radiustop': '上部の半径',
			'sidebar/geometry/cylinder_geometry/radiusbottom': '下部の半径',
			'sidebar/geometry/cylinder_geometry/height': '高さ',
			'sidebar/geometry/cylinder_geometry/radialsegments': '円の分割数',
			'sidebar/geometry/cylinder_geometry/heightsegments': '高さの分割数',
			'sidebar/geometry/cylinder_geometry/openended': 'ふた',

			'sidebar/geometry/extrude_geometry/curveSegments': '分割数',
			'sidebar/geometry/extrude_geometry/steps': 'ステップ',
			'sidebar/geometry/extrude_geometry/depth': '深さ',
			'sidebar/geometry/extrude_geometry/bevelEnabled': 'ベベルを有効にするか',
			'sidebar/geometry/extrude_geometry/bevelThickness': 'ベベルの厚さ',
			'sidebar/geometry/extrude_geometry/bevelSize': 'ベベルのサイズ',
			'sidebar/geometry/extrude_geometry/bevelOffset': 'ベベルのオフセット',
			'sidebar/geometry/extrude_geometry/bevelSegments': 'ベベルの分割数',
			'sidebar/geometry/extrude_geometry/shape': '形状に変換',

			'sidebar/geometry/dodecahedron_geometry/radius': '半径',
			'sidebar/geometry/dodecahedron_geometry/detail': '詳細',

			'sidebar/geometry/icosahedron_geometry/radius': '半径',
			'sidebar/geometry/icosahedron_geometry/detail': '詳細',

			'sidebar/geometry/octahedron_geometry/radius': '半径',
			'sidebar/geometry/octahedron_geometry/detail': '詳細',

			'sidebar/geometry/tetrahedron_geometry/radius': '半径',
			'sidebar/geometry/tetrahedron_geometry/detail': '詳細',

			'sidebar/geometry/lathe_geometry/segments': '分割数',
			'sidebar/geometry/lathe_geometry/phistart': '開始角度',
			'sidebar/geometry/lathe_geometry/philength': '角度の大きさ',
			'sidebar/geometry/lathe_geometry/points': 'ポイント',

			'sidebar/geometry/plane_geometry/width': '幅',
			'sidebar/geometry/plane_geometry/height': '高さ',
			'sidebar/geometry/plane_geometry/widthsegments': '幅の分割数',
			'sidebar/geometry/plane_geometry/heightsegments': '奥行きの分割数',

			'sidebar/geometry/ring_geometry/innerRadius': '内半径',
			'sidebar/geometry/ring_geometry/outerRadius': '外半径',
			'sidebar/geometry/ring_geometry/thetaSegments': '円の分割数',
			'sidebar/geometry/ring_geometry/phiSegments': 'リングの分割数',
			'sidebar/geometry/ring_geometry/thetastart': '開始角度',
			'sidebar/geometry/ring_geometry/thetalength': '角度の大きさ',

			'sidebar/geometry/shape_geometry/curveSegments': '分割数',
			'sidebar/geometry/shape_geometry/extrude': '押し出し',

			'sidebar/geometry/sphere_geometry/radius': '半径',
			'sidebar/geometry/sphere_geometry/widthsegments': '円の分割数',
			'sidebar/geometry/sphere_geometry/heightsegments': 'リングの分割数',
			'sidebar/geometry/sphere_geometry/phistart': '開始角度',
			'sidebar/geometry/sphere_geometry/philength': '角度の大きさ',
			'sidebar/geometry/sphere_geometry/thetastart': '開始角度',
			'sidebar/geometry/sphere_geometry/thetalength': '角度の大きさ',

			'sidebar/geometry/torus_geometry/radius': '半径',
			'sidebar/geometry/torus_geometry/tube': 'チューブの太さ',
			'sidebar/geometry/torus_geometry/radialsegments': '小セグメント数',
			'sidebar/geometry/torus_geometry/tubularsegments': '大セグメント数',
			'sidebar/geometry/torus_geometry/arc': '弧',

			'sidebar/geometry/torusKnot_geometry/radius': '半径',
			'sidebar/geometry/torusKnot_geometry/tube': 'チューブの太さ',
			'sidebar/geometry/torusKnot_geometry/tubularsegments': '小セグメント数',
			'sidebar/geometry/torusKnot_geometry/radialsegments': '大セグメント数',
			'sidebar/geometry/torusKnot_geometry/p': 'P',
			'sidebar/geometry/torusKnot_geometry/q': 'Q',

			'sidebar/geometry/tube_geometry/path': 'パス',
			'sidebar/geometry/tube_geometry/radius': '半径',
			'sidebar/geometry/tube_geometry/tube': 'チューブの太さ',
			'sidebar/geometry/tube_geometry/tubularsegments': '小セグメント数',
			'sidebar/geometry/tube_geometry/radialsegments': '大セグメント数',
			'sidebar/geometry/tube_geometry/closed': '閉じる',
			'sidebar/geometry/tube_geometry/curvetype': 'カーブタイプ',
			'sidebar/geometry/tube_geometry/tension': 'テンション',

			'sidebar/material/new': '新規作成',
			'sidebar/material/copy': 'コピー',
			'sidebar/material/paste': '貼り付け',
			'sidebar/material/slot': 'スロット',
			'sidebar/material/type': 'タイプ',
			'sidebar/material/uuid': 'UUID',
			'sidebar/material/name': '名前',
			'sidebar/material/program': 'プログラム',
			'sidebar/material/info': '情報',
			'sidebar/material/vertex': '頂点',
			'sidebar/material/fragment': 'フラグメント',
			'sidebar/material/color': '色',
			'sidebar/material/depthPacking': '深度パッキング',
			'sidebar/material/roughness': '粗さ',
			'sidebar/material/metalness': '金属度',
			'sidebar/material/reflectivity': '反射率',
			'sidebar/material/emissive': '発光',
			'sidebar/material/specular': '鏡面',
			'sidebar/material/shininess': '光沢',
			'sidebar/material/clearcoat': 'クリアコート',
			'sidebar/material/clearcoatroughness': 'クリアコートの粗さ',
			'sidebar/material/dispersion': 'Dispersion',
			'sidebar/material/ior': 'IOR',
			'sidebar/material/iridescence': '遊色効果',
			'sidebar/material/iridescenceIOR': '遊色効果のIOR',
			'sidebar/material/iridescenceThicknessMax': '遊色効果の厚さ',
			'sidebar/material/sheen': '光沢',
			'sidebar/material/sheenroughness': '光沢の粗さ',
			'sidebar/material/sheencolor': '光沢の色',
			'sidebar/material/transmission': '透過',
			'sidebar/material/attenuationDistance': '減衰距離',
			'sidebar/material/attenuationColor': '減衰色',
			'sidebar/material/thickness': '厚さ',
			'sidebar/material/vertexcolors': '頂点色',
			'sidebar/material/matcap': 'マットキャップ',
			'sidebar/material/map': 'マップ',
			'sidebar/material/alphamap': 'アルファマップ',
			'sidebar/material/bumpmap': 'バンプマップ',
			'sidebar/material/normalmap': 'ノーマルマップ',
			'sidebar/material/clearcoatmap': 'クリアコートマップ',
			'sidebar/material/clearcoatnormalmap': 'クリアコートノーマルマップ',
			'sidebar/material/clearcoatroughnessmap': 'クリアコート粗さマップ',
			'sidebar/material/displacementmap': '変位マップ',
			'sidebar/material/roughnessmap': '粗さマップ',
			'sidebar/material/metalnessmap': 'メタリックマップ',
			'sidebar/material/specularmap': '鏡面マップ',
			'sidebar/material/iridescencemap': '遊色効果マップ',
			'sidebar/material/iridescencethicknessmap': '遊色効果の厚さマップ',
			'sidebar/material/sheencolormap': '光沢色マップ',
			'sidebar/material/sheenroughnessmap': '光沢粗さマップ',
			'sidebar/material/envmap': '環境マップ',
			'sidebar/material/lightmap': 'ライトマップ',
			'sidebar/material/aomap': 'AOマップ',
			'sidebar/material/emissivemap': '発光マップ',
			'sidebar/material/gradientmap': 'グラデーションマップ',
			'sidebar/material/transmissionmap': '透過マップ',
			'sidebar/material/thicknessmap': '厚さマップ',
			'sidebar/material/side': '側面',
			'sidebar/material/size': 'サイズ',
			'sidebar/material/sizeAttenuation': 'サイズ減衰',
			'sidebar/material/flatShading': 'フラットシェーディング',
			'sidebar/material/blending': 'ブレンディング',
			'sidebar/material/opacity': '不透明度',
			'sidebar/material/transparent': '透明',
			'sidebar/material/forcesinglepass': 'シングルパスを強制',
			'sidebar/material/alphatest': 'アルファテスト',
			'sidebar/material/depthtest': '深度テスト',
			'sidebar/material/depthwrite': '深度書き込み',
			'sidebar/material/wireframe': 'ワイヤーフレーム',
			'sidebar/material/userdata': 'ユーザーデータ',
			'sidebar/material/export': 'JSONをエクスポート',

			'sidebar/script/new': '新規',
			'sidebar/script/edit': '編集',
			'sidebar/script/remove': '削除',

			'sidebar/project': 'プロジェクト',
			'sidebar/project/antialias': 'アンチエイリアス',
			'sidebar/project/shadows': 'シャドウ',
			'sidebar/project/toneMapping': 'トーンマッピング',
			'sidebar/project/materials': 'マテリアル',
			'sidebar/project/Assign': '割り当て',

			'sidebar/project/app': 'アプリ',
			'sidebar/project/app/play': '再生',
			'sidebar/project/app/stop': '停止',
			'sidebar/project/app/title': 'タイトル',
			'sidebar/project/app/editable': '編集可能',
			'sidebar/project/app/publish': 'アプリファイルとして保存',

			'sidebar/project/image': '画像',
			'sidebar/project/image/samples': 'サンプル',
			'sidebar/project/video': '動画',

			'sidebar/project/shading': 'シェーディング',
			'sidebar/project/resolution': '解像度',
			'sidebar/project/duration': '長さ',
			'sidebar/project/render': 'レンダー',

			'sidebar/settings': '設定',
			'sidebar/settings/language': '言語',

			'sidebar/settings/shortcuts': 'ショートカット',
			'sidebar/settings/shortcuts/translate': '移動',
			'sidebar/settings/shortcuts/rotate': '回転',
			'sidebar/settings/shortcuts/scale': 'スケール',
			'sidebar/settings/shortcuts/undo': '元に戻す',
			'sidebar/settings/shortcuts/focus': 'フォーカス',

			'sidebar/history': '履歴',
			'sidebar/history/clear': 'クリア',
			'sidebar/history/persistent': '永続的',

			'toolbar/translate': '移動',
			'toolbar/rotate': '回転',
			'toolbar/scale': 'スケール',
			'toolbar/local': 'ローカル',

			'viewport/controls/grid': 'グリッド',
			'viewport/controls/helpers': 'オーバーレイ表示',

			'viewport/info/object': 'オブジェクト',
			'viewport/info/objects': 'オブジェクト',
			'viewport/info/vertex': '頂点',
			'viewport/info/vertices': '頂点',
			'viewport/info/triangle': '三角形',
			'viewport/info/triangles': '三角形',
			'viewport/info/sample': 'サンプル',
			'viewport/info/samples': 'サンプル',
			'viewport/info/rendertime': 'レンダリング時間',

			'script/title/vertexShader': '頂点シェーダー',
			'script/title/fragmentShader': 'フラグメントシェーダ',
			'script/title/programInfo': 'プログラムのプロパティ'

		},

		ko: {
			'prompt/file/open': '저장되지 않은 데이터는 손실됩니다. 진행하시겠습니까?',
			'prompt/file/failedToOpenProject': '프로젝트를 여는 데 실패했습니다!',
			'prompt/file/export/noMeshSelected': '메시가 선택되지 않았습니다!',
			'prompt/file/export/noObjectSelected': '객체가 선택되지 않았습니다!',
			'prompt/script/remove': '삭제하시겠습니까?',
			'prompt/history/clear': '되돌리기/다시하기 기록이 지워집니다. 진행하시겠습니까?',
			'prompt/history/preserve': '기록은 세션을 통해 저장됩니다. 이는 텍스처를 조작할 때 성능에 영향을 미칠 수 있습니다.',
			'prompt/history/forbid': '씬을 재생하는 동안 되돌리기/다시하기는 비활성화됩니다.',
			'prompt/rendering/realistic/unsupportedMaterial': 'REALISTIC 셰이딩: MeshStandardmaterial 및 MeshPhysicalmaterial만 지원됩니다',
	
			'command/AddObject': '객체 추가',
			'command/AddScript': '스크립트 추가',
			'command/MoveObject': '객체 이동',
			'command/MultiCmds': '여러 변경',
			'command/RemoveObject': '객체 삭제',
			'command/RemoveScript': '스크립트 삭제',
			'command/SetColor': '색 설정',
			'command/SetGeometry': '지오메트리 설정',
			'command/SetGeometryValue': '지오메트리 값 설정',
			'command/SetMaterialColor': '머티리얼 색 설정',
			'command/SetMaterial': '머티리얼 설정',
			'command/SetMaterialMap': '머티리얼 맵 설정',
			'command/SetMaterialRange': '머티리얼 범위 설정',
			'command/SetMaterialValue': '머티리얼 값 설정',
			'command/SetMaterialVector': '머티리얼 벡터 설정',
			'command/SetPosition': '위치 설정',
			'command/SetRotation': '회전 설정',
			'command/SetScale': '스케일 설정',
			'command/SetScene': '장면 설정',
			'command/SetScriptValue': '스크립트 값 설정',
			'command/SetShadowValue': '그림자 값 설정',
			'command/SetUuid': 'UUID 설정',
			'command/SetValue': '값 설정',
	
			'menubar/file': '파일',
			'menubar/file/new': '새 프로젝트',
			'menubar/file/new/empty': '비어 있음',
			'menubar/file/new/Arkanoid': '아카노이드',
			'menubar/file/new/Camera': '카메라',
			'menubar/file/new/Particles': '파티클',
			'menubar/file/new/Pong': '퐁',
			'menubar/file/new/Shaders': '셰이더',
			'menubar/file/open': '열기',
			'menubar/file/save': '저장',
			'menubar/file/import': '가져오기',
			'menubar/file/export': '내보내기',
	
			'menubar/edit': '편집',
			'menubar/edit/undo': '되돌리기',
			'menubar/edit/redo': '다시하기',
			'menubar/edit/center': '중앙으로 옮기기',
			'menubar/edit/clone': '복제',
			'menubar/edit/delete': '삭제',
	
			'menubar/add': '추가',
			'menubar/add/group': '그룹',
	
			'menubar/add/mesh': '메시',
			'menubar/add/mesh/plane': '평면',
			'menubar/add/mesh/box': '직육면체',
			'menubar/add/mesh/capsule': '캡슐',
			'menubar/add/mesh/circle': '원',
			'menubar/add/mesh/cylinder': '원통',
			'menubar/add/mesh/ring': '링',
			'menubar/add/mesh/sphere': '구',
			'menubar/add/mesh/dodecahedron': '십이면체',
			'menubar/add/mesh/icosahedron': '이십면체',
			'menubar/add/mesh/octahedron': '팔면체',
			'menubar/add/mesh/tetrahedron': '사면체',
			'menubar/add/mesh/torus': '토러스',
			'menubar/add/mesh/tube': '튜브',
			'menubar/add/mesh/torusknot': '토러스 매듭',
			'menubar/add/mesh/lathe': '선반형',
			'menubar/add/mesh/sprite': '스프라이트',
	
			'menubar/add/light': '조명',
			'menubar/add/light/ambient': '환경광',
			'menubar/add/light/directional': '방향광',
			'menubar/add/light/hemisphere': '반구광',
			'menubar/add/light/point': '포인트',
			'menubar/add/light/spot': '스포트',
	
			'menubar/add/camera': '카메라',
			'menubar/add/camera/perspective': '투시 투영',
			'menubar/add/camera/orthographic': '정사영',
	
			'menubar/status/autosave': '자동 저장',
	
			'menubar/view': '보기',
			'menubar/view/fullscreen': '전체 화면',
			'menubar/view/gridHelper': '그리드 도우미',
			'menubar/view/cameraHelpers': '카메라 도우미',
			'menubar/view/lightHelpers': '조명 도우미',
			'menubar/view/skeletonHelpers': '골격 도우미',
	
			'menubar/help': '도움말',
			'menubar/help/source_code': '소스 코드',
			'menubar/help/icons': '아이콘 팩',
			'menubar/help/about': 'Three.js 알아보기',
			'menubar/help/manual': '매뉴얼',
	
			'sidebar/animations': '애니메이션',
			'sidebar/animations/play': '재생',
			'sidebar/animations/stop': '정지',
			'sidebar/animations/timescale': '시간 스케일',
	
			'sidebar/scene': '장면',
			'sidebar/scene/background': '배경',
			'sidebar/scene/environment': '환경',
			'sidebar/scene/fog': '안개',
	
			'sidebar/properties/object': '객체',
			'sidebar/properties/geometry': '지오메트리',
			'sidebar/properties/material': '머티리얼',
			'sidebar/properties/script': '스크립트',
	
			'sidebar/object/type': '타입',
			'sidebar/object/new': '새로 만들기',
			'sidebar/object/uuid': 'UUID',
			'sidebar/object/name': '이름',
			'sidebar/object/position': '위치',
			'sidebar/object/rotation': '회전',
			'sidebar/object/scale': '스케일',
			'sidebar/object/fov': '화각',
			'sidebar/object/left': '왼쪽',
			'sidebar/object/right': '오른쪽',
			'sidebar/object/top': '위',
			'sidebar/object/bottom': '아래',
			'sidebar/object/near': '최소 시야',
			'sidebar/object/far': '최대 시야',
			'sidebar/object/intensity': '강도',
			'sidebar/object/color': '색',
			'sidebar/object/groundcolor': '지면 색',
			'sidebar/object/distance': '거리',
			'sidebar/object/angle': '각도',
			'sidebar/object/penumbra': '반음영',
			'sidebar/object/decay': '감쇠',
			'sidebar/object/shadow': '그림자',
			'sidebar/object/shadowIntensity': '그림자 강도',
			'sidebar/object/shadowBias': '그림자 바이어스',
			'sidebar/object/shadowNormalBias': '그림자 노멀 바이어스',
			'sidebar/object/shadowRadius': '그림자 반지름',
			'sidebar/object/cast': 'cast',
			'sidebar/object/receive': 'receive',
			'sidebar/object/visible': '보임',
			'sidebar/object/frustumcull': '프러스텀 컬링',
			'sidebar/object/renderorder': '렌더 순서',
			'sidebar/object/userdata': '사용자 데이터',
			'sidebar/object/export': 'JSON으로 내보내기',
	
			'sidebar/geometry/type': '타입',
			'sidebar/geometry/new': '새로 만들기',
			'sidebar/geometry/uuid': 'UUID',
			'sidebar/geometry/name': '이름',
			'sidebar/geometry/bounds': '경계',
			'sidebar/geometry/userdata': '사용자 데이터',
			'sidebar/geometry/show_vertex_normals': '버텍스 노멀 보기',
			'sidebar/geometry/compute_vertex_normals': '버텍스 노멀 계산',
			'sidebar/geometry/compute_vertex_tangents': '접선 계산',
			'sidebar/geometry/center': '중앙',
			'sidebar/geometry/export': 'JSON으로 내보내기',
	
			'sidebar/geometry/box_geometry/width': '너비',
			'sidebar/geometry/box_geometry/height': '높이',
			'sidebar/geometry/box_geometry/depth': '깊이',
			'sidebar/geometry/box_geometry/widthseg': '너비 분할 수',
			'sidebar/geometry/box_geometry/heightseg': '높이 분할 수',
			'sidebar/geometry/box_geometry/depthseg': '깊이 분할 수',
	
			'sidebar/geometry/buffer_geometry/attributes': '속성',
			'sidebar/geometry/buffer_geometry/index': '인덱스',
			'sidebar/geometry/buffer_geometry/morphAttributes': '모프 속성',
			'sidebar/geometry/buffer_geometry/morphRelative': '상대적 모프',
	
			'sidebar/geometry/capsule_geometry/radius': '반지름',
			'sidebar/geometry/capsule_geometry/length': '길이',
			'sidebar/geometry/capsule_geometry/capseg': '캡 분할 수',
			'sidebar/geometry/capsule_geometry/radialseg': '방사 분할 수',
	
			'sidebar/geometry/circle_geometry/radius': '반지름',
			'sidebar/geometry/circle_geometry/segments': '세그먼트',
			'sidebar/geometry/circle_geometry/thetastart': '시작 각도',
			'sidebar/geometry/circle_geometry/thetalength': '각도 길이',
	
			'sidebar/geometry/cylinder_geometry/radiustop': '상단 반지름',
			'sidebar/geometry/cylinder_geometry/radiusbottom': '하단 반지름',
			'sidebar/geometry/cylinder_geometry/height': '높이',
			'sidebar/geometry/cylinder_geometry/radialsegments': '방사 분할 수',
			'sidebar/geometry/cylinder_geometry/heightsegments': '높이 분할 수',
			'sidebar/geometry/cylinder_geometry/openended': '끝 열림',
	
			'sidebar/geometry/extrude_geometry/curveSegments': '곡선 분할 수',
			'sidebar/geometry/extrude_geometry/steps': '단계',
			'sidebar/geometry/extrude_geometry/depth': '깊이',
			'sidebar/geometry/extrude_geometry/bevelEnabled': '베벨 사용',
			'sidebar/geometry/extrude_geometry/bevelThickness': '베벨 두께',
			'sidebar/geometry/extrude_geometry/bevelSize': '베벨 크기',
			'sidebar/geometry/extrude_geometry/bevelOffset': '베벨 오프셋',
			'sidebar/geometry/extrude_geometry/bevelSegments': '베벨 분할 수',
			'sidebar/geometry/extrude_geometry/shape': '형상으로 변환',
	
			'sidebar/geometry/dodecahedron_geometry/radius': '반지름',
			'sidebar/geometry/dodecahedron_geometry/detail': '세부화',
	
			'sidebar/geometry/icosahedron_geometry/radius': '반지름',
			'sidebar/geometry/icosahedron_geometry/detail': '세부화',
	
			'sidebar/geometry/octahedron_geometry/radius': '반지름',
			'sidebar/geometry/octahedron_geometry/detail': '세부화',
	
			'sidebar/geometry/tetrahedron_geometry/radius': '반지름',
			'sidebar/geometry/tetrahedron_geometry/detail': '세부화',
	
			'sidebar/geometry/lathe_geometry/segments': '분할 수',
			'sidebar/geometry/lathe_geometry/phistart': '시작 각도',
			'sidebar/geometry/lathe_geometry/philength': '각도 길이',
			'sidebar/geometry/lathe_geometry/points': '포인트',
	
			'sidebar/geometry/plane_geometry/width': '너비',
			'sidebar/geometry/plane_geometry/height': '높이',
			'sidebar/geometry/plane_geometry/widthsegments': '너비 분할 수',
			'sidebar/geometry/plane_geometry/heightsegments': '깊이 분할 수',
	
			'sidebar/geometry/ring_geometry/innerRadius': '내부 반지름',
			'sidebar/geometry/ring_geometry/outerRadius': '외부 반지름',
			'sidebar/geometry/ring_geometry/thetaSegments': '원 분할 수',
			'sidebar/geometry/ring_geometry/phiSegments': '링 분할 수',
			'sidebar/geometry/ring_geometry/thetastart': '시작 각도',
			'sidebar/geometry/ring_geometry/thetalength': '각도 길이',
	
			'sidebar/geometry/shape_geometry/curveSegments': '곡선 분할 수',
			'sidebar/geometry/shape_geometry/extrude': '압출',
	
			'sidebar/geometry/sphere_geometry/radius': '반지름',
			'sidebar/geometry/sphere_geometry/widthsegments': '원 분할 수',
			'sidebar/geometry/sphere_geometry/heightsegments': '링 분할 수',
			'sidebar/geometry/sphere_geometry/phistart': '시작 각도',
			'sidebar/geometry/sphere_geometry/philength': '각도 길이',
			'sidebar/geometry/sphere_geometry/thetastart': '시작 각도',
			'sidebar/geometry/sphere_geometry/thetalength': '각도 길이',
	
			'sidebar/geometry/torus_geometry/radius': '반지름',
			'sidebar/geometry/torus_geometry/tube': '튜브 두께',
			'sidebar/geometry/torus_geometry/radialsegments': '소 분할 수',
			'sidebar/geometry/torus_geometry/tubularsegments': '대 분할 수',
			'sidebar/geometry/torus_geometry/arc': '호',
	
			'sidebar/geometry/torusKnot_geometry/radius': '반지름',
			'sidebar/geometry/torusKnot_geometry/tube': '튜브 두께',
			'sidebar/geometry/torusKnot_geometry/tubularsegments': '소 분할 수',
			'sidebar/geometry/torusKnot_geometry/radialsegments': '대 분할 수',
			'sidebar/geometry/torusKnot_geometry/p': 'P',
			'sidebar/geometry/torusKnot_geometry/q': 'Q',
	
			'sidebar/geometry/tube_geometry/path': '경로',
			'sidebar/geometry/tube_geometry/radius': '반지름',
			'sidebar/geometry/tube_geometry/tube': '튜브 두께',
			'sidebar/geometry/tube_geometry/tubularsegments': '소 분할 수',
			'sidebar/geometry/tube_geometry/radialsegments': '대 분할 수',
			'sidebar/geometry/tube_geometry/closed': '닫기',
			'sidebar/geometry/tube_geometry/curvetype': '곡선 타입',
			'sidebar/geometry/tube_geometry/tension': '텐션',
	
			'sidebar/material/new': '새로 만들기',
			'sidebar/material/copy': '복사',
			'sidebar/material/paste': '붙여넣기',
			'sidebar/material/slot': '슬롯',
			'sidebar/material/type': '타입',
			'sidebar/material/uuid': 'UUID',
			'sidebar/material/name': '이름',
			'sidebar/material/program': '프로그램',
			'sidebar/material/info': '정보',
			'sidebar/material/vertex': '버텍스',
			'sidebar/material/fragment': '프래그먼트',
			'sidebar/material/color': '색',
			'sidebar/material/depthPacking': '깊이 패킹',
			'sidebar/material/roughness': '거칠기',
			'sidebar/material/metalness': '금속성',
			'sidebar/material/reflectivity': '반사율',
			'sidebar/material/emissive': '발광',
			'sidebar/material/specular': '스펙큘러',
			'sidebar/material/shininess': '광택',
			'sidebar/material/clearcoat': '클리어 코트',
			'sidebar/material/clearcoatroughness': '클리어 코트 거칠기',
			'sidebar/material/dispersion': '분산',
			'sidebar/material/ior': '굴절률',
			'sidebar/material/iridescence': '훈색',
			'sidebar/material/iridescenceIOR': '훈색 굴절률',
			'sidebar/material/iridescenceThicknessMax': '훈색 두께',
			'sidebar/material/sheen': '광택',
			'sidebar/material/sheenroughness': '광택 거칠기',
			'sidebar/material/sheencolor': '광택 색상',
			'sidebar/material/transmission': '투명도',
			'sidebar/material/attenuationDistance': '감쇠 거리',
			'sidebar/material/attenuationColor': '감쇠 색상',
			'sidebar/material/thickness': '두께',
			'sidebar/material/vertexcolors': '버텍스 색상',
			'sidebar/material/matcap': '매트 캡',
			'sidebar/material/map': '맵',
			'sidebar/material/alphamap': '알파맵',
			'sidebar/material/bumpmap': '범프맵',
			'sidebar/material/normalmap': '노멀맵',
			'sidebar/material/clearcoatmap': '클리어 코트 맵',
			'sidebar/material/clearcoatnormalmap': '클리어 코트 노멀맵',
			'sidebar/material/clearcoatroughnessmap': '클리어 코트 거칠기 맵',
			'sidebar/material/displacementmap': '변위 맵',
			'sidebar/material/roughnessmap': '거칠기 맵',
			'sidebar/material/metalnessmap': '금속성 맵',
			'sidebar/material/specularmap': '스펙큘러 맵',
			'sidebar/material/iridescencemap': '훈색 맵',
			'sidebar/material/iridescencethicknessmap': '훈색 두께 맵',
			'sidebar/material/sheencolormap': '광택 색상 맵',
			'sidebar/material/sheenroughnessmap': '광택 거칠기 맵',
			'sidebar/material/envmap': '환경 맵',
			'sidebar/material/lightmap': '조명 맵',
			'sidebar/material/aomap': 'AO 맵',
			'sidebar/material/emissivemap': '발광 맵',
			'sidebar/material/gradientmap': '그라디언트 맵',
			'sidebar/material/transmissionmap': '투명 맵',
			'sidebar/material/thicknessmap': '두께 맵',
			'sidebar/material/side': '측면',
			'sidebar/material/size': '크기',
			'sidebar/material/sizeAttenuation': '크기 감쇠',
			'sidebar/material/flatShading': '플랫 셰이딩',
			'sidebar/material/blending': '블렌딩',
			'sidebar/material/opacity': '불투명도',
			'sidebar/material/transparent': '투명',
			'sidebar/material/forcesinglepass': '단일 패스 강제',
			'sidebar/material/alphatest': '알파 테스트',
			'sidebar/material/depthtest': '깊이 테스트',
			'sidebar/material/depthwrite': '깊이 쓰기',
			'sidebar/material/wireframe': '와이어프레임',
			'sidebar/material/userdata': '사용자 데이터',
			'sidebar/material/export': 'JSON으로 내보내기',
	
			'sidebar/script/new': '새로 만들기',
			'sidebar/script/edit': '편집',
			'sidebar/script/remove': '삭제',
	
			'sidebar/project': '프로젝트',
			'sidebar/project/antialias': '안티앨리어싱',
			'sidebar/project/shadows': '그림자',
			'sidebar/project/toneMapping': '톤 매핑',
			'sidebar/project/materials': '머티리얼',
			'sidebar/project/Assign': '할당',
	
			'sidebar/project/app': '앱',
			'sidebar/project/app/play': '재생',
			'sidebar/project/app/stop': '정지',
			'sidebar/project/app/title': '제목',
			'sidebar/project/app/editable': '편집 가능',
			'sidebar/project/app/publish': '앱 파일로 저장',
	
			'sidebar/project/image': '이미지',
			'sidebar/project/image/samples': '샘플',
			'sidebar/project/video': '비디오',
	
			'sidebar/project/shading': '셰이딩',
			'sidebar/project/resolution': '해상도',
			'sidebar/project/duration': '길이',
			'sidebar/project/render': '렌더',
	
			'sidebar/settings': '설정',
			'sidebar/settings/language': '언어',
	
			'sidebar/settings/shortcuts': '단축키',
			'sidebar/settings/shortcuts/translate': '이동',
			'sidebar/settings/shortcuts/rotate': '회전',
			'sidebar/settings/shortcuts/scale': '스케일',
			'sidebar/settings/shortcuts/undo': '되돌리기',
			'sidebar/settings/shortcuts/focus': '포커스',
	
			'sidebar/history': '기록',
			'sidebar/history/clear': '지우기',
			'sidebar/history/persistent': '영구적',
	
			'toolbar/translate': '이동',
			'toolbar/rotate': '회전',
			'toolbar/scale': '스케일',
			'toolbar/local': '로컬',
	
			'viewport/controls/grid': '그리드',
			'viewport/controls/helpers': '도우미 보기',
	
			'viewport/info/object': '객체',
			'viewport/info/objects': '객체',
			'viewport/info/vertex': '버텍스',
			'viewport/info/vertices': '버텍스',
			'viewport/info/triangle': '삼각형',
			'viewport/info/triangles': '삼각형',
			'viewport/info/sample': '샘플',
			'viewport/info/samples': '샘플',
			'viewport/info/rendertime': '렌더링 시간',
	
			'script/title/vertexShader': '버텍스 셰이더',
			'script/title/fragmentShader': '프래그먼트 셰이더',
			'script/title/programInfo': '프로그램 속성'
		}
	};

	return {

		getKey: function ( key ) {

			return values[ language ][ key ] || '???';

		}

	};

}

export { Strings };
