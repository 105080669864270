

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Editor } from './js/Editor.js';
import { Viewport } from './js/Viewport.js';
import { Toolbar } from './js/Toolbar.js';
import { Script } from './js/Script.js';
import { Player } from './js/Player.js';
import { Sidebar } from './js/Sidebar.js';
import { Menubar } from './js/Menubar.js';
import { Resizer } from './js/Resizer.js';

const ThreeJsEditor = () => {
  const editorContainerRef = useRef(null);

  useEffect(() => {
    // Ensure the container exists
    if (!editorContainerRef.current) return;

    // Initialize the editor
    const editor = new Editor();
    window.editor = editor; // Expose editor to Console
    window.THREE = THREE; // Expose THREE to APP Scripts and Console
    console.log("Editor", editor);
    console.log("THREE", THREE);

    // Initialize components
    const viewport = new Viewport(editor);
    editorContainerRef.current.appendChild(viewport.dom);

    const toolbar = new Toolbar(editor);
    editorContainerRef.current.appendChild(toolbar.dom);

    const script = new Script(editor);
    editorContainerRef.current.appendChild(script.dom);

    const player = new Player(editor);
    editorContainerRef.current.appendChild(player.dom);

    const sidebar = new Sidebar(editor);
    editorContainerRef.current.appendChild(sidebar.dom);

    const menubar = new Menubar(editor);
    editorContainerRef.current.appendChild(menubar.dom);

    const resizer = new Resizer(editor);
    editorContainerRef.current.appendChild(resizer.dom);

   
        // Function to dynamically inject CSS
        const injectCSS = () => {
          const style = document.createElement('style');
          style.innerHTML = `
           :root {
	color-scheme: light dark;
}

[hidden] {
	display: none !important;
}

body {
	font-family: Helvetica, Arial, sans-serif;
	font-size: 14px;
	margin: 0;
	overflow: hidden;
}

hr {
	border: 0;
	border-top: 1px solid #ccc;
}

button {
	position: relative;
}

input {
	vertical-align: middle;
}

	input[type="color"]::-webkit-color-swatch-wrapper {
		padding: 0;
	}
	input[type="color"]::-webkit-color-swatch {
		border: none;
	}

textarea {
	tab-size: 4;
	white-space: pre;
	word-wrap: normal;
}

	textarea.success {
		border-color: #8b8 !important;
	}

	textarea.fail {
		border-color: #f00 !important;
		background-color: rgba(255,0,0,0.05);
	}

textarea, input { outline: none; } /* osx */

.Panel {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;

	/* No support for these yet */
	-o-user-select: none;
	user-select: none;
}

.TabbedPanel {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;

	/* No support for these yet */
	-o-user-select: none;
	user-select: none;
	position: relative;
	display: block;
	width: 100%;
	min-width: 335px;
}

.TabbedPanel .Tabs {
	position: relative;
	z-index: 1; /** Above .Panels **/
	display: block;
	width: 100%;
	white-space: pre;
	overflow: hidden;
	overflow-x: auto;
}

	.TabbedPanel .Tabs::-webkit-scrollbar {
		height: 5px;
		background: #eee;
	}
	.TabbedPanel .Tabs::-webkit-scrollbar-thumb {
		background: #08f3;
	}
	.TabbedPanel .Tabs:hover::-webkit-scrollbar-thumb {
		background: #08f;
		cursor: ew-resize;
	}

	.TabbedPanel .Tabs .Tab {
		padding: 10px 9px;
		text-transform: uppercase;
	}

	.TabbedPanel .Panels {
		position: absolute;
		top: 40px;
		display: block;
		width: 100%;
	}

/* Listbox */
.Listbox {
	color: #444;
	background-color: #fff;
	padding: 0;
	width: 100%;
	min-height: 180px;
	font-size: 12px;
	cursor: default;
	overflow: auto;
}

.Listbox .ListboxItem {
	padding: 6px;
	color: #666;
	white-space: nowrap;
}

.Listbox .ListboxItem.active {
	background-color: rgba(0, 0, 0, 0.04);
}

/* aceEditor */

.aceEditor {

	position: absolute !important;
	top: 37px;
	width: 100% !important;
	height: calc(100% - 37px) !important;

}

	.aceEditor .errorLine {

		background: rgba(255,0,0,0.25);

	}

	.aceEditor .esprima-error {

		color: #f00;
		text-align: right;
		padding: 0 20px;

	}

/* outliner */

#outliner .opener {
	display: inline-block;
	width: 14px;
	height: 14px;
	margin: 0px 4px;
	vertical-align: top;
	text-align: center;
}

	#outliner .opener.open:after {
		content: '−';
	}

	#outliner .opener.closed:after {
		content: '+';
	}

#outliner .option {

	border: 1px solid transparent;

}

#outliner .option.drag {

	border: 1px dashed #999;

}

#outliner .option.dragTop {

	border-top: 1px dashed #999;

}

#outliner .option.dragBottom {

	border-bottom: 1px dashed #999;

}

#outliner .type {
	display: inline-block;
	width: 14px;
	height: 14px;
	color: #ddd;
	text-align: center;
}

#outliner .type:after {
	content: '●';
}

/* */

#outliner .Scene {
	color: #8888dd;
}

#outliner .Camera {
	color: #dd8888;
}

#outliner .Light {
	color: #dddd88;
}

/* */

#outliner .Object3D {
	color: #aaaaee;
}

#outliner .Mesh {
	color: #8888ee;
}

#outliner .Line {
	color: #88ee88;
}

#outliner .LineSegments {
	color: #88ee88;
}

#outliner .Points {
	color: #ee8888;
}

/* */

#outliner .Geometry {
	color: #aaeeaa;
}

#outliner .Material {
	color: #eeaaee;
}

/* */

#outliner .Script:after {
	content: '◎'
}

/*  */

button {
	color: #555;
	background-color: #ddd;
	border: 0px;
	margin: 0px; /* GNOME Web */
	padding: 5px 8px;
	font-size: 12px;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
}

	button:hover {
		background-color: #fff;
	}

	button.selected {
		background-color: #fff;
	}

input, textarea {
	border: 1px solid transparent;
	color: #444;
}

input.Number {
	color: #08f!important;
	font-size: 12px;
	border: 0px;
	padding: 2px;
}

select {
	color: #666;
	background-color: #ddd;
	border: 0px;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
}

	select:hover {
		background-color: #fff;
	}

/* UI */

#resizer {
	 position: absolute;
	z-index: 2; /* Above #sidebar */
	top: 32px;
	right: 350px;
	width: 5px;
	bottom: 0px;
	transform: translatex(2.5px);
	cursor: col-resize;
}

	#resizer:hover {
		background-color: #08f8;
		transition-property: background-color;
		transition-delay: 0.1s;
		transition-duration: 0.2s;
	}

	#resizer:active {
		background-color: #08f;
	}

#viewport {
	position: absolute;
	top: 32px;
	left: 0;
	right: 350px;
	bottom: 0;
   
}

	#viewport .Text {
		text-shadow: 1px 1px 0 rgba(0,0,0,0.25);
		pointer-events: none;
	}

#script {
	position: absolute;
	top: 32px;
	left: 0;
	right: 350px;
	bottom: 0;
	opacity: 0.9;
}

#player {
	position: absolute;
	top: 32px;
	left: 0;
	right: 350px;
	bottom: 0;
}

#menubar {
	position: absolute;
	width: 100%;
	height: 32px;
	background: #eee;
	padding: 0;
	margin: 0;
	right: 0;
	top: 0;
}

	#menubar .menu {
		float: left;
		cursor: pointer;
		padding-right: 8px;
	}

	#menubar .menu.right {
		float: right;
		cursor: auto;
		padding-right: 0;
		text-align: right;
	}

		#menubar .menu .title {
			display: inline-block;
			color: #888;
			margin: 0;
			padding: 8px;
			line-height: 16px;
		}

		#menubar .menu .key {
			position: absolute;
			right: 10px;
			color: #ccc;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 9px;
			padding: 2px 4px;
			right: 10px;
			pointer-events: none;
		}

		#menubar .menu .options {
			position: fixed;
			z-index: 1; /* higher than resizer */
			display: none;
			padding: 5px 0;
			background: #eee;
			min-width: 150px;
			max-height: calc(100vh - 80px);
			overflow: auto;
		}

		#menubar .menu:hover .options {
			display: block;
			box-shadow: 0 10px 10px -5px #00000033;
		}

			#menubar .menu .options hr {
				border-color: #ddd;
			}

			#menubar .menu .options .option {
				color: #666;
				background-color: transparent;
				padding: 5px 10px;
				margin: 0 !important;
			}

				#menubar .menu .options .option:hover {
					color: #fff;
					background-color: #08f;
				}

				#menubar .menu .options .option:not(.submenu-title):active {
					color: #666;
					background: transparent;
				}

				#menubar .menu .options .option.toggle::before {

					content: ' ';
					display: inline-block;
					width: 16px;

				}

				#menubar .menu .options .option.toggle-on::before {

					content: '✔';
					font-size: 12px;

				}

				#menubar .submenu-title::after {
					content: '⏵';
					float: right;
				}

		#menubar .menu .options .inactive {
			color: #bbb;
			background-color: transparent;
			padding: 5px 10px;
			margin: 0 !important;
			cursor: not-allowed;
		}

		

#sidebar {
	position: absolute;
	right: 0;
	top: 32px;
	bottom: 0;
	width: 350px;
	background: #eee;
	overflow: auto;
	overflow-x: hidden;
}

	#sidebar .Panel {
		color: #888;
		padding: 10px;
		border-top: 1px solid #ccc;
	}

	#sidebar .Panel.collapsed {
		margin-bottom: 0;
	}

	#sidebar .Row {
		display: flex;
		align-items: center;
		min-height: 24px;
		margin-bottom: 5px;
	}

	#sidebar .Row .Label {

		width: 120px;

	}

#tabs {
	background-color: #ddd;
	border-top: 1px solid #ccc;
}

	#tabs span {
		color: #aaa;
		border-right: 1px solid #ccc;
		padding: 10px;
	}

	#tabs span.selected {
		color: #888;
		background-color: #eee;
	}

#toolbar {
	position: absolute;
	left: 10px;  /* 350px */
	top: 42px;
	width: 32px;
	background: #eee;
	text-align: center;
}

	#toolbar button, #toolbar input {
		height: 32px;
	}

		#toolbar button img {
			width: 16px;
			opacity: 0.5;
		}

.Outliner {
	color: #444;
	background-color: #fff;
	padding: 0;
	width: 100%;
	height: 130px;
	font-size: 12px;
	cursor: default;
	overflow: auto;
	resize: vertical;
	outline: none !important;
}

	.Outliner .option {
		padding: 4px;
		color: #666;
		white-space: nowrap;
	}

	.Outliner .option:hover {
		background-color: rgba(0,0,0,0.02);
	}

	.Outliner .option.active {
		background-color: rgba(0,0,0,0.04);
	}


.TabbedPanel .Tabs {
	background-color: #ddd;
	border-top: 1px solid #ccc;
}

	.TabbedPanel .Tab {
		color: #aaa;
		border-right: 1px solid #ccc;
	}

	.TabbedPanel .Tab.selected {
		color: #888;
		background-color: #eee;
	}

.Listbox {
	color: #444;
	background-color: #fff;
}

.Panel {
	color: #888;
}

/* */

// @media all and ( max-width: 600px ) {

// 	#resizer {
// 		display: none;
// 	}

// 	#menubar .menu .options {
// 		max-height: calc(100% - 80px);
// 	}

// 	#menubar .menu.right {
// 		display: none;
// 	}

// 	#viewport {
// 		left: 0;
// 		right: 0;
// 		top: 32px;
// 		height: calc(100% - 127px);
// 	}

// 	#script {
// 		left: 0;
// 		right: 0;
// 		top: 32px;
// 		height: calc(100% - 127px);
// 	}

// 	#player {
// 		left: 0;
// 		right: 0;
// 		top: 32px;
// 		height: calc(100% - 127px);
// 	}

// 	#sidebar {
// 		left: 0;
// 		width: 100%;
// 		top: calc(100% - 320px);
// 		bottom: 0;
// 	}

// }

/* DARK MODE */

@media ( prefers-color-scheme: dark ) {

	button {
		color: #aaa;
		background-color: #222;
	}

		button:hover {
			color: #ccc;
			background-color: #444;
		}

		button.selected {
			color: #fff;
			background-color: #08f;
		}

	input, textarea {
		background-color: #222;
		border: 1px solid transparent;
		color: #888;
	}

	select {
		color: #aaa;
		background-color: #222;
	}

		select:hover {
			color: #ccc;
			background-color: #444;
		}

	/* UI */

	#menubar {
		background: #111;
	}

			#menubar .menu .key {
				color: #444;
				border-color: #444;
			}

			#menubar .menu .options {
				background: #111;
			}

				#menubar .menu .options hr {
					border-color: #222;
				}

				#menubar .menu .options .option {
					color: #888;
				}

			#menubar .menu .options .inactive {
				color: #444;
			}

	#sidebar {
		background-color: #111;
	}

		#sidebar .Panel {
			border-top: 1px solid #222;
		}

		#sidebar .Panel.Material canvas {
			border: solid 1px #5A5A5A;
		}

	#tabs {
		background-color: #1b1b1b;
		border-top: 1px solid #222;
	}

		#tabs span {
			color: #555;
			border-right: 1px solid #222;
		}

		#tabs span.selected {
			background-color: #111;
		}

	#toolbar {
		background-color: #111;
	}

		#toolbar img {
			filter: invert(1);
		}

	.Outliner {
		background: #222;
	}

		.Outliner .option {
			color: #999;
		}

		.Outliner .option:hover {
			background-color: rgba(21,60,94,0.5);
		}

		.Outliner .option.active {
			background-color: rgba(21,60,94,1);
		}

	.TabbedPanel .Tabs {
		background-color: #1b1b1b;
		border-top: 1px solid #222;
	}

		.TabbedPanel .Tabs::-webkit-scrollbar {
			background: #111;
		}

		.TabbedPanel .Tab {
			color: #555;
			border-right: 1px solid #222;
		}

		.TabbedPanel .Tab.selected {
			color: #888;
			background-color: #111;
		}

	.Listbox {
		color: #888;
		background: #222;
	}

	.Listbox .ListboxItem:hover {
		background-color: rgba(21,60,94,0.5);
	}

	.Listbox .ListboxItem.active {
		background-color: rgba(21,60,94,1);
	}

}

/* Temporary Chrome fix (#24794) */

[draggable="true"] {
	transform: translate(0, 0);
	z-index: 0;
}
          `;
          document.head.appendChild(style);
        };
    
        // Inject the dynamic CSS
        injectCSS();


    // Handle storage and auto-save
    editor.storage.init(() => {
      editor.storage.get(async (state) => {
        if (state !== undefined) {
          await editor.fromJSON(state);
        }
        const selected = editor.config.getKey('selected');
        if (selected !== undefined) {
          editor.selectByUuid(selected);
        }
      });

    //   const saveState = () => {
    //     if (editor.config.getKey('autosave') === false) return;

    //     clearTimeout(timeout);
    //     timeout = setTimeout(() => {
    //       editor.signals.savingStarted.dispatch();
    //       timeout = setTimeout(() => {
    //         editor.storage.set(editor.toJSON());
    //         editor.signals.savingFinished.dispatch();
    //       }, 100);
    //     }, 1000);
    //   };

	// Declare the timeout variable
let timeout;

const saveState = () => {
    // Check if autosave is disabled
    if (editor.config.getKey('autosave') === false) return;

    // Clear the previous timeout to debounce the save operation
    clearTimeout(timeout);

    // Set a new timeout for saving
    timeout = setTimeout(() => {
        editor.signals.savingStarted.dispatch();
        timeout = setTimeout(() => {
            editor.storage.set(editor.toJSON());
            editor.signals.savingFinished.dispatch();
        }, 100);
    }, 1000);
};

      const signals = editor.signals;
      signals.geometryChanged.add(saveState);
      signals.objectAdded.add(saveState);
      signals.objectChanged.add(saveState);
      signals.objectRemoved.add(saveState);
      signals.materialChanged.add(saveState);
      signals.sceneBackgroundChanged.add( saveState );
	  signals.sceneEnvironmentChanged.add( saveState );
	  signals.sceneFogChanged.add( saveState );
	  signals.sceneGraphChanged.add( saveState );
	  signals.scriptChanged.add( saveState );
	  signals.historyChanged.add( saveState );

    });
    document.addEventListener( 'dragover', function ( event ) {

        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';

    } );

    document.addEventListener( 'drop', function ( event ) {

        event.preventDefault();

        if ( event.dataTransfer.types[ 0 ] === 'text/plain' ) return; // Outliner drop

        if ( event.dataTransfer.items ) {

            // DataTransferItemList supports folders

            editor.loader.loadItemList( event.dataTransfer.items );

        } else {

            editor.loader.loadFiles( event.dataTransfer.files );

        }

    } );
    // Resize handling
    const onWindowResize = () => editor.signals.windowResize.dispatch();
    window.addEventListener('resize', onWindowResize);
    onWindowResize();
    let isLoadingFromHash = false;
			const hash = window.location.hash;

			if ( hash.slice( 1, 6 ) === 'file=' ) {

				const file = hash.slice( 6 );

				if ( confirm( editor.strings.getKey( 'prompt/file/open' ) ) ) {

					const loader = new THREE.FileLoader();
					loader.crossOrigin = '';
					loader.load( file, function ( text ) {

						editor.clear();
						editor.fromJSON( JSON.parse( text ) );

					} );

					isLoadingFromHash = true;

				}

			}

			// ServiceWorker

			// if ( 'serviceWorker' in navigator ) {

			// 	try {

			// 		navigator.serviceWorker.register( 'sw.js' );

			// 	} catch ( error ) {

			// 	}

			// }
    return () => {
      window.removeEventListener('resize', onWindowResize);
      editorContainerRef.current.innerHTML = ''; // Cleanup editor DOM elements
    };
  }, []);

  return < div ref={editorContainerRef} style={{ width: '100%',overflowY:'hidden' }} />;   //
};


export default ThreeJsEditor;
