import React, { useEffect, useState, useRef, useMemo } from 'react';
import AceEditor from 'react-ace';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIonLoading } from '@ionic/react';
import ReactReplView from './ReactReplView.jsx';
import { Tab } from 'react-tabs';
import Sidebar from './Sidebar.jsx';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/theme-nord_dark';
import 'ace-builds/src-noconflict/ext-language_tools';
import { loadPyodide } from 'pyodide';
import logsIcon1 from './img/logs.svg';
import runall from './img/runall.svg';
import stopIcon from './img/stopIcon.svg';
import runIcon from './img/runIcon.svg';
import debugIcon from './img/bugIcon.svg'; 
import Debugger from './Debugger.jsx';
import expandIcon from './img/expandIcon.svg';
import expandBig from './img/expandbig.svg';
import { handleSpriteCommands } from './spriteCommands.js';
// import { handleExtensionCommands } from './extensionCommands.js';
import spriteCode from './runpythonsprite.js';
// import extensionCode from './extensionblocks.js'
// import eventEmitter from './eventEmmiter.js'; // Adjust the import

// import Stage from '../../containers/stage.jsx'
// import { text } from 'node:stream/consumers';
// import VM from 'blockzie-vm'

window.spriteData = {
  names: [],
  ids: []
};

const PythonEditor = ({ vm }) => {
  const [codeInput, setCodeInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  const [presentLoading] = useIonLoading();
  const [outputArray, setOutputArray] = useState([{ type: '', value: '' }]);
  const [pyodide, setPyodide] = useState(null);
  const [stopRequested, setStopRequested] = useState(false);
  const [showDebugger, setShowDebugger] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [allFiles, setAllFiles] = useState([]);
  const currentInputIndexRef = useRef(0);

  // console.log(pyodide)
  // console.log(outputArray)
  // console.log(allFiles)

  useEffect(() => {
    if (selectedFile) {
      handleOpenEditor(selectedFile);
    }
  }, [selectedFile]);

  const updateSpriteData = () => {
    const target = vm.runtime.targets;
    const names = [];
    const ids = [];
    for (let i = 1; i < target.length; i++) {
      names.push(target[i].sprite.name);
      ids.push(target[i].id);
    }
    window.spriteData.names = names;
    window.spriteData.ids = ids;
  };

  // Call updateSpriteData whenever necessary, for example, when the component mounts
  updateSpriteData();

  const handleButtonClick1 = () => {
    setIsExpanded(!isExpanded);
  };

  const [combinedCode, setCombinedCode] = useState('');

  const combineAllFileContents = () => {
    if (allFiles.length === 0) {
      console.warn('No files to combine.');
      return '';
    }

    // Combine all file contents into a single string and set it in combinedCode state
    const combined = allFiles.map(file => file.content).join('\n');
    setCombinedCode(combined);
  };

  // Automatically combine code whenever allFiles changes
  useEffect(() => {
    combineAllFileContents();
  }, [allFiles]);

  // The updated handleRunAllClick function
  const handleRunAllClick = async () => {
    // debugger
    // Check if there are files to combine
    if (allFiles.length === 0) {
      console.warn('No files to run.');
      return;
    }

    // Check if Pyodide is loaded
    if (!pyodide) {
      console.error('Pyodide is not loaded yet.');
      return;
    }

    // Check if combined code is empty
    if (!combinedCode.trim()) {
      console.warn('Code input is empty.');
      return;
    }

    try {
      // Run the Python code asynchronously with Pyodide
      const result = await pyodide.runPythonAsync(combinedCode);
      console.log(result);
      console.log(combinedCode);

      // Format the result into a string for output
      const outputArray = result !== undefined ? result.toString() : "";

      // Handle error output
      if (result !== undefined && result.error) {
        const data = { type: 'error', value: result.error };
        setOutputArray((outputArray) => [...outputArray, data]);
      }

      console.log("Python result :", outputArray)
      // Dispatch custom event with the result
      window.dispatchEvent(new CustomEvent('pythonCodeResult', { detail: outputArray }));

      // Extract and handle sprite commands if they exist in the code
      const spriteCommands = extractSpriteCommands(combinedCode);
      if (spriteCommands) {
        // debugger
        handleSpriteCommands({ command: combinedCode, result: outputArray, spriteId: window.spriteData.ids, spriteName: window.spriteData.names });
      }
    } catch (error) {
      console.error('Error running Python code:', error);
      const errorData = { type: 'error', value: error.message || 'An unknown error occurred.' };
      setOutputArray((outputArray) => [...outputArray, errorData]);
    }
  };

  const handleButtonClick = () => {
    setShowDebugger(!showDebugger);
  };

  const handleStopClick = () => {
    setStopRequested(true); // Set stopRequested to true when stop button is clicked
  };

  useEffect(() => {
    currentInputIndexRef.current = 0;
  }, [codeInput]);

  const inputStrings = useMemo(() => {
    return [...codeInput.matchAll(/input\("([^"]+)"\)/g)].map(match => match[1]);
  }, [codeInput]);
  // console.log(inputStrings);

  const prevLengthRef = useRef(inputStrings ? inputStrings.length : 0);

  useEffect(() => {
    const currentLength = inputStrings ? inputStrings.length : 0;

    // Check if the length of inputStrings has changed
    if (currentLength !== prevLengthRef.current && currentLength > 0) {
      setIsPyodideLoaded(false);
    }
    prevLengthRef.current = currentLength;
  }, [inputStrings]);

  useEffect(() => {
    const loadPythonInReact = async () => {
      if (!isLoading) {
        setIsLoading(true);
        return;
      }

      if (!isPyodideLoaded) {
        presentLoading('Loading... Pyodide', 5000, 'bubbles');
        const pyodideInstance = await loadPyodide({
          indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
          stdin: () => {
            if (currentInputIndexRef.current < inputStrings.length) {
              console.log(currentInputIndexRef.current)
              console.log(inputStrings.length)
              const userInput = prompt(`${inputStrings[currentInputIndexRef.current]}`); // Get input from the array
              console.log(userInput);
              let data = { type: 'input', value: `${inputStrings[currentInputIndexRef.current]}${userInput}` };
              setOutputArray((outputArray) => [...outputArray, data]);
              currentInputIndexRef.current += 1;
              if (currentInputIndexRef.current === inputStrings.length) {
                currentInputIndexRef.current = 0;
              }
              return userInput; // Return the user input from the array
            } else {
              console.log("if input function has not in code ")
              // currentInputIndexRef.current = 0;
              return ''; // Return an empty string if there are no more inputs
            }
          },
          stdout: async (text) => {
            // Check for matches and remove them from the text
            let modifiedText = text;

            inputStrings.forEach(input => {
              if (modifiedText.includes(input)) {
                modifiedText = modifiedText.replace(input, ''); // Remove the matched string
              }
            });

            // Trim the modified text to avoid leading/trailing spaces
            modifiedText = modifiedText.trim();

            // Only add to output if there's remaining text
            if (modifiedText) {
              console.log("Log Data :", modifiedText);
              // const spriteCommands = extractSpriteCommands(modifiedText);
              // console.log(spriteCommands)
              // if (spriteCommands) {
              // Call the handleSpriteCommands function from the spriteCommands.js file
              await handleSpriteCommands({ command: modifiedText, result: modifiedText, spriteId: window.spriteData.ids, spriteName: window.spriteData.names });
              // await handleExtensionCommands({ command: modifiedText, result: modifiedText });
              // window.dispatchEvent(new CustomEvent('pythonCodeResult', { detail: outputArray }));

              // // that timeout has for a print in terminal after running code 
              // await new Promise(resolve => setTimeout(resolve, 2000));

              // }
              let data = { type: 'output', value: modifiedText };
              setOutputArray((outputArray) => [...outputArray, data]);
            }
          },
          stderr: (text) => {
            let data = { type: 'error', value: text.toString() };
            setOutputArray((outputArray) => [...outputArray, data]);
          },
        });


        // const response = await fetch('src/components/python-editor/main.py');
        // const pythonCode = await response.text();
        // console.log(pythonCode)
        // call runpythonsprite.js file
        await pyodideInstance.runPythonAsync(spriteCode);
        // await pyodideInstance.runPythonAsync(extensionCode);
        // Load additional packages
        // await pyodideInstance.loadPackage('ppy_js')
        await pyodideInstance.loadPackage('pandas')
        await pyodideInstance.loadPackage('micropip')
        await pyodideInstance.loadPackage('scipy')
        await pyodideInstance.loadPackage('opencv-python')
        await pyodideInstance.loadPackage('matplotlib')
        await pyodideInstance.loadPackage('distutils')
        // await pyodideInstance.loadPackage('pyFirmata');

        setPyodide(pyodideInstance);
        setIsPyodideLoaded(true);
      }
    };

    loadPythonInReact();
  }, [isLoading, isPyodideLoaded, presentLoading, codeInput]);

  const runPyCode = async () => {
    if (!pyodide) {
      console.error('Pyodide is not loaded yet.');
      return;
    }

    if (!codeInput.trim()) {
      console.warn('Code input is empty.');
      return;
    }

    try {
      // const pythonExecutionPromise = pyodide.runPythonAsync(codeInput);

      // // Create a promise for handling sprite commands
      // const spriteCommands = extractSpriteCommands(codeInput);
      // const spriteHandlingPromise = spriteCommands 
      //   ? handleSpriteCommands({ command: codeInput, spriteId: window.spriteData.ids, spriteName: window.spriteData.names }) 
      //   : Promise.resolve(); // Resolve immediately if no sprite commands

      // // Use Promise.all to run both promises concurrently
      // const [result] = await Promise.all([pythonExecutionPromise, spriteHandlingPromise]);

      
      // only one time python has pass this function . if user can run any one only that one time that code execute 
      const result = await pyodide.runPythonAsync(codeInput);
      // window.result = result
      console.log(result)
      console.log(codeInput)
      // const outputArray = result !== undefined ? result.toString() : "Python code did not return a value.";
      const outputArray = result !== undefined ? result.toString() : "";
      console.log("Log Data", outputArray)
      if (result !== undefined && result.error) {
        let data = { type: 'error', value: result.error };
        setOutputArray((outputArray) => [...outputArray, data]);
      }
      // window.dispatchEvent(new CustomEvent('pythonCodeResult', { detail: outputArray }));

      // // Extract sprite commands from the Python code
      // const spriteCommands = extractSpriteCommands(result.toString());
      // console.log(spriteCommands)
      // if (spriteCommands) {
      //   // Call the handleSpriteCommands function from the spriteCommands.js file
      //   await handleSpriteCommands({ command: codeInput, result: outputArray, spriteId: spriteId, spriteName: spriteName });
      // }
    } catch (error) {
      console.error('Error running Python code:', error);
      const errorData = { type: 'error', value: error.message || 'An unknown error occurred.' };
      setOutputArray((outputArray) => [...outputArray, errorData]);
    }
  };

  // Helper function to extract sprite commands from Python code
  // const extractSpriteCommands = (code) => {
  //   // Implement logic to extract sprite commands from the Python code
  //   // For example, you can use regular expressions to extract lines that start with "sprite."
  //   const spriteCommands = [];
  //   console.log(spriteCommands)
  //   const lines = code.split('\n');
  //   for (const line of lines) {
  //     if (line.trim().startsWith('sprite.')) {
  //       spriteCommands.push(line.trim());
  //     }
  //   }
  //   return spriteCommands;
  // };

  const extractSpriteCommands = (code) => {
    // Check if any line in the code contains "sprite."
    const lines = code.split('\n');
    console.log(lines)
    for (const line of lines) {
      if (line.includes('sprite.')) {
        return true; // Return true if "sprite." is found in any line
      }
    }
    return false; // Return false if "sprite." is not found
  };


  async function termAction(action, e) {
    if (action === "onClear") {
      setOutputArray([{ type: "", value: "" }]);
    }

    if (action === "onSubmit") {
      if (e === "clear" || e === "clear()") {
        setOutputArray([{ type: "", value: "" }]);
        return;
      }

      if (e === "" || e === undefined || e === null) {
        return;
      }

      let data = { type: "input".toString(), value: e.toString() };
      setOutputArray((outputArray) => [...outputArray, data]);

      if (!pyodide) {
        console.error('Pyodide is not loaded yet.');
        return;
      }

      let namespace = pyodide.globals.get("dict")();
      pyodide.runPython(
        `
    import matplotlib.pyplot as plt
    import sys 
    from pyodide.ffi import to_js
    from pyodide.console import PyodideConsole, repr_shorten, BANNER
    import main
    BANNER = "Welcome to the Pyodide terminal emulator 🐍\\n" + BANNER
    pyconsole = PyodideConsole(main.dict)
    import builtins
    async def await_fut(fut):
      res = await fut
      if res is not None:
        builtins._ = res
      return to_js([res], depth=1)
    def clear_console():
      pyconsole.buffer = []
`,
        { globals: namespace }
      );

      let await_fut = namespace.get("await_fut");
      let pyconsole = namespace.get("pyconsole");

      pyconsole.stdout_callback = (s) => {
        let data = { type: "output".toString(), value: s.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      };

      pyconsole.stderr_callback = (s) => {
        let data = { type: "error".toString(), value: s.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      };

      try {
        let fut = pyconsole.push(e.toString());
        let result = await await_fut(fut);
        if (result.length > 0) {
          if (result[0] !== undefined) {
            let data = { type: 'output'.toString(), value: repr_shorten(result[0]) };
            setOutputArray((outputArray) => [...outputArray, data]);
          }
        }
      } catch (err) {
        let data = { type: 'error'.toString(), value: err.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      }
    }

    if (action === "onCopy") {
      const lastOutput = outputArray[outputArray.length - 1];
      if (lastOutput && lastOutput.value) {
        navigator.clipboard.writeText(lastOutput.value).then(
          () => console.log('Copied to clipboard'),
          (err) => console.error('Could not copy: ', err)
        );
      }
    }

    if (action === "onSearch") {
      const searchTerm = prompt("Enter the term to search:");
      if (searchTerm) {
        const results = outputArray.filter(line => line.value.includes(searchTerm));
        if (results.length > 0) {
          console.log('Search results:', results);
          alert(`Found${results.length}`, results);
          // Optionally, setFilteredResults(results); if you want to manage state
          scrollToResult(0); // Scroll to the first result
        } else {
          alert('No results found');
        }
      }
    }
  }

  const scrollToResult = (index) => {
    if (index >= 0 && index < outputArray.length) {
      document.querySelectorAll('.output-line')[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexGrow: '1' }}>
      <div id="python-editor-container" style={{ height: '100%', width: '100%' }}>
        <div style={{ display: isExpanded ? 'none' : 'flex', paddingBottom: '5px', height: '65vh' }}>
          <Sidebar
            setCodeInput={setCodeInput}
            setImageSrc={setImageSrc}
            codeInput={codeInput}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
          />
          {imageSrc ? (
            <div style={{ width: showDebugger ? '80%' : ' 100%', textAlign: 'center', marginTop: '10%' }}>
              <img src={imageSrc} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          ) : (
            <AceEditor id="show-file"
              onChange={(newValue) => setCodeInput(newValue)}
              style={{ width: showDebugger ? '80%' : '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '1%', borderBottomRightRadius: '1%', paddingBottom: '5px' }}
              placeholder=""
              mode="python"
              name="code_editor"
              // theme="chrome" 
              height="600px"
              fontSize={18}
              editorProps={{ $blockScrolling: true }}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={codeInput}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}>
            </AceEditor>
          )}
        </div>

        <div id="python-editor-output" style={{ width: '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '2%', borderBottomRightRadius: '2%' }}>
          <div className='logos' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Tab
              className="react-tabs_react-tabs_tab_1ktU terminal_tab_8e-XY react-tabs_react-tabs__tab--selected_2o0LJ terminal_is-selected_3neEd"
              id="react-tabs-10"
              style={{ borderRadius: '5%', width: '75px', backgroundColor: 'rgb(219 227 242)', marginLeft: "10px", border: '1px solid hsla(0, 0%, 0%, 0.15)', height: '30px', display: 'flex' }}
            >
              <img draggable={false} src={logsIcon1} style={{ height: '20px', width: '20px', marginTop: '4px' }} />
              <div style={{ marginTop: '5px', marginLeft: '7px', color: '#2c236c' }}>Logs</div>
            </Tab>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* run button */}
              <button type="button" onClick={runPyCode}
                style={{ borderTopLeftRadius: '5%', borderBottomLeftRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={runIcon} style={{ width: '20px', height: '20px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "5px", color: '#2c236c' }}>Run</div>
              </button>
              {/* runn all buttton  */}
              <button className="btn-text-right" type="button" onClick={handleRunAllClick} style={{ display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={runall} style={{ width: '20px', height: '20px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "5px", color: '#2c236c' }}>Run All</div>
              </button>
              {/* stop button */}
              <button
                onClick={handleStopClick}
                style={{ borderTopRightRadius: '5%', borderBottomRightRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}  >
                <img draggable={false} src={stopIcon} style={{ width: '20px', height: '20px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "5px", color: '#2c236c' }}>Stop</div>
              </button>
              {/* debugger buttton  */}
              <button onClick={handleButtonClick} style={{ marginLeft: '10px', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={debugIcon} alt="Debug Icon" style={{ width: '18px', height: '18px', cursor: 'pointer', margin: '1px', }} />
              </button>

              {/* expand button  */}
              <button onClick={handleButtonClick1} style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: 'rgb(219 227 242)', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)' }}>
                <img draggable={false} src={isExpanded ? expandBig : expandIcon} style={{ width: '18px', height: '18px', margin: '1px', cursor: 'pointer' }} alt="Expand Icon" />
              </button>
            </div>
          </div>

          <div style={{ marginLeft: '5px', height: '100%' }}>
            <div style={{ transition: 'height 0.3s ease', height: isExpanded ? '100%' : '20%', maxwidth: '900px' }}>
              {/* terminal */}
              <ReactReplView title={''}
                tabs={[]}
                onSubmit={(e) => termAction("onSubmit", e)}
                lines={outputArray}
                onChangeTab={(e) => termAction("onChangeTab", e)}
                onClear={() => termAction("onClear")}
                isExpanded={isExpanded} />
            </div>
          </div>
        </div>
      </div>
      {showDebugger && <Debugger />}
    </div >
  );
};

PythonEditor.propTypes = {
  vm: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  vm: state.scratchGui.vm,// Ensure VM is available in props
});

export default connect(
  mapStateToProps,
)(PythonEditor);


// import React, { useEffect, useState, useRef} from 'react';
// import AceEditor from 'react-ace';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { useIonLoading } from '@ionic/react';
// import ReactReplView from './ReactReplView.jsx';
// import { Tab } from 'react-tabs';
// import Sidebar from './Sidebar.jsx';
// import 'ace-builds/src-noconflict/mode-python';
// import 'ace-builds/src-noconflict/snippets/python';
// import 'ace-builds/src-noconflict/theme-nord_dark';
// import 'ace-builds/src-noconflict/ext-language_tools';
// // import { loadPyodide } from 'pyodide';
// import logsIcon1 from './img/logs.svg';
// import runall from './img/runall.svg';
// import stopIcon from './img/stopIcon.svg';
// import runIcon from './img/runIcon.svg';
// import debugIcon from './img/bugIcon.svg';
// import Debugger from './Debugger.jsx';
// import expandIcon from './img/expandIcon.svg';
// import expandBig from './img/expandbig.svg';
// import * as Comlink from 'comlink';
// import PyodideWorker from 'worker-loader!./pyodideWorker.worker.js';



// window.spriteData = {
//   names: [],
//   ids: []
// };

// const PythonEditor = ({ vm }) => {
//   // const [codeInput, setCodeInput] = useState('');
//   // const [isLoading, setIsLoading] = useState(true);
//   const [code, setCode] = useState('');
//   const [worker, setWorker] = useState(null);
//   const [presentLoading] = useIonLoading();
//   const [outputArray, setOutputArray] = useState([{ type: '', value: '' }]);
//   const [stopRequested, setStopRequested] = useState(false);
//   const [showDebugger, setShowDebugger] = useState(false);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [imageSrc, setImageSrc] = useState('');
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [allFiles, setAllFiles] = useState([]);
//   const currentInputIndexRef = useRef(0);
//   const workerRef = useRef(null);
//   const [isInitializing, setIsInitializing] = useState(false);

//   useEffect(() => {
//     if (selectedFile) {
//       handleOpenEditor(selectedFile);
//     }
//   }, [selectedFile]);

//   const updateSpriteData = () => {
//     const target = vm.runtime.targets;
//     const names = [];
//     const ids = [];
//     for (let i = 1; i < target.length; i++) {
//       names.push(target[i].sprite.name);
//       ids.push(target[i].id);
//     }
//     window.spriteData.names = names;
//     window.spriteData.ids = ids;
//   };

//   updateSpriteData();

//   const handleButtonClick1 = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const [combinedCode, setCombinedCode] = useState('');

//   const combineAllFiles = () => {
//     const combined = allFiles.map((file) => file.content).join("\n");
//     setCombinedCode(combined);
//   };

//   useEffect(() => {
//     combineAllFiles();
//   }, [allFiles]);

//   const handleRunAllClick = async () => {
//     if (allFiles.length === 0) {
//       console.warn('No files to run.');
//       return;
//     }
//     if (!worker) {
//       console.error('Worker is not initialized yet.');
//       return;
//     }
//     if (!combinedCode.trim()) {
//       console.warn('Code input is empty.');
//       return;
//     }

//     try {
//       const { output, error } = await worker.runPython(combinedCode);
//       if (output) {
//         setOutputArray(prev => [...prev, { type: 'output', value: output }]);
//       }
//       if (error) {
//         setOutputArray(prev => [...prev, { type: 'error', value: error }]);
//       }
//     } catch (error) {
//       console.error('Error running Python code:', error);
//       setOutputArray(prev => [...prev, { type: 'error', value: error.message || 'An unknown error occurred.' }]);
//     }
//   };

//   const handleButtonClick = () => {
//     setShowDebugger(!showDebugger);
//   };

//   const handleStopClick = () => {
//     setStopRequested(true);
//   };

//   useEffect(() => {
//     const initWorker = async () => {
//       setIsInitializing(true); // Set initializing state to true
//       try {
//         const worker = new PyodideWorker(); // Create the worker
//         const wrappedWorker = Comlink.wrap(worker);

//         workerRef.current = wrappedWorker;

//         const message = await workerRef.current.loadPyodideAndPackages();
//         console.log(message);
//         setWorker(true);
//       } catch (error) {
//         console.error('Failed to initialize worker:', error);
//         setOutputArray((prev) => [...prev, { type: 'error', value: 'Failed to initialize worker.' }]);
//       } finally {
//         setIsInitializing(false); // Reset initializing state
//       }
//     };

//     initWorker();
//   }, []);

//   const appendOutput = (type, value) => {
//     setOutputArray((prev) => [...prev, { type, value }]);
//   };

//   const handleRunCode = async () => {
//     if (!worker) {
//       appendOutput("error", "Worker is still initializing. Please wait...");
//       return;
//     }

//     try {
//       console.log("Running Python code...");
//       const { output, error } = await workerRef.current.runPython(code);

//       if (output) {
//         appendOutput("output", output);
//       }
//       if (error) {
//         appendOutput("error", error);
//       }
//     } catch (error) {
//       console.error("Error during Python execution:", error);
//       appendOutput("error",`Error: ${error.message}`);
//     }
//   };
//   return (
//     <div style={{ display: 'flex', flexGrow: '1' }}>
//       <div id="python-editor-container" style={{ height: '100%', width: '100%' }}>
//         <div style={{ display: isExpanded ? 'none' : 'flex', paddingBottom: '5px', height: '65vh' }}>
//           <Sidebar
//             setCodeInput={setCode}
//             setImageSrc={setImageSrc}
//             codeInput={code}
//             allFiles={allFiles}
//             setAllFiles={setAllFiles}
//           />
//           {imageSrc ? (
//             <div style={{ width: showDebugger ? '80%' : ' 100%', textAlign: 'center', marginTop: '10%' }}>
//               <img src={imageSrc} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
//             </div>
//           ) : (
//             <AceEditor id="show-file"
//               onChange={setCode}
//               value={code}
//               style={{ width: showDebugger ? '80%' : '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '1%', borderBottomRightRadius: '1%', paddingBottom: '5px' }}
//               placeholder=""
//               mode="python"
//               name="code_editor"
//               // theme="chrome" 
//               height="600px"
//               fontSize={18}
//               editorProps={{ $blockScrolling: true }}
//               showPrintMargin={true}
//               showGutter={true}
//               highlightActiveLine={true}
//               // value={codeInput}
//               setOptions={{
//                 enableBasicAutocompletion: true,
//                 enableLiveAutocompletion: true,
//                 enableSnippets: true,
//                 showLineNumbers: true,
//                 tabSize: 2,
//               }}>
//             </AceEditor>
//           )}
//         </div>

//         <div id="python-editor-output" style={{ width: '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '2%', borderBottomRightRadius: '2%' }}>
//           <div className='logos' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//             <Tab
//               className="react-tabs_react-tabs_tab_1ktU terminal_tab_8e-XY react-tabs_react-tabs__tab--selected_2o0LJ terminal_is-selected_3neEd"
//               id="react-tabs-10"
//               style={{ borderRadius: '5%', width: '75px', backgroundColor: 'rgb(219 227 242)', marginLeft: "10px", border: '1px solid hsla(0, 0%, 0%, 0.15)', height: '30px', display: 'flex' }}
//             >
//               <img draggable={false} src={logsIcon1} style={{ height: '20px', width: '20px', marginTop: '4px' }} />
//               <div style={{ marginTop: '5px', marginLeft: '7px', color: '#2c236c' }}>Logs</div>
//             </Tab>
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//               {/* run button */}
//               <button type="button" onClick={handleRunCode}
//                 style={{ borderTopLeftRadius: '5%', borderBottomLeftRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={runIcon} style={{ width: '20px', height: '20px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "5px", color: '#2c236c' }}>Run</div>
//               </button>
//               {/* runn all buttton  */}
//               <button className="btn-text-right" type="button" onClick={handleRunAllClick} style={{ display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={runall} style={{ width: '20px', height: '20px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "5px", color: '#2c236c' }}>Run All</div>
//               </button>
//               {/* stop button */}
//               <button
//                 onClick={handleStopClick}
//                 style={{ borderTopRightRadius: '5%', borderBottomRightRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}  >
//                 <img draggable={false} src={stopIcon} style={{ width: '20px', height: '20px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "5px", color: '#2c236c' }}>Stop</div>
//               </button>
//               {/* debugger buttton  */}
//               <button onClick={handleButtonClick} style={{ marginLeft: '10px', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={debugIcon} alt="Debug Icon" style={{ width: '18px', height: '18px', cursor: 'pointer', margin: '1px', }} />
//               </button>

//               {/* expand button  */}
//               <button onClick={handleButtonClick1} style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: 'rgb(219 227 242)', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)' }}>
//                 <img draggable={false} src={isExpanded ? expandBig : expandIcon} style={{ width: '18px', height: '18px', margin: '1px', cursor: 'pointer' }} alt="Expand Icon" />
//               </button>
//             </div>
//           </div>

//           <div style={{ marginLeft: '5px', height: '100%' }}>
//             <div style={{ transition: 'height 0.3s ease', height: isExpanded ? '100%' : '20%', maxwidth: '900px' }}>
//               {/* terminal */}
//               <ReactReplView title={''}
//                 tabs={[]}
//                 onSubmit={(e) => termAction("onSubmit", e)}
//                 lines={outputArray}
//                 onChangeTab={(e) => termAction("onChangeTab", e)}
//                 onClear={() => termAction("onClear")}
//                 isExpanded={isExpanded} />
//             </div>
//           </div>
//         </div>
//       </div>
//       {showDebugger && <Debugger />}
//     </div >
//   );
// };

// PythonEditor.propTypes = {
//   vm: PropTypes.object.isRequired,
// };

// const mapStateToProps = state => ({
//   vm: state.scratchGui.vm,// Ensure VM is available in props
// });

// export default connect(
//   mapStateToProps,
// )(PythonEditor);
